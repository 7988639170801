import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Chip from "@material-ui/core/Chip";
import { Card, Button, Grid, Typography, ListItem} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "./Billing.css";
import agent from "../../agent";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import InfoModal from "../../components/modal/InfoModal";
import InfoPrivateHireModal from "../../components/modal/InfoPrivateHireModal";
import ConfirmationStripeModal from "../../components/modal/ConfirmationStripeModal";
import ConfirmationUpgradeModal from "../../components/modal/ConfirmationUpgradeModal";
import Loading from "../../components/loadingSpinner/Loading";
import moment from "moment";
import { getProductType, getPricingId, getPricingIdArray } from "../../pages/billing/ProductPlanStripe";
import { OrganisationType, ProfileType } from "../../types/enum";
import { withRouter, Link } from "react-router-dom";
import { hgStripe } from "./StripeConfig.js";

const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("userStore", "billingStore", "profileStore", "authStore", "organisationStore")
@withRouter
@observer
class HgBilling extends Component{
    constructor(props){
        super(props);
        this.props.billingStore.reloadAccess();
    };


    state = {
        openCreditCardDialog: false,
        openCardDialog: false,
        openConfirmationDialog: false,
        openConfirmationCancelSubscriptionDialog: false,
        isPrivateHireFirstOpen: false,
        planId: "",
        planName: "",
        customer: null,
        card: null,
        subscription: null,
        invoices: null,
        subscriptionApp: 0,
        profileType: 0,
        currentPlanId: null,
        nextPlanId: null,
        status: 'cancel',
        billingInfoStartDate: null,
        billingInfoStatus: null,
        billingInfoStatusPro: null,
        isTrialPro: false,
        periodEndPro: null,
        cancelledStatusPro: null,
        billingInfoSubsctiptionPlan: null,
        billingInfoCreditCardInformation: null,
        billingInfoIsRegistered: null,
        isCardExist:false,
        isAddCardSuccess: false,
        productPrice: "",
        productPriceWithout0: "",
        canCancelSubscription: false,
        showEntertainerSubscription: false,
        showAgentSubscription: false,
        showSingleVenueSubscription: false,
        showGroupVenueSubscription: false,
        showNoMembersip: false,
        hasSubscriptionCancelled: false,
        organisations: [],
        isVenuHasOrganisation : false,
        showForceSubscriptionDiaglog: false,
        showUpdatePaymentDialog: false,
        isRegistrationEnable:false,
        isNeedToReloadAndDirectToDashboard: false,
        isMemberOfOrganisation : false,
        cancelledStatus : null,
        isTrial: true,
        isProPlan: false,
        openDialogProPlan: false,
        openDialogDowngradeProPlan: false,
        periodEnd: null,
        haveSecondSubscription: false,
        planIdPro: "", 
        planNamePro: "",
        openDialogCancelDowngrade: false,
        isUpgradeSucess: false,
        hasScheduleSubs: false,
        scheduleStatus: "",
        isRegisterSingleVenueProPlan: false,
        billingInfoStatusVenue: ""
    };

    
    async handleLoadBillingTrialPeriodPro(subscription){
        if(subscription.status == "trialing"){
            let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
            if(periodEnd > 14){
                periodEnd = 14
            } 
            let status = "Your Free Trial Has " + periodEnd + " days remaining";
            this.setState({
                billingInfoStatusPro: status,
                isTrialPro: true,
                periodEndPro: periodEnd
            });
        }else if(subscription.status == "active" && !subscription.cancel_at_period_end){
            let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
            let status = `Your membership will renew in ${periodEnd} days`
            // "Your Subscription Has " + periodEnd + " days remaining";
            
            this.setState({
                billingInfoStatusPro: status,
                isTrialPro: false,
                periodEndPro: periodEnd
            });
        }
        if(subscription.cancel_at_period_end){
            let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
            let cancelledStatus = "Your membership for pro plan has " + periodEnd + " day(s) remaining. You can continue using Hot Giggity Pro Plan until the end of your current membership. To continue using Hot Giggity Pro Plan after this time, please cancel downgrade plan."
            let status = `Your membership will resume to basic plan in ${periodEnd} days`
            this.setState({
                billingInfoStatusPro: status,
                cancelledStatusPro: cancelledStatus,
                periodEndPro: periodEnd
            });
        }
    }
    async handleLoadBillingTrialPeriod(subscription){
        var proPlanId = [hgStripe.pricing.entertainerMembershipPro,hgStripe.pricing.agentMembershipProMonthly, hgStripe.pricing.singleVenueProMembershipMonth]
        var isProPlan = proPlanId.includes(subscription.plan.id);

        if(this.props.profileStore.currentProfileType !== 1 || isProPlan){
            if(subscription.status == "trialing"){
                let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
                if(periodEnd > 14){
                    periodEnd = 14
                } 
                let scheduleStatus = `This subscription plan will be active in ${periodEnd} days`
                let status = "Your Free Trial Has " + periodEnd + " days remaining";
                this.setState({
                    billingInfoStatus: status,
                    billingInfoStatusVenue: scheduleStatus,
                    isTrial: true,
                    periodEnd: periodEnd
                });
            }else if(subscription.status == "active"){
                let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
                let status = `Your membership will renew in ${periodEnd} days`
                // "Your Subscription Has " + periodEnd + " days remaining";
                
                this.setState({
                    billingInfoStatus: status,
                    isTrial: false,
                    periodEnd: periodEnd
                });
            }
            if(subscription.cancel_at_period_end){
                let periodEnd = moment.utc(moment.unix(subscription.current_period_end)).local().diff(moment(), "days") + 1;
                let status = "Your membership has " + periodEnd + " day(s) remaining. You can continue using Hot Giggity until the end of your current membership. To continue using Hot Giggity after this time, please resubscribe."
                if(this.props.profileStore.currentProfileType === 3 ){
                    status = "Your membership has " + periodEnd + " day(s) remaining. You can continue using Hot Giggity until the end of your current membership, you will resume as free plan by the end of your current membership. To continue using basic plan after this time, please resubscribe."
                }
                this.setState({
                    cancelledStatus: status,
                    periodEnd: periodEnd
                });
            }
        }
    }

    showCardNumber(){
        return(
            <Chip label={`${this.state.card[0].brand} ending in ${this.state.card[0].last4}` } variant="outlined"/>
        );
    }

    componentDidMount(){
        this.props.handleLoading(true)
        this.setState({
            customer: this.props.billingStore.customer,
        });

    
        let currentType = this.props.profileStore.getCurrentProfile()?.typeString;
        
        if (currentType == "musician" || currentType == "agent" || currentType == "staff") {
            this.setupAccess();
        }else {
            this.updateMenu();
        }
       
    }

    setupAccess = async () => {
        let stripeAccessibility = this.props.billingStore.billingAccess;
        if(stripeAccessibility.isSubscriptionRequired){
            const {billingAccess} = this.props.billingStore
            var userId = billingAccess?.isMemberOfOrganisation ? billingAccess.parentUserId : this.props.userStore.currentUser.sub
            let customer = await this.props.billingStore.getCustomerByUserId(userId);
            var card = []
            if(customer){
                card = customer.cards;
            }
               this.setState({customer: customer,isCardExist: card.length, isMemberOfOrganisation: stripeAccessibility.isMemberOfOrganisation,showForceSubscriptionDiaglog : true, planName: stripeAccessibility.plan.planName, productPrice : stripeAccessibility.plan.productPrice});
               this.props.handleLoading(false)
        }else if(stripeAccessibility.isNeedToUpdatePayment) {
            let customer = await this.props.billingStore.getCustomerByUserId(this.props.userStore.currentUser.sub);
            this.setState({
                isMemberOfOrganisation: stripeAccessibility.isMemberOfOrganisation,
                showUpdatePaymentDialog : true, 
                planName: stripeAccessibility.plan.planName, 
                productPrice : stripeAccessibility.plan.productPrice,
                customer: customer
            });
            this.props.handleLoading(false)
        }else {
            this.setState({
                isMemberOfOrganisation: stripeAccessibility.isMemberOfOrganisation
            });
            this.updateMenu();
        }
    }

    handleCloseConfirmationDialog = () => {
        this.setState({
            openConfirmationDialog: false,
        });
    }

    handleCloseDialog = () => {
        this.setState({
            openCreditCardDialog: false
        })
    }
    handleCloseDialogProPlan = () => {
        this.setState({
            openDialogProPlan: false
        })
    }
    closeDialogDowngradeProPlan = () => {
        this.setState({
            openDialogDowngradeProPlan: false
        })
    }
    removeHandler = () => {
        this.setState({
            openCreditCardDialog: false
        })
    }
    
    handleUpgradeProPlan = async () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        var priceIdPro = ""
        if(currentProfile?.type === ProfileType.Musician.ordinal){
            priceIdPro =  hgStripe.pricing.entertainerMembershipPro
        }else if(currentProfile?.type === ProfileType.Agent.ordinal){
            priceIdPro = hgStripe.pricing.agentMembershipProMonthly
        }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            priceIdPro = hgStripe.pricing.singleVenueProMembershipMonth
        }
        this.setState({ openDialogProPlan: false, isUpgradeSucess: true});
        this.props.handleLoading(true)
        this.props.billingStore.upgradeProPlan(currentProfile?.type,{priceId:priceIdPro}).then(() => {
            this.props.profileStore.loadProfilesAfterLogin().then(() => {
        
                this.updateMenu();
                this.setupAccess();
            })
        })
    }

    handleDowngradeProPlan = async () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();
        var priceIdPro = ""
        if(currentProfile?.type === ProfileType.Musician.ordinal){
            priceIdPro =  hgStripe.pricing.entertainerMembershipFree
        }else if(currentProfile?.type === ProfileType.Agent.ordinal){
            priceIdPro = hgStripe.pricing.agentMembershipMonthly
        }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            priceIdPro = hgStripe.pricing.singleVenueMembershipMonth
        }
        this.setState({ openDialogDowngradeProPlan: false });
        this.props.handleLoading(true)
        this.props.billingStore.downgradeProPlan(currentProfile?.type,{priceId:priceIdPro}).then(() => {
            this.props.profileStore.loadProfilesAfterLogin().then(() => {
                this.updateMenu();
                this.setupAccess();
            })
        }).catch( () => {
        })
       
    }

    handleCancelDowngrade = async () => {
        var currentProfile = this.props.profileStore.getCurrentProfile();
      
        this.setState({ openDialogCancelDowngrade: false });
        this.props.handleLoading(true)
        this.props.billingStore.cancelDowngradeProPlan(currentProfile?.type).then(() => {
            this.setState({
                billingInfoStatusPro: "",
                cancelledStatusPro: "",
                periodEndPro: null,
                billingInfoStatus: "",
                cancelledStatus: "",
                periodEnd: null
            });
            this.updateMenu();
            this.setupAccess();
        })
    }
     
    handleSubscribeDialog = async (isDeleted) => {
        let profileType = this.props.profileStore.getCurrentProfile()?.type;
        this.props.profileStore.loadProfilesAfterLogin().then(() => {
            this.props.handleLoading(true)
            this.setState({
                isAddCardSuccess: true,
                isPrivateHireFirstOpen: false
            });
    
            if(profileType === ProfileType.PrivateHire.ordinal){
                
                this.props.handleLoading(true)
                if(isDeleted){
                    this.props.userStore.getUserCognito().then(() => {
                        this.props.handleLoading(false)
                        this.updateMenu();
                        window.location.reload();
                        
                   
                    })
                }else{
                    this.props.userStore.completeUser().then(() => {
                        this.props.userStore.getUserCognito().then(() => {
                            this.props.handleLoading(false)
                            this.updateMenu();
                            window.location.reload();
                            
                       
                        })
                    });
                }
              
            }else{
                this.updateMenu();
                this.setupAccess();
            }
        }).catch( () => {
            this.props.handleLoading(false)
        })
     
       
    }

    gotoDashboard() {
        let profileType = this.props.profileStore.getCurrentProfile()?.type;
        switch (profileType) {
            case 1:
                this.props.history.push("/musician");
                break;
            case 2:
                this.props.history.push("/agent");
                break;
            case 3:
                this.props.history.push("/staff");
                break;
            case 6:
                this.props.history.push("/production");
                break;
            case 7:
                this.props.history.push("/dj");
                break;
            case 8:
                this.props.history.push("/agent");
                break;
            case 9:
                this.props.history.push("/privatehire/gig");
                break;
                    
        }
    }

    handleOpenDialog = () => {
        this.setState({ openCreditCardDialog: true });
    };

    handleOpenDialogDowngradeProPlan = () => {
        this.setState({ openDialogDowngradeProPlan: true });
    };
    
    handleOpenDialogCancelDowngrade = () => {
        this.setState({ openDialogCancelDowngrade: true });
    };
    
    handleOpenDialogProPlan = () => {
        this.setState({ openDialogProPlan: true });
    };
    
    handleRefreshCard = () => {
  
        this.props.handleLoading(true)
        this.updateMenu();
    };

    async subscribe(planId){
       
        this.props.handleLoading(true)
        await agent.Billing.createSubscription(this.state.customer.id, planId);
        await this.props.authStore.refreshTokenManually();
        this.updateMenu();
    }

    handleOpenUpdateSubscriptionStatus = () => {
        this.setState({
            openConfirmationCancelSubscriptionDialog: true,
        });
    }

    handleCloseConfirmationUpdateSubscriptionDialog = () => {
        this.setState({
            openConfirmationCancelSubscriptionDialog: false,
        });
    }

    handleCloseDialogCancelDowngrade = () => {
        this.setState({ openDialogCancelDowngrade: false });
    };

    handleCloseInfoModal= async () => {
        this.setState({
            isAddCardSuccess: false
        });
        this.props.billingStore.setTemporaryAccess(false)
        this.props.history.push("/account/setting/membership");
        window.location.reload();
      
    }

    handleCloseInfoModalUpgrade= () => {
        this.setState({
            isUpgradeSucess: false
        });
      
    }

    handleCloseInfoPrivateHireModal= () => {
        this.setState({
            isPrivateHireFirstOpen: false
        });
    }

    setPlanId = (subscription) => {
        console.log(subscription)
      const { currentProfileType } = this.props.profileStore;
      let plan = getProductType(currentProfileType, subscription.plan);
      this.setState({ planId: plan.planId, planName: plan.planName })
    }

    setPlanIdPro = (planId) => {
      const { currentProfileType } = this.props.profileStore;
      var temp = {
        id: planId
      }
      let plan = getProductType(currentProfileType, temp);
      this.setState({ planIdPro: plan.planId, planNamePro: plan.planName })
    }

    handleCancelSubscriptionBySubId = async () => {
        this.setState({
            openConfirmationCancelSubscriptionDialog : false,
        });

        this.props.handleLoading(true)
     

        await agent.Billing.cancelMembershipBySubscriptionId(this.state.subscription.id);
        this.updateMenu();
      
    }

    handleRegisterSingleVenueProPlan = async () => {
        this.setState({
            isRegisterSingleVenueProPlan : false
        });

          
        this.props.handleLoading(true)
        const currentProfile  = this.props.profileStore.getCurrentProfile();
        
        let result = await agent.Billing.subscribeSingleVenueProPlan(currentProfile.id);
        await this.props.billingStore.reloadAccessSynch();
        this.gotoDashboard();
    }
    handleRegistrationMembership = async () => {

        this.setState({
            isRegistrationEnable : false
        });
        
        this.props.handleLoading(true)
       const currentProfile  = this.props.profileStore.getCurrentProfile();
       const currentProfileType = currentProfile?.type
        let currentUser = this.props.userStore.currentUser;
        var candidateCustomer = { 
            userId: currentUser.sub, 
            email: currentUser.email, 
            given_name: currentUser.given_name, 
            family_name: currentUser.family_name, 
            userType: null
        }

        if(currentProfileType == 1) candidateCustomer.userType = 1;
        else if(currentProfileType == 2) candidateCustomer.userType = 2;
        else if(currentProfileType == 3){
            // if(this.state.organisations.length == 0){
            //     let org = await this.props.organisationStore.loadOrganisations(1);
            //     let isVenueHasOrg = org.filter(x => x.type === OrganisationType.Venue.ordinal).length > 0;
            //     if(isVenueHasOrg) candidateCustomer.userType = 4;
            // }else candidateCustomer.userType = 3;
            if(currentProfile?.isOwnOrganization){
                candidateCustomer.userType = 4;
            }else{
                candidateCustomer.userType = 3;
            }
        }
      
        let result = await agent.Billing.createCustomer(candidateCustomer);
        await this.props.billingStore.reloadAccessSynch();
        this.gotoDashboard();
    }

    closeRegistrationModal= () => {
        this.setState({ isRegistrationEnable: false });
    }

    closeRegisterSingleVenueProPlan= () => {
        this.setState({ isRegisterSingleVenueProPlan: false });
    }
    
    async updateMenu() {
        const {billingAccess} = this.props.billingStore
        const currentProfileType  = this.props.profileStore.getCurrentProfile()?.type
        let status = null;
        let card = null;
        let plan = null;
        let planPro = null;
        let productPricePro = null;
        var productPriceWithout0Pro = null;
        let productPriceWithout0 = null;
        let productPrice = null;
        let subscription = null;
        let subscriptionApp = null;
        let invoices = null;
        let subscriptions = [];
        let haveSecondSubscription = false;
        let secondSubscription = null;
        let isScheduleSubs = false 
        var proPlanId = [hgStripe.pricing.entertainerMembershipPro,hgStripe.pricing.agentMembershipProMonthly, hgStripe.pricing.singleVenueProMembershipMonth]
        var basicPlanId = [hgStripe.pricing.entertainerMembershipFree,hgStripe.pricing.agentMembershipMonthly]
        if(this.state.organisations.length == 0){
            let org = await this.props.organisationStore.loadOrganisations(1);
            let isVenueHasOrg = org.filter(x => x.type === OrganisationType.Venue.ordinal).length > 0;
            this.setState({
                isVenuHasOrganisation: isVenueHasOrg,
                organisations: org
            })
        }
        let planIdbyTpe = getPricingIdArray(currentProfileType,this.state.isVenuHasOrganisation);

        var userId = billingAccess?.isMemberOfOrganisation ? billingAccess.parentUserId : this.props.userStore.currentUser.sub
        let customer = await this.props.billingStore.getCustomerByUserId(userId);
        if(customer == null){
            this.props.handleLoading(false)
            this.setShowSubscription(0, false);
            return;
        }
        if(customer && currentProfileType === ProfileType.PrivateHire.ordinal){
            card = customer.cards;
            this.setShowSubscription(currentProfileType, false);
            this.setState({
                openCreditCardDialog: false,
                openConfirmationDialog: false,
                openConfirmationCancelSubscriptionDialog: false,
                customer: customer,
                card: card,
                isCardExist: card.length > 0,
            })

            if(!card.length && this.props.isPrivateHireLock){
                this.setState({ isPrivateHireFirstOpen: true });
            }
            this.props.handleLoading(false)
            return;
        }

        if(customer.subscriptions.filter(x => planIdbyTpe.includes(x.plan.id))[0] == undefined){
        this.props.handleLoading(false)
            let isCancelled = false
          
            planIdbyTpe.forEach(item => {
                isCancelled = isCancelled || customer.inactiveSubscriptions.map(e => e.plan.id).includes(item);
               
            });


            if(isCancelled){
                this.setState({ hasSubscriptionCancelled: true})
            }
            this.setShowSubscription(0, false,customer);
            return;
        }
      
       
        if(customer.activeSchedule.length){
           
           
            planIdbyTpe.forEach(item => {
                isScheduleSubs = isScheduleSubs || customer.activeSchedule.map(e => e.phases[0]?.items[0]?.plan).includes(item);
               
            });
        }
       
        card = customer.cards;
        subscriptions = customer.subscriptions.filter(x => planIdbyTpe.includes(x.plan.id))
        // if(subscriptions.length === 1){
            subscription = customer.subscriptions.filter(x => planIdbyTpe.includes(x.plan.id))[0];
        // }else if(subscriptions.length === 2){
        //     subscription = customer.subscriptions.filter(x => basicPlanId.includes(x.plan.id))[0];
        //     secondSubscription = customer.subscriptions.filter(x => proPlanId.includes(x.plan.id))[0]; 
        //     haveSecondSubscription = true
        // }
       
        var isProPlan = false
        if(subscription && subscription.plan){
         
            isProPlan = proPlanId.includes(subscription.plan.id);
        }
        plan = subscription.items.data[0].plan;
        productPrice = `${plan.currency.toUpperCase()}$ ${parseInt(plan.amount_decimal) / 100}.00 per ${plan.interval}`
        productPriceWithout0 = `${plan.currency.toUpperCase()}$ ${parseInt(plan.amount_decimal) / 100} per ${plan.interval}`

        if(isScheduleSubs){
            var scheduleSubs = customer.activeSchedule.filter(e => planIdbyTpe.includes(e.phases[0]?.items[0]?.plan))
            planPro = scheduleSubs[0].phases[0].items[0].planObj;
            productPricePro = `${planPro.currency.toUpperCase()}$ ${parseInt(planPro.amount_decimal) / 100}.00 per ${planPro.interval}`
            productPriceWithout0Pro = `${planPro.currency.toUpperCase()}$ ${parseInt(planPro.amount_decimal) / 100} per ${planPro.interval}`
            this.setPlanIdPro(scheduleSubs[0].phases[0].items[0].plan);
            // this.handleLoadBillingTrialPeriodPro(secondSubscription);

            let periodEnd = moment.utc(moment.unix(scheduleSubs[0].phases[0].start_date)).local().diff(moment(), "days") + 1;
            let cancelledStatus = "Your membership for pro plan has " + periodEnd + " day(s) remaining. You can continue using Hot Giggity Pro Plan until the end of your current membership. To continue using Hot Giggity Pro Plan after this time, please cancel downgrade plan."
            let status = `Your membership will resume to basic plan in ${periodEnd} days`
            let scheduleStatus = `This subscription plan will be active in ${periodEnd} days`
            this.setState({
                billingInfoStatusPro: status,
                cancelledStatusPro: cancelledStatus,
                periodEndPro: periodEnd,
                scheduleStatus: scheduleStatus
            });
        }
        invoices = customer.invoice;
        this.handleLoadBillingTrialPeriod(subscription);
        this.setPlanId(subscription);
        this.setShowSubscription(currentProfileType, true);
        this.setState({
            openCreditCardDialog: false,
            openConfirmationDialog: false,
            openConfirmationCancelSubscriptionDialog: false,
            customer: customer,
            card: card,
            invoices: invoices,
            currentPlanId: subscription != null ? subscription.items.data[0].plan.id : null,
            subscriptionApp: subscriptionApp,
            subscription: subscription,
            secondSubscription: secondSubscription,

            // status: status,
            isCardExist: card.length > 0,
            productPrice: productPrice,
            productPricePro : productPricePro,
            productPriceWithout0: productPriceWithout0,
            productPriceWithout0Pro : productPriceWithout0Pro,
            isProPlan: isProPlan,
            canCancelSubscription: customer.subscriptions.length > 0,
            haveSecondSubscription: haveSecondSubscription,
            hasScheduleSubs : isScheduleSubs
        })
        this.props.handleLoading(false)
    }

    hasAccountAndMembership(){
        const { currentProfileType } = this.props.profileStore

        if(this.state.customer == null) return false;
        if(this.state.customer.subscriptions.length == 0 && currentProfileType != 1) return false;
        return true;
    }

    setShowSubscription(type, hasSubscription,customer) {
        var card = customer?.cards;
       
        if(type === 9){
            this.setState({
                showEntertainerSubscription: false,
                showAgentSubscription: false,
                showSingleVenueSubscription: false,
                showGroupVenueSubscription: false,
                showNoMembersip: false,
                showPrivateHireSubscription: true
            })
            return;
        }
        if(!hasSubscription){
            this.setState({
                showEntertainerSubscription: false,
                showAgentSubscription: false,
                showSingleVenueSubscription: false,
                showGroupVenueSubscription: false,
                showNoMembersip: true,
                card: card,
                isCardExist: card && card.length > 0,
            })
            return;
        }

        if(type === 1){
            this.setState({
                showEntertainerSubscription: true,
                showAgentSubscription: false,
                showSingleVenueSubscription: false,
                showGroupVenueSubscription: false,
                showNoMembersip: false,
            })
            return;
        }

        if(type === 2){
            this.setState({
                showEntertainerSubscription: false,
                showAgentSubscription: true,
                showSingleVenueSubscription: false,
                showGroupVenueSubscription: false,
                showNoMembersip: false,
            })
            return;
        }

        if(type === 3 ){
            if(this.state.isVenuHasOrganisation){
                this.setState({
                    showEntertainerSubscription: false,
                    showAgentSubscription: false,
                    showSingleVenueSubscription: false,
                    showGroupVenueSubscription: true,
                    showNoMembersip: false,
                })
            }else {
                this.setState({
                    showEntertainerSubscription: false,
                    showAgentSubscription: false,
                    showSingleVenueSubscription: true,
                    showGroupVenueSubscription: false,
                    showNoMembersip: false,
                })
            }
            return;
        }
        
        if(type === 9){
            this.setState({
                showEntertainerSubscription: false,
                showAgentSubscription: false,
                showSingleVenueSubscription: false,
                showGroupVenueSubscription: false,
                showNoMembersip: false,
                showPrivateHireSubscription: true
            })
            return;
        }
    }


    render(){
        const { classes } = this.props;
        const { isProPlan, planName,planNamePro,periodEndPro,productPricePro, productPriceWithout0Pro, periodEnd,productPriceWithout0 } = this.state;
        var currentProfile = this.props.profileStore.getCurrentProfile();
        // if(this.state.isNeedToReloadAndDirectToDashboard) return <Redirect to={this.gotoDashboard()} />;
        return (
            <Fragment>
                    { !this.props.isLoading ? (
                    <Grid container>
                        <Grid item xs={12}>
                        { this.state.showEntertainerSubscription && this.state.hasScheduleSubs ? (
                            <Grid>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            {planName}
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    
                                                    {this.state.billingInfoStatusPro != null
                                                            ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatusPro} color="success" /></div>
                                                            : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                        }
                                                        
                                                    {this.state.cancelledStatusPro != null
                                                            ? <div>
                                                                <br />
                                                                    <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                              
                                                                {this.state.cancelledStatusPro}
                                                                </div>
                                                            : ""
                                                        }
                                                    <br />
                                                    <div style={{marginTop:20}}>Fee: {productPriceWithout0} (ex GST)</div>
                                                    <br />
                                                    <p><b>Saved Payment Methods</b></p>
                                                        { this.state.isCardExist ? (this.showCardNumber()) : ""}
                                                        <br />
                                                        <br />
                                                    <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Multimedia act profiles to feature your act(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be found by agents, venues, and private hirers</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and connect with agencies</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage players in your bands</p>
                                                                </li>
                                                                <li>
                                                                    <p>Total invoicing solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution including availability management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage social media posts</p>
                                                                </li>
                                                                <li>
                                                                    <p>Google Calendar two-way integration</p>
                                                                </li>
                                                                <li>
                                                                    <p>Financial reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integrations (MYOB, XERO).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be listed as a featured act (coming soon)</p>
                                                                </li>
                                                            </ul>
                                                           
                                                </Grid>
                                            </Grid>
                                           
                                    </Card>
                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                    
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            {planNamePro}
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    {/* {this.state.billingInfoStatusPro != null
                                                            ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatusPro} color="success" /></div>
                                                            : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                        } */}
                                                    <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label={this.state.scheduleStatus} color="success" /></div>
                                                   
                                                    <div style={{marginTop:20}}>Fee: Free</div>
                                                    <br />
                                                    <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Multimedia act profiles to feature your act(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be found by agents, venues, and private hirers</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and connect with agencies</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage players in your bands</p>
                                                                </li>
                                                                <li>
                                                                    <p>Total invoicing solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution including availability management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage social media posts</p>
                                                                </li>
                                                                <li>
                                                                    <p>Google Calendar two-way integration</p>
                                                                </li>
                                                                <li>
                                                                    <p>Financial reporting</p>
                                                                </li>
                                                            </ul> 
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    {this.state.isCardExist &&  <div style={{ marginLeft: -8, marginRight:25}}>
                                                        <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => this.handleOpenDialog()}
                                                                className={classes.button} fullWidth>
                                                                Update Payment Method
                                                            </Button>
                                                    </div>}
                                                    <div style={{ marginLeft: -8, marginRight:25}}>
                                                        <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => this.handleOpenDialogCancelDowngrade()}
                                                                className={classes.button} fullWidth>
                                                                Cancel Downgrade to Free Plan
                                                            </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                            ) : null}
                            
                            { this.state.showEntertainerSubscription && !this.state.hasScheduleSubs? (
                                <Grid>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                        <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            {isProPlan ? planName : "Entertainer Basic Membership "}
                                                                </Typography>
                                                        </div>
                                                        {!isProPlan ?  <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active"  /></div> :
                                                            <div style={{ marginTop: 19}}>
                                                               <Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" />
                                                            </div>
                                                        }
                                                        {isProPlan ? <div style={{marginTop:20}}>Fee: {productPriceWithout0} (ex GST)</div> : <div>Fee: Free</div>}
                                                       
                                                        {this.state.cancelledStatusPro != null
                                                            ? (
                                                                <div>
                                                                    <br />
                                                                    <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                                    <br/>
                                                                    {this.state.cancelledStatusPro}
                                                                </div>
                                                            )
                                                            : ""
                                                        }
                                                        <br />
                                                        <br />

                                                        {isProPlan && 
                                                        (
                                                            <div>
                                                                <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Multimedia act profiles to feature your act(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Be found by agents, venues, and private hirers</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Search and connect with agencies</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage players in your bands</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Total invoicing solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution including availability management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage social media posts</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Google Calendar two-way integration</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Financial reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integrations (MYOB, XERO).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Be listed as a featured act (coming soon)</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                        }
                                                        {!isProPlan && 
                                                        (
                                                            <div>
                                                                <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Multimedia act profiles to feature your act(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Be found by agents, venues, and private hirers</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Search and connect with agencies</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage players in your bands</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Total invoicing solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution including availability management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage social media posts</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Google Calendar two-way integration</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Financial reporting</p>
                                                                    </li>
                                                                </ul> 
                                                            </div>
                                                        )
                                                        }
                                                        <br />
                                                        <p><b>Saved Payment Methods</b></p>
                                                        <br />
                                                        { this.state.isCardExist ? (this.showCardNumber()) : ""}
                                                        <br />
                                                            <br />
                                                            {isProPlan ?
                                                            <div>
                                                                  <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenDialog()}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>
                                                            </div>
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                        className={classes.button} fullWidth>
                                                                        Downgrade to Free Plan
                                                                    </Button>
                                                            </div>
                                                            </div> : 
                                                              <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenDialogProPlan()}
                                                                    className={classes.button} fullWidth>
                                                                    Upgrade to Pro Plan
                                                                </Button>
                                                               </div>
                                                               }
                                                          
                                                     
                                                    </Grid>
                                                </Grid> 
                                        </Card>
                                    
                                    </Grid>

                                    {isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Entertainer Membership Free
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: Free</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Multimedia act profiles to feature your act(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be found by agents, venues, and private hirers</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and connect with agencies</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage players in your bands</p>
                                                                </li>
                                                                <li>
                                                                    <p>Total invoicing solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution including availability management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage social media posts</p>
                                                                </li>
                                                                <li>
                                                                    <p>Google Calendar two-way integration</p>
                                                                </li>
                                                                <li>
                                                                    <p>Financial reporting</p>
                                                                </li>
                                                            </ul> 
                                                            
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                          
                                    )}

                                     {!isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Entertainer Pro Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 14.99 (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Multimedia act profiles to feature your act(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be found by agents, venues, and private hirers</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and &lsquo;Apply to Play&rsquo; at venues</p>
                                                                </li>
                                                                <li>
                                                                    <p>Search and connect with agencies</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage players in your bands</p>
                                                                </li>
                                                                <li>
                                                                    <p>Total invoicing solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution including availability management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage social media posts</p>
                                                                </li>
                                                                <li>
                                                                    <p>Google Calendar two-way integration</p>
                                                                </li>
                                                                <li>
                                                                    <p>Financial reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integrations (MYOB, XERO).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Be listed as a featured act (coming soon)</p>
                                                                </li>
                                                            </ul>
                                                          
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                          
                                    )}
                                    
                                </Grid>

                            ) : null}

                          

                            { this.state.showPrivateHireSubscription &&  !this.state.isMemberOfOrganisation  ? (
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12} key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                    <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                    Parties & Events Per Gig Fee 
                                                            </Typography>
                                                    </div>

                                                    <br />
                                                    <br />
                                                     <br />
                                                    Fee: $25 (ex GST) per completed gig.
                                                    <br />
                                                    <br />
                                                    Details: You will only be charged after you booking is marked as 'Completed'.       
                                                    <br />
                                                    <br />
                                                    { this.state.isCardExist ? (this.showCardNumber()) : ""}
                                                    <br />
                                                        <br />
                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                            <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenDialog()}
                                                                    className={classes.button} fullWidth>
                                                                    Update Payment Method
                                                                </Button>
                                                        </div>
                                                </Grid>
                                            </Grid>
                                    </Card>
                                </Grid>

                            ) : null}

                            { this.state.showPrivateHireSubscription &&  this.state.isMemberOfOrganisation  ? (
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12} key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                        Parties & Events Per Gig Fee
                                                            </Typography>
                                                    </div>

                                                    <br />
                                                    <br />
                                                     <br />
                                                    Fee: $25 (ex GST) per completed gig.
                                                    <br />
                                                    <br />
                                                    Details: You will only be charged after you booking is marked as 'Completed'.       
                                                    <br />
                                                    <br />
                                                
                                                </Grid>
                                            </Grid>
                                    </Card>
                                </Grid>

                            ) : null}
                            
                            { this.state.showAgentSubscription && !this.state.isMemberOfOrganisation && this.state.hasScheduleSubs &&
                                (  
                                    <Grid>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{  float: "left", paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                { this.state.planName }
                                                                </Typography>
                                                            </div>
                                                            {this.state.billingInfoStatusPro != null
                                                                ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatusPro} color="success" /></div>
                                                                : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                            }
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                            
                                                            {this.state.cancelledStatusPro != null
                                                                ? this.state.cancelledStatusPro
                                                                : ""
                                                            }
                                                            <br/>
                                                            <br/>
                                                            Fee: {this.state.productPrice} (ex GST)
                                                            <br />
                                                            Free Trial : 14 days 
                                                            <br />
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            {isProPlan ? 
                                                            (
                                                                <ul>
                                                                    <li>
                                                                        <p>Consultant management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue roster management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                    </li>
                                                                </ul> 
                                                            ) 
                                                            : (
                                                                <ul>
                                                                    <li>
                                                                        <p>Consultant management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue roster management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                </ul> 
                                                            )}
                                                        
                                                            <br />
                                                            <p><b>Saved Payment Methods</b></p>
                                                            <br />
                                                            { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                            <br />
                                                            <br />
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialog()}
                                                                            className={classes.button} fullWidth>
                                                                            Update Payment Method
                                                                        </Button>

                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogCancelDowngrade()}
                                                                                className={classes.button} fullWidth>
                                                                                Cancel Downgrade to Free Plan
                                                                        </Button>
                                                                    <br/>
                                                                    {
                                                                        this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                        <Button disabled={false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                            className={classes.button} fullWidth>
                                                                            Resubscribe 
                                                                        </Button>
                                                                        :
                                                                        <Button disabled={!this.state.canCancelSubscription}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                        className={classes.button} fullWidth>
                                                                        Cancel Membership
                                                                    </Button>
                                                                    }
                                                                
                                                                    <br/>
                                                                    <br/>


                                                                </div>
                                                            {/* {isProPlan &&
                                                                <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialog()}
                                                                            className={classes.button} fullWidth>
                                                                            Update Payment Method
                                                                        </Button>

                                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogCancelDowngrade()}
                                                                                className={classes.button} fullWidth>
                                                                                Cancel Downgrade to Free Plan
                                                                            </Button>
                                                                    </div>
                                                                    <br/>
                                                                    {
                                                                        this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                        <Button disabled={false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                            className={classes.button} fullWidth>
                                                                            Resubscribe 
                                                                        </Button>
                                                                        :
                                                                        <Button disabled={!this.state.canCancelSubscription}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                        className={classes.button} fullWidth>
                                                                        Cancel Membership
                                                                    </Button>
                                                                    }
                                                                
                                                                    <br/>
                                                                    <br/>


                                                                </div>
                                                            } */}
                                                            {/* {!isProPlan &&
                                                                <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialog()}
                                                                            className={classes.button} fullWidth>
                                                                            Update Payment Method
                                                                        </Button>

                                                                    {!isProPlan && 
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Upgrade to Pro Plan
                                                                            </Button>
                                                                    }
                                                                    {isProPlan &&
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Downgrade to Free Plan
                                                                            </Button>
                                                                    }
                                                                    <br/>
                                                                    {
                                                                        this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                        <Button disabled={false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                            className={classes.button} fullWidth>
                                                                            Resubscribe 
                                                                        </Button>
                                                                        :
                                                                        <Button disabled={!this.state.canCancelSubscription}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                        className={classes.button} fullWidth>
                                                                        Cancel Membership
                                                                    </Button>
                                                                    }
                                                                
                                                                    <br/>
                                                                    <br/>


                                                                </div>
                                                            } */}
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    {isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                             <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Agent Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label={this.state.scheduleStatus} color="success" /></div>
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 49.00  (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Consultant management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue roster management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                            </ul> 
                                                         
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                    {!isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Agent Pro Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 129.00 (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Consultant management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue roster management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                </li>
                                                            </ul> 
                                                          
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                                )
                            }
                            { this.state.showAgentSubscription && !this.state.isMemberOfOrganisation && !this.state.hasScheduleSubs ? (
                               <Grid>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{  float: "left", paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                { this.state.planName }
                                                                </Typography>
                                                            </div>
                                                            {this.state.billingInfoStatus != null
                                                                ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                                : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                            }
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                           
                                                            {this.state.cancelledStatus != null
                                                                ? this.state.cancelledStatus
                                                                : ""
                                                            }
                                                            <br/>
                                                            Fee: {this.state.productPrice} (ex GST)
                                                            <br />
                                                            Free Trial : 14 days
                                                            <br />
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            {isProPlan ? 
                                                            (
                                                                <ul>
                                                                    <li>
                                                                        <p>Consultant management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue roster management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                    </li>
                                                                </ul> 
                                                            ) 
                                                            : (
                                                                <ul>
                                                                    <li>
                                                                        <p>Consultant management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue roster management</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Income reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                </ul> 
                                                            )}
                                                        
                                                            <br />
                                                            <p><b>Saved Payment Methods</b></p>
                                                            <br />
                                                            { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                            <br />
                                                            <br />
                                                            {isProPlan &&
                                                                <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialog()}
                                                                            className={classes.button} fullWidth>
                                                                            Update Payment Method
                                                                        </Button>

                                                                    {!isProPlan && 
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Upgrade to Pro Plan
                                                                            </Button>
                                                                    }
                                                                    {isProPlan &&
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Downgrade to Free Plan
                                                                            </Button>
                                                                    }
                                                                    <br/>
                                                                    {
                                                                        this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                        <Button disabled={false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                            className={classes.button} fullWidth>
                                                                            Resubscribe 
                                                                        </Button>
                                                                        :
                                                                        <Button disabled={!this.state.canCancelSubscription}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                        className={classes.button} fullWidth>
                                                                        Cancel Membership
                                                                    </Button>
                                                                    }
                                                                
                                                                    <br/>
                                                                    <br/>


                                                                </div>
                                                            }
                                                            {!isProPlan &&
                                                                <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialog()}
                                                                            className={classes.button} fullWidth>
                                                                            Update Payment Method
                                                                        </Button>

                                                                    {!isProPlan && 
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Upgrade to Pro Plan
                                                                            </Button>
                                                                    }
                                                                    {isProPlan &&
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                                className={classes.button} fullWidth>
                                                                                Downgrade to Free Plan
                                                                            </Button>
                                                                    }
                                                                    <br/>
                                                                    {
                                                                        this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                        <Button disabled={false}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                            className={classes.button} fullWidth>
                                                                            Resubscribe 
                                                                        </Button>
                                                                        :
                                                                        <Button disabled={!this.state.canCancelSubscription}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                        className={classes.button} fullWidth>
                                                                        Cancel Membership
                                                                    </Button>
                                                                    }
                                                                
                                                                    <br/>
                                                                    <br/>


                                                                </div>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    {isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                             <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Agent Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 49.00  (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Consultant management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue roster management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                            </ul> 
                                                         
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                    {!isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Agent Pro Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 129.00 (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Consultant management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue roster management</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists&nbsp;</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Income reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete invoicing solution including batched invoicing (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive batched invoices from artists (weekly, fortnightly, monthly).</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                </li>
                                                            </ul> 
                                                          
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : null}

                            { this.state.showAgentSubscription && this.state.isMemberOfOrganisation ? (
                                <>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName }
                                                            </Typography>
                                                        </div>
                                                        {this.state.billingInfoStatus != null
                                                            ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                            : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                        }
                                                        <br />
                                                        <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                        Fee: {this.state.productPrice} (ex GST)
                                                        <br />
                                                        Free Trial : 14 days
                                                        <br />
                                                        {/* <p><b>Saved Payment Methods</b></p>
                                                        <br />
                                                        { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                        <br />
                                                        <br /> */}
                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                            {/* <b>Features </b>
                                                            <br/>
                                                            <ul>
                                                                <li>Full profile page</li>
                                                                <li>Calendar and booking management</li>
                                                                <li>Act roster management </li>
                                                                <li>Venue roster management</li>
                                                            </ul> */}

                                   
                                                            <br/>
                                                            <br/>


                                                        </div>
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>
                                </>
                            ) : null}

                            { this.state.showSingleVenueSubscription && !this.state.isMemberOfOrganisation && !this.state.hasScheduleSubs ? (
                                <>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName }
                                                            </Typography>
                                                        </div>
                                                        <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                        <br />
                                                        {this.state.cancelledStatus != null
                                                            ? this.state.cancelledStatus
                                                            : ""
                                                        }
                                                        <br />
                                                        <br />
                                                        Fee: {this.state.productPrice} (ex GST)
                                                        <br />
                                                        Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                        {isProPlan ?
                                                        ( <div>
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                    <br/>
                                                                    <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                        </li>
                                                                    </ul>
                                                            </div>) :  <div>
                                                        <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                    </ul>
                                                        </div> }
                                                       
                                                        <br/>
                                                        <p><b>Saved Payment Methods</b></p>
                                                        { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                        <br />
                                                        <br />
                                                        {isProPlan &&
                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                           
                                                        <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenDialog()}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>

                                                                {!isProPlan &&  <div>
                                                                    <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialogProPlan()}
                                                                            className={classes.button} fullWidth>
                                                                            Upgrade to Pro Plan
                                                                        </Button>
                                                                </div>}
                                                                {isProPlan &&  <div>
                                                                    <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                            className={classes.button} fullWidth>
                                                                            Downgrade to Free Plan
                                                                        </Button>
                                                                </div>}
                                                                <br/>
                                                                {
                                                                    this.state.cancelledStatus && !this.state.hasScheduleSubs ?
                                                                    <Button disabled={false}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                        className={classes.button} fullWidth>
                                                                        Resubscribe 
                                                                    </Button>
                                                                    :
                                                                    <Button disabled={!this.state.canCancelSubscription}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                    className={classes.button} fullWidth>
                                                                    Cancel Membership to Free Plan
                                                                </Button>
                                                                }
                                                            
                                                                <br/>
                                                                <br/>


                                                        </div>
                                                        }
                                                        {!isProPlan && 
                                                          <div style={{ marginLeft: -8, marginRight:25}}>
                                                          {/* <b>Features </b>
                                                          <br/>
                                                          <ul>
                                                              <li>Full profile page</li>
                                                              <li>Calendar and booking management</li>
                                                              <li>Act roster management </li>
                                                              <li>Venue roster management</li>
                                                          </ul> */}

                                                              <Button
                                                                  variant="contained"
                                                                  color="primary"
                                                                  onClick={() => this.handleOpenDialog()}
                                                                  className={classes.button} fullWidth>
                                                                  Update Payment Method
                                                              </Button>

                                                          {!isProPlan &&  <div>
                                                              <Button
                                                                      variant="contained"
                                                                      color="primary"
                                                                      onClick={() => this.handleOpenDialogProPlan()}
                                                                      className={classes.button} fullWidth>
                                                                      Upgrade to Pro Plan
                                                                  </Button>
                                                          </div>}
                                                          {isProPlan &&  <div>
                                                              <Button
                                                                      variant="contained"
                                                                      color="primary"
                                                                      onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                      className={classes.button} fullWidth>
                                                                      Downgrade to Free Plan
                                                                  </Button>
                                                          </div>}
                                                      {
                                                          this.state.cancelledStatus ?
                                                          <Button disabled={false}
                                                              variant="contained"
                                                              color="primary"
                                                              onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                              className={classes.button} fullWidth>
                                                              Resubscribe
                                                          </Button>
                                                          :
                                                          <Button disabled={!this.state.canCancelSubscription}
                                                              variant="contained"
                                                              color="primary"
                                                              onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                              className={classes.button} fullWidth>
                                                              Downgrade to Free Plan
                                                          </Button>
                                                      }
                                                      <br/>
                                                      <br/>
                                                      
                                                          <br/>
                                                          <br/>


                                                      </div>}
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Membership Free Plan
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: Free</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                            </ul>
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                {isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                             <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 79.00 (ex GST)</div>
                                                            <br />
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue staff with permission-based access</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage your agents to give them access to book your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Spend vs budget reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Artist compliance with terms and conditions</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                </li>
                                                                <li>
                                                                    <p>Send invoices to accounts via email</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on venue screens</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on your website</p>
                                                                </li>
                                                            </ul>
                                                                 
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                    {!isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Pro Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 129.00 (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue staff with permission-based access</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage your agents to give them access to book your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Spend vs budget reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Artist compliance with terms and conditions</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                </li>
                                                                <li>
                                                                    <p>Send invoices to accounts via email</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on venue screens</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on your website</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                </li>
                                                            </ul>
                                                          
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                </>
                            ) : null}

                            { this.state.showSingleVenueSubscription && !this.state.isMemberOfOrganisation && this.state.hasScheduleSubs ? (
                                <>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName }
                                                            </Typography>
                                                        </div>
                                                        {this.state.billingInfoStatusPro != null
                                                                    ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatusPro} color="success" /></div>
                                                                    : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                                }
                                                            
                                                            {this.state.cancelledStatusPro != null
                                                                    ? <div>
                                                                        <br />
                                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                                    
                                                                        {this.state.cancelledStatusPro}
                                                                        </div>
                                                                    : ""
                                                                }
                                                            <br />
                                                            <div style={{marginTop:20}}>Fee: {productPriceWithout0} (ex GST)</div>
                                                            <br />
                                                            {isProPlan ? <div>
                                                                <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue staff with permission-based access</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage your agents to give them access to book your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Spend vs budget reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Artist compliance with terms and conditions</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Send invoices to accounts via email</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on venue screens</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on your website</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                    </li>
                                                                </ul>
                                                            </div> : <div>
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue staff with permission-based access</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage your agents to give them access to book your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Spend vs budget reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Artist compliance with terms and conditions</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Send invoices to accounts via email</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on venue screens</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on your website</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                    </li>
                                                                </ul>
                                                            </div>}
                                                        <p><b>Saved Payment Methods</b></p>
                                                        <br />
                                                        { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                        <br />
                                                        <br />
                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                           
                                                            <br/>
                                                            <br/>


                                                        </div>
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>

                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Membership Free Plan
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: Free</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                            </ul>
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                {isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                             <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 79.00 (ex GST)</div>
                                                            <br />
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue staff with permission-based access</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage your agents to give them access to book your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Spend vs budget reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Artist compliance with terms and conditions</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                </li>
                                                                <li>
                                                                    <p>Send invoices to accounts via email</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on venue screens</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on your website</p>
                                                                </li>
                                                            </ul>
                                                            <Grid container spacing={16}>
                                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                                    {this.state.isCardExist &&  <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialog()}
                                                                                className={classes.button} fullWidth>
                                                                                Update Payment Method
                                                                            </Button>
                                                                    </div>}
                                                                    <div style={{ marginLeft: -8, marginRight:25}}>
                                                                        <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() => this.handleOpenDialogCancelDowngrade()}
                                                                                className={classes.button} fullWidth>
                                                                                Cancel Downgrade to Free Plan
                                                                            </Button>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                    {!isProPlan && (
                                        <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{ float: "left", paddingRight:10}}>
                                                        
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Pro Membership
                                                            </Typography>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div style={{marginTop:20}}>Fee: AUD$ 129.00 (ex GST)</div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue staff with permission-based access</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage your agents to give them access to book your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Spend vs budget reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Artist compliance with terms and conditions</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                </li>
                                                                <li>
                                                                    <p>Send invoices to accounts via email</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on venue screens</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on your website</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                </li>
                                                            </ul>
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                {/* <b>Features </b>
                                                                <br/>
                                                                <ul>
                                                                    <li>Full profile page</li>
                                                                    <li>Calendar and booking management</li>
                                                                    <li>Act roster management </li>
                                                                    <li>Venue roster management</li>
                                                                </ul> */}

                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => this.handleOpenDialog()}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>

                                                                {!isProPlan &&  <div>
                                                                    <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialogProPlan()}
                                                                            className={classes.button} fullWidth>
                                                                            Upgrade to Pro Plan
                                                                        </Button>
                                                                </div>}
                                                                {isProPlan &&  <div>
                                                                    <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => this.handleOpenDialogDowngradeProPlan()}
                                                                            className={classes.button} fullWidth>
                                                                            Downgrade to Free Plan
                                                                        </Button>
                                                                </div>}
                                                            {
                                                                this.state.cancelledStatus ?
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Resubscribe
                                                                </Button>
                                                                :
                                                                <Button disabled={!this.state.canCancelSubscription}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                    className={classes.button} fullWidth>
                                                                    Cancel Membership to Basic Plan
                                                                </Button>
                                                            }
                                                            <br/>
                                                            <br/>
                                                            
                                                                <br/>
                                                                <br/>


                                                            </div>
                                                    </Grid>
                                                </Grid> 
                                            </Card>
                                        </Grid>
                                    )}
                                </>
                            ) : null}

                            { this.state.showSingleVenueSubscription && this.state.isMemberOfOrganisation ? (
                                <>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName }
                                                            </Typography>
                                                        </div>
                                                        <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                        <br />
                                                        Fee: {this.state.productPrice} (ex GST)
                                                        <br />
                                                        Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>
                                </>
                            ) : null}

                            { this.state.showGroupVenueSubscription && !this.state.isMemberOfOrganisation? (
                                <>
                                <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName } - {currentProfile?.isProPlan ? "Pro Plan" : currentProfile?.isFreeUser ? "Basic Plan" : "Free Plan"} 
                                                            </Typography>
                                                        </div>
                                                        {this.state.billingInfoStatus != null && this.state.isTrial
                                                            ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                            : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                        }
                                                        <br />
                                                        <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                     
                                                        {this.state.cancelledStatus != null
                                                            ? this.state.cancelledStatus
                                                            : ""
                                                        }
                                                        Type: Manual Invoice
                                                        <br />
                                                        Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                        {!currentProfile?.isProPlan && !currentProfile?.isFreeUser ?
                                                            (<div>
                                                                  <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                    </li>
                                                                </ul>
                                                            </div>) : null
                                                            } 
                                                             {currentProfile?.isProPlan ?
                                                           ( <div>
                                                                 <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue staff with permission-based access</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage your agents to give them access to book your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Spend vs budget reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Artist compliance with terms and conditions</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Send invoices to accounts via email</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on venue screens</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on your website</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                    </li>
                                                                </ul>
                                                            </div>) :  null
                                                            }
                                                             {!currentProfile?.isProPlan && currentProfile?.isFreeUser ?
                                                            (<div>
                                                                 <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue staff with permission-based access</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Manage your agents to give them access to book your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Act roster management - search and connect with artists</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete booking management including contracts, worksheets</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Spend vs budget reporting</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Artist compliance with terms and conditions</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Complete calendar solution</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Send invoices to accounts via email</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Social post scheduling</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on venue screens</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Display gig guides on your website</p>
                                                                    </li>
                                                                </ul>
                                                            </div>) : null
                                                            }
                                                              <br />
                                                        {/* { this.state.isCardExist ? (this.showCardNumber()) : "-"} */}
                                                        <br />
                                                        <div style={{ marginLeft: -8, marginRight:25}}>
                                                           
                                                           
                                                            <br/>
                                                           
                                                                {/* <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenDialog()}
                                                                    className={classes.button} fullWidth>
                                                                    Update Payment Method
                                                                </Button> */}
                                                            
                                                            <br/>
                                                            {
                                                                this.state.cancelledStatus ?
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Resubscribe
                                                                </Button>
                                                                :
                                                                <Button disabled={!this.state.canCancelSubscription}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                    className={classes.button} fullWidth>
                                                                    Cancel Membership
                                                                </Button>
                                                            }
                                                            {
                                                                (currentProfile?.isFreeUser && !currentProfile?.isProPlan)  ? 
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => window.open(`mailto:support@hotgiggity.com?subject=${encodeURIComponent("Upgrade to Pro Plan")}`, '_self')}
                                                                    className={classes.button} fullWidth>
                                                                    Upgrade to Pro Plan
                                                                </Button> : null
                                                            }

                                                            {
                                                                !currentProfile?.isProPlan && !currentProfile?.isFreeUser ? 
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => window.open(`mailto:support@hotgiggity.com?subject=${encodeURIComponent("Upgrade to Pro Plan")}`, '_self')}
                                                                    className={classes.button} fullWidth>
                                                                    Upgrade to Basic/Pro Plan
                                                                </Button> : null
                                                            }

                                                            {
                                                                currentProfile?.isProPlan ? 
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => window.open('https://support.hotgiggity.com', '_system')}
                                                                    className={classes.button} fullWidth>
                                                                    Downgrade to basic/free plan
                                                                </Button> : null
                                                            }
                                                            
                                                            <br/>
                                                            <br/>


                                                        </div>
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>

                                {!currentProfile?.isProPlan && 
                                 <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{  float: "left", paddingRight:15}}>
                                                    <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Venue Group Membership Pro Plan
                                                        </Typography>
                                                    </div>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <div>
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue staff with permission-based access</p>
                                                                </li>
                                                                <li>
                                                                    <p>Manage your agents to give them access to book your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Act roster management - search and connect with artists</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete booking management including contracts, worksheets</p>
                                                                </li>
                                                                <li>
                                                                    <p>Spend vs budget reporting</p>
                                                                </li>
                                                                <li>
                                                                    <p>Artist compliance with terms and conditions</p>
                                                                </li>
                                                                <li>
                                                                    <p>Complete calendar solution</p>
                                                                </li>
                                                                <li>
                                                                    <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                </li>
                                                                <li>
                                                                    <p>Send invoices to accounts via email</p>
                                                                </li>
                                                                <li>
                                                                    <p>Social post scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on venue screens</p>
                                                                </li>
                                                                <li>
                                                                    <p>Display gig guides on your website</p>
                                                                </li>
                                                                <li>
                                                                    <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                </Grid>
                                            </Grid>
                                    </Card>

                                </Grid>}

                                {(!currentProfile?.isFreeUser || currentProfile?.isProPlan) ?
                                 <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{  float: "left", paddingRight:15}}>
                                                    <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Venue Group Membership Basic Plan
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div>
                                                            <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                    </ul>
                                                        </div>
                                                </Grid>
                                            </Grid>
                                    </Card>

                                </Grid> : null}

                                {currentProfile?.isProPlan || currentProfile?.isFreeUser ? 
                                 <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                    <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Venue Group Membership Free Plan
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    {/* <br />
                                                    {this.state.billingInfoStatus != null && this.state.isTrial
                                                        ?  <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label={this.state.billingInfoStatusVenue} color="success" /></div>
                                                        : null
                                                    } */}
                                                    <br />
                                                    <br />
                                                    <div>
                                                        <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                            <ul>
                                                                <li>
                                                                    <p>Manage your venue(s)</p>
                                                                </li>
                                                                <li>
                                                                    <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                                <li>
                                                                    <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                </li>
                                                            </ul>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                    </Card>

                                </Grid> : null}
                                </>
                            ) : null}

                            { this.state.showGroupVenueSubscription && this.state.isMemberOfOrganisation? (
                                <>
                               <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                                <Grid container spacing={16}>
                                                    <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                        <div style={{  float: "left", paddingRight:15}}>
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            { this.state.planName } - {currentProfile?.isProPlan ? "Pro Plan" : currentProfile?.isFreeUser ? "Basic Plan" : "Free Plan"} 
                                                            </Typography>
                                                        </div>
                                                        {this.state.billingInfoStatus != null && this.state.isTrial
                                                            ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "red", color: "white"}} label={this.state.billingInfoStatus} color="success" /></div>
                                                            : <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                        }
                                                        <br />
                                                        {this.state.cancelledStatus != null
                                                            ? this.state.cancelledStatus
                                                            : ""
                                                        }
                                                        Type: Manual Invoice
                                                        <br />
                                                        Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                        {!currentProfile?.isProPlan && !currentProfile?.isFreeUser ?
                                                            (<div>
                                                                 <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                    <li>
                                                                        <p>Manage your venue(s)</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                    </li>
                                                                    <li>
                                                                        <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                    </li>
                                                                </ul>
                                                            </div>) : null
                                                            } 
                                                             {currentProfile?.isProPlan ?
                                                           ( <div>
                                                                <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                        </li>
                                                                    </ul>
                                                            </div>) :  null
                                                            }
                                                             {!currentProfile?.isProPlan && currentProfile?.isFreeUser ?
                                                            (<div>
                                                                 <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                    </ul>
                                                            </div>) : null
                                                            }
                                                             {/* <br />
                                                              { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                              <br /> */}
                                                    </Grid>
                                                </Grid>
                                        </Card>

                                </Grid>
                                </>
                            ) : null}

                            { this.state.showNoMembersip ? (
                                    <>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{ paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            There is no membersip plan
                                                            </Typography>
                                                            </div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                      
                                                            {this.state.hasSubscriptionCancelled 
                                                                    ? <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Membership cancelled"  /></div>
                                                                    : "-"
                                                            }
                                                            <br />
                                                            <br />
                                                            <p><b>Saved Payment Methods</b></p>
                                                            <br />
                                                            { this.state.isCardExist ? (this.showCardNumber()) : "-"}
                                                            <br />
                                                            <br />
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                {/* <b> Features</b>
                                                                <br />
                                                                -
                                                            <br /> */}
                                                                <br/>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={null}
                                                                    className={classes.button} fullWidth>
                                                                    Update Payment Method
                                                                </Button>


                                                                <br/>
                                                                {/* {
                                                                this.state.cancelledStatus ?
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Resubscribe
                                                                </Button>
                                                                :
                                                                <Button disabled={!this.state.canCancelSubscription}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => this.handleOpenUpdateSubscriptionStatus()}
                                                                className={classes.button} fullWidth>
                                                                Cancel Membership
                                                            </Button>
                                                            } */}
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Resubscribe
                                                                </Button>
                                                                <br/>
                                                                <br/>


                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    </>
                            ) : null}

                            { this.state.showForceSubscriptionDiaglog && this.state.isMemberOfOrganisation ? (
                                    <>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{ paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Your account is no longer active, speak with your system administrator.
                                                            </Typography>
                                                            </div>
                                                            <br />
                                                           
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    </>
                            ) : null}

                            { this.props.profileStore.currentProfileType  === 3 && (this.state.showForceSubscriptionDiaglog || this.state.showUpdatePaymentDialog) && !this.state.isMemberOfOrganisation ? (
                                   <>
                                   <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                   <Card>
                                   <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                                Single Venue Membership Free Plan
                                                            </Typography>
                                                            <br />
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                            <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                                            <br />
                                                            Fee : Free
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <div>
                                                                <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                    <br/>
                                                                    <ul>
                                                                        <li>
                                                                            <p>Manage your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                                        </li>
                                                                    </ul>
                                                            </div>
                                                            <br />
                                                            </div>
                                                            </Grid>
                                                            </Grid>
                                                            </Card>
                                                        </Grid>
   
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                    
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Single Venue Membership
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div style={{marginTop:20}}>Fee: AUD$ 79.00 (ex GST)</div>
                                                    <br />
                                                            Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                        <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                 <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                    </ul>
                                                                    {
                                                            this.props.billingStore.billingAccess.isNeedToUpdatePayment || this.state.isCardExist ?
                                                            ( <Grid container spacing={16}>
                                                                <Grid item xs key={16} style={{ marginRight: 20 }}>
                                                                    <Button disabled={false}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={this.handleOpenDialog}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>   
                                                                </Grid>
                                                            </Grid>) :
                                                            null
                                                        }
                                                        {
                                                             this.props.billingStore.billingAccess.isSubscriptionRequired || this.state.isCardExist ?
                                                             ( <Grid container spacing={16}>
                                                                <Grid item xs key={16} style={{ marginRight: 20 }}>
                                                                    <Button disabled={false}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                        className={classes.button} fullWidth>
                                                                        Subscribe
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>) :
                                                             null
                                                        }
                                                       
                                                       
                                                </Grid>
                                            </Grid> 
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                        <Card>
                                            <Grid container spacing={16}>
                                                <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                    <div style={{ float: "left", paddingRight:10}}>
                                                    
                                                        <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Single Venue Pro Membership
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div style={{marginTop:20}}>Fee: AUD$ 129.00 (ex GST)</div>
                                                    <br />
                                                            Free Trial : 14 days
                                                        <br />
                                                        <br />
                                                        <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                                <ul>
                                                                        <li>
                                                                            <p>Manage your venue staff with permission-based access</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Manage your agents to give them access to book your venue(s)</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Act roster management - search and connect with artists</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete booking management including contracts, worksheets</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Spend vs budget reporting</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Artist compliance with terms and conditions</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Complete calendar solution</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Receive automated invoices from agents and artists - batched weekly</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Send invoices to accounts via email</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Social post scheduling</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on venue screens</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Display gig guides on your website</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Accounting package integration - XERO and MYOB (Quickbooks coming soon)</p>
                                                                        </li>
                                                                    </ul>
                                                        {
                                                            this.props.billingStore.billingAccess.isNeedToUpdatePayment || this.state.isCardExist ?
                                                            ( <Grid container spacing={16}>
                                                                <Grid item xs key={16} style={{ marginRight: 20 }}>
                                                                    <Button disabled={false}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={this.handleOpenDialog}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>   
                                                                </Grid>
                                                            </Grid>) :
                                                            null
                                                        }
                                                        {
                                                             this.props.billingStore.billingAccess.isSubscriptionRequired || this.state.isCardExist ?
                                                             ( <Grid container spacing={16}>
                                                                 <Grid item xs key={16} style={{ marginRight: 20 }}>
                                                                     <Button disabled={false}
                                                                         variant="contained"
                                                                         color="primary"
                                                                         onClick={() => { this.setState({ isRegisterSingleVenueProPlan: true });}}
                                                                         className={classes.button} fullWidth>
                                                                         Subscribe Pro Plan
                                                                     </Button>
                                                                 </Grid>
                                                             </Grid>) :
                                                             null
                                                        }
                                                       
                                                        
                                                </Grid>
                                            </Grid> 
                                        </Card>
                                    </Grid>
                                    
                                    
                                   </>
                            ) : null}

                            { this.props.profileStore.currentProfileType  !== 1 && this.props.profileStore.currentProfileType  !== 3 && this.state.showForceSubscriptionDiaglog && !this.state.isMemberOfOrganisation ? (
                                    <>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{ paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            Please subscribe to access features
                                                            </Typography>
                                                            </div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                            Plan : { this.state.planName }
                                                            <br />
                                                            Fee: AUD$ {this.state.productPrice} (ex GST)
                                                            <br />
                                                            Free Trial : 14 days
                                                            <br />
                                                            <br />
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <br/>
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Subscribe
                                                                </Button>
                                                                {
                                                                    this.state.isCardExist ? 
                                                                    ( <Button disabled={false}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={this.handleOpenDialog}
                                                                        className={classes.button} fullWidth>
                                                                        Update Payment Method
                                                                    </Button>) : null
                                                                }
                                                                <br/>
                                                                <br/>


                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    </>
                            ) : null}

                            { this.props.profileStore.currentProfileType  === 1 && this.state.showForceSubscriptionDiaglog && !this.state.isMemberOfOrganisation ? (
                                    <>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{ paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                            You must be subscribed to use the Basic Membership. You will not be charged for the Entertainer Basic plan.
                                                            </Typography>
                                                            </div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                            
                                                            Plan : { this.state.planName }
                                                            <br />
                                                            Fee: Free
                                                         
                                                            <br />
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <br/>
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => { this.setState({ isRegistrationEnable: true });}}
                                                                    className={classes.button} fullWidth>
                                                                    Subscribe
                                                                </Button>

                                                                <br/>
                                                                <br/>


                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    </>
                            ) : null}

                            {/* { this.state.showUpdatePaymentDialog && !this.state.isMemberOfOrganisation && this.props.profileStore.currentProfileType  === 3 && (
                                 <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                 <Card>
                                     <Grid container spacing={16}>
                                         <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                             <div style={{ float: "left", paddingRight:10}}>
                                             
                                                 <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                     Single Venue Membership Free Plan
                                                 </Typography>
                                             </div>
                                             <div style={{ marginTop: 19}}><Chip style={{ backgroundColor: "green", color: "white"}} label="Active" color="success" /></div>
                                             <div>Fee: Free</div>
                                                 <br />
                                                 <div style={{fontWeight:900,color:"black"}}>Features</div>
                                                 <ul>
                                                     <li>
                                                         <p>Manage your venue(s)</p>
                                                     </li>
                                                     <li>
                                                         <p>Gig Promotions allowing your gigs to appear in the Hot Giggity Live gig guide app</p>
                                                     </li>
                                                     <li>
                                                         <p>Venue Promotions - allowing you to publish venue promotions in the Hot Giggity Live gig guide app</p>
                                                     </li>
                                                 </ul>
                                         </Grid>
                                     </Grid> 
                                 </Card>
                             </Grid>
                            )
                            } */}
                            { this.props.profileStore.currentProfileType  != 3 && this.state.showUpdatePaymentDialog && !this.state.isMemberOfOrganisation ? (
                                    <>
                                    <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                            <Card>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                            <div style={{ paddingRight:15}}>
                                                            <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                             { this.props.profileStore.currentProfileType  === ProfileType.Musician.ordinal ? "Please update the payment method to access features" : "Your subscription is on hold. This is a paid membership. Please update your card details" }
                                                            </Typography>
                                                            </div>
                                                            <br />
                                                            <div style={{fontWeight:900,color:"black"}}>Membership Information </div>
                                                        
                                                            Plan : { this.state.planName }
                                                            <br />
                                                            Fee: AUD${this.state.productPrice}.00 (ex GST)

                                                            <br />
                                                            Free Trial : 14 days
                                                            <br />
                                                            <br />
                                                            <div style={{ marginLeft: -8, marginRight:25}}>
                                                                <br/>
                                                                <Button disabled={false}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleOpenDialog}
                                                                    className={classes.button} fullWidth>
                                                                    Update Payment Method
                                                                </Button>

                                                                <br/>
                                                                <br/>


                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                            </Card>

                                    </Grid>
                                    </>
                            ) : null}

                            { this.state.showUpdatePaymentDialog && this.state.isMemberOfOrganisation ? (
                                     <>
                                     <Grid item xs={12} style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}>
                                             <Card>
                                                     <Grid container spacing={16}>
                                                         <Grid item xs key={16} style={{ paddingLeft: 20 }}>
                                                             <div style={{ paddingRight:15}}>
                                                             <Typography style={{fontWeight:900,color:"black",marginTop:"23px"}} variant="headline" component="h3">
                                                             Your account is no longer active, speak with your system administrator.
                                                            </Typography>
                                                             </div>
                                                             <br />
                                                            
                                                         </Grid>
                                                     </Grid>
                                             </Card>
 
                                     </Grid>
                                     </>
                            ) : null}

                            <div style={{ marginLeft: 8, marginRight: 8, marginTop:20, textAlign: "left" }}> Payment powered by Stripe. Hot Giggity does not store credit card details.</div>
                            
                        </Grid>

                    </Grid>
                    ) : null}

                    <ConfirmationStripeModal
                        customer={this.state.customer}
                        open={this.state.openCreditCardDialog}
                        onSuccess={this.handleSubscribeDialog}
                        onClose={this.handleCloseDialog}
                        fullWidth={true}
                        isRemovable={this.state.isCardExist }
                        planId={this.state.planId}
                        isSubscribe={true}
                        title={this.state.isCardExist ? "Update Credit Card" :"Add Credit Card"}
                        confirmationHandler={this.handleSubscribeDialog}
                        profileType={this.props.profileStore.getCurrentProfile()?.type}
                        declineHandler={this.handleCloseDialog}
                        confirmationLabel={this.state.isCardExist ? "Update Card" :"Add Card"}
                     />
                     <ConfirmationUpgradeModal
                        customer={this.state.customer}
                        open={this.state.openDialogProPlan}
                        onSuccess={this.handleUpgradeProPlan}
                        onClose={this.handleCloseDialogProPlan}
                        fullWidth={true}
                        isRemovable={this.state.isCardExist }
                        planId={this.state.planId}
                        isSubscribe={true}
                        title={this.state.isCardExist ? "Confirm Upgrade to Pro Plan" : "Add Credit Card & Confirm Upgrade to Pro Plan" }
                        confirmationHandler={this.handleUpgradeProPlan}
                        profileType={this.props.profileStore.getCurrentProfile()?.type}
                        declineHandler={this.handleCloseDialogProPlan}
                        confirmationLabel={this.state.isCardExist ? "Upgrade to Pro Plan" :"Add Card & Upgrade to pro plan"}
                     />
                    <ConfirmationModal
                        open={this.state.openConfirmationCancelSubscriptionDialog}
                        title=""
                        message={'Are you sure you would like to cancel your membership?'}
                        closeHandler={this.handleCloseConfirmationUpdateSubscriptionDialog}
                        confirmationHandler={this.handleCancelSubscriptionBySubId}
                        declineHandler={this.handleCloseConfirmationUpdateSubscriptionDialog}
                        confirmationLabel="Ok"
                    />
                     <InfoModal
                        open={this.state.isAddCardSuccess}
                        message={'Your card details have been successfully updated'}
                        closeHandler={this.handleCloseInfoModal}
                        confirmationLabel="Ok"
                    />

                    <InfoModal
                        open={this.state.isUpgradeSucess}
                        title={`Pro Plan Upgrade Confirmed.`}
                        message={'You have successfully upgraded to the Pro Plan and have unlocked our advanced integrations.'}
                        closeHandler={this.handleCloseInfoModalUpgrade}
                        confirmationLabel="Ok"
                    />
                    <InfoPrivateHireModal
                        open={this.state.isPrivateHireFirstOpen}
                        message={'Please note, you will not be charged until your booking has been marked as completed'}
                        closeHandler={this.handleCloseInfoPrivateHireModal}
                        confirmationLabel="Continue"
                    />

                    <ConfirmationModal
                        open={this.state.isRegistrationEnable}
                        title="Membership Confirmation"
                        maxWidth={"sm"}
                        message={'Are you sure you want to subscribe? '}
                        closeHandler={this.closeRegistrationModal}
                        confirmationHandler={this.handleRegistrationMembership}
                        declineHandler={this.closeRegistrationModal}
                        confirmationLabel="Ok"
                    />

<                   ConfirmationModal
                        open={this.state.isRegisterSingleVenueProPlan}
                        title="Pro Plan Membership Confirmation"
                        maxWidth={"sm"}
                        message={'Are you sure you want to subscribe to pro plan? '}
                        closeHandler={this.closeRegisterSingleVenueProPlan}
                        confirmationHandler={this.handleRegisterSingleVenueProPlan}
                        declineHandler={this.closeRegisterSingleVenueProPlan}
                        confirmationLabel="Ok"
                    />
                    <ConfirmationModal
                        open={this.state.openDialogDowngradeProPlan}
                        title="Confirm plan Downgrade to Free Plan"
                        maxWidth={"sm"}
                        message={`Are you sure you would like to downgrade to the Basic Plan? By downgrading you will lose access the Pro Plan features in ${periodEnd} days`}
                        closeHandler={this.closeDialogDowngradeProPlan}
                        confirmationHandler={this.handleDowngradeProPlan}
                        declineHandler={this.closeDialogDowngradeProPlan}
                        confirmationLabel="Ok"
                    />

                    <ConfirmationModal
                        open={this.state.openDialogCancelDowngrade}
                        title="Cancel plan downgrade confirmation"
                        maxWidth={"sm"}
                        message={`By cancelling your downgrade request, your current membership will continue and renew on your next payment cycle.`}
                        closeHandler={this.handleCloseDialogCancelDowngrade}
                        confirmationHandler={this.handleCancelDowngrade}
                        declineHandler={this.handleCloseDialogCancelDowngrade}
                        confirmationLabel="Ok"
                    />


            </Fragment>
        );
    }
}

export default withStyles(styles) (HgBilling);
