import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import commonStore from "./stores/commonStore";
import authStore from "./stores/authStore";
import userStore from "./stores/userStore";
import CacheHelper from "./helper/cache";
import { API } from "aws-amplify";

import templateStore from "./stores/templateStore";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

const encode = encodeURIComponent;

const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        authStore.logout();
    } else if (err && err.response && err.response.status === 403) {
        templateStore.replaceLink("/unauthorized");
    }
    return err;
};

const responseBody = res => res.body;
const responseBodyAmplify = res => res.response;
const responsePlainAmplify = res => res;
const responseText = res => res.text;

const tokenPlugin = req => {
    if (commonStore.token) {
        req.set("authorization", `Bearer ${commonStore.token}`);
    }
}

const urlEncoded = req => {
    req.set("Content-Type", `application/x-www-form-urlencoded`);
}

const customRequest = (body) => {
    var userId = null;
    if (typeof userStore.currentUser === "undefined") {
        userStore.saveUserFromCache(CacheHelper.getUser());
    }
    if (commonStore.token && userStore.currentUser) {
        userId = userStore.currentUser.sub;
    }
    if (body) {
        return {
            headers: {
                "X-Cognito-Identity-Id": `${userId}`,
                
    },
            body
        }
    };
    return {
        headers: {
            "X-Cognito-Identity-Id": `${userId}`,
        },
    }
};

const customRequestLocal = (body) => {
    return {
        headers: {
            "X-Cognito-Identity-Id": `${userId}`,
            "Access-Control-Allow-Origin": "*",
            },
        body
    }
};

var localheaders = function() {
    return ({
      "Access-Control-Allow-Origin": "*",
    });
  };

const requests = {
    del: async url => {
        // await authStore.refreshToken();
        return superagent
            .del(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    delAmplify: async (api, url) => {
        // authStore.refreshToken();
        return await API
            .del(api, `${url}`, customRequest())
            .then(responseBodyAmplify);
    },
    delWithBodyAmplify: async (api, url, body) => {
        // authStore.refreshToken();
        return await API
            .del(api, `${url}`, { body })
            .then(responseBodyAmplify);
    },
    get: async url => {
        // authStore.refreshToken();
        return superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    myobPost: async (body) => {
        // authStore.refreshToken();
        return superagent
            .post(`https://secure.myob.com/oauth2/v1/authorize`, body)
            // .use(urlEncoded)
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .end(handleErrors)
            .then(responseBody);
    },
   
    getLocal: async url => {
        // authStore.refreshToken();
        return superagent
            .get(`http://localhost:3000${url}`)
            .type("json") // DOH. Why we do this????
            .then(responseBody);
    },
    getAmplify: async (api, url) => {
        return await API
            .get(api, `${url}`, customRequest())
            .then(responseBodyAmplify);

        // .end(handleErrors)
    },
    getPlainAmplify: async (api, url) => {
        return await API
            .get(api, `${url}`, customRequest())
            .then(responsePlainAmplify);

        // .end(handleErrors)
    },
    getFile: async url => {
        // authStore.refreshToken();
        return superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseText);
    },
    put: async (url, body) => {
        // authStore.refreshToken();
        return superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    putAmplify: async (api, url, body) => {
        return await API
            .put(api, url, customRequest(body))
            .then(responseBodyAmplify);
    },
    patch: async (url, body) => {
        // authStore.refreshToken();
        return superagent
            .patch(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    anonymousPost: async (url, body) => {
        return superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .then(responseBody);
    },
    post: async (url, body) => {
        // authStore.refreshToken();
        return superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    postLocal: async (url, body) => {
        // authStore.refreshToken();
        return superagent
            .post(`http://localhost:3000${url}`, body)
            .type("json") // DOH. Why we do this????
            .end(handleErrors)
            .then(responseBody);
    },
    putLocal: async (be, url, body) => {
        // authStore.refreshToken();
        return superagent
            .put(`http://localhost:3000${url}`, customRequestLocal(body))
            .type("json") // DOH. Why we do this????
            .end(handleErrors)
            .then(responseBody);
    },
    postAmplify: async (api, url, body) => {
        // authStore.refreshToken();
        return await API
            .post(api, url, customRequest(body))
            .then(responseBodyAmplify);
    },
    customUrlPost: async (url, body) => {
        // authStore.refreshToken();
        return superagent
            .post(`${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody);
    },
    simplePostForSoundcloud: async (url, body) => {
        // we use post in this format to avoid the CORST prefligt request, which is currently blocked by soundcloud for unknown reason
        return superagent
            .post(`${url}`)
            .type("form")
            .send({ url: body.url })
            .send({ format: body.format })
            .then(responseBody);
    },
    upload: async (url, files) => {
        // authStore.refreshToken();
        var req = superagent.post(`${API_ROOT}${url}`).use(tokenPlugin);
        files.forEach(file => {
            req.attach("files", file);
        });

        return req.end(handleErrors).then(responseBody);
    },
    uploadSignature: (url, signature) => {
        var req = superagent.post(`${API_ROOT}${url}`).use(tokenPlugin);
        req.attach("signature", signature, "signature.png");

        return req.end(handleErrors).then(responseBody);
    },
    googlePlace: async (key, placeId) => {
        // authStore.refreshToken();
        return superagent
            .get(`https://places.googleapis.com/v1/places/${placeId}`)
            // .use(urlEncoded)
            .set('Content-Type', 'application/json')
            .set('X-Goog-Api-Key', `${key}`)
            .set('X-Goog-FieldMask', 'id,displayName,photos')
            .end(handleErrors)
            .then(responseBody);
    },


    
};

const Auth = {
    handleSetAgent: (data) => requests.putAmplify("hotgiggity-be", `/profile/toggle/toggleActivateEditVenue`,data),
    current: () => requests.get("/user/me"),
    login: (email, password, source) => requests.anonymousPost("/user/login", { email: email, password: password, source: source }),
    register: (email, firstName, lastName, password, confirmPassword, type, token, source) =>
        requests.postAmplify("hotgiggity-be", source ? `/user/register/${source}` : `/user/register`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            type: type,
            invitationToken: token
        }),
    reregister: (email, invitationCode) =>
        requests.postAmplify("hotgiggity-be", `/user/reregister`, {
            email: email,
            invitationCode: invitationCode
        }),
    getDataMappingUserInit: invitationCode => requests.getAmplify("hotgiggity-be", `/user/${invitationCode}/reregistration`),
    getUser: () => requests.getAmplify("hotgiggity-be", "/user"),
    save: user => requests.putAmplify("hotgiggity-be", "/user", user),
    changePassword: (password, newPassword, confirmPassword) =>
        requests.postAmplify("hotgiggity-be", "/user/changePassword", {
            currentPassword: password,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }),
    forgotPassword: email => requests.anonymousPost("/user/reset", { email: email }),
    createNewPassword: (signature, newPassword, confirmPassword) =>
        requests.post("/user/createnewpassword", {
            password: signature,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }),
    complete: () => requests.postAmplify("hotgiggity-be", "/user/complete"),
    updateIntegration: user => requests.put("/user/integration", user),
    confirmationToken: (email, firstName, lastName, token) =>
        requests.get(
            `/user/confirmationtoken?email=${encode(email)}&firstName=${encode(firstName)}&lastName=${encode(lastName)}&token=${encode(token)}`
        ),
    resendConfirmationToken: email => requests.get(`/user/resendConfirmationToken?email=${encode(email)}`),
    changeUserEmail: data => requests.postAmplify("hotgiggity-be", `/user/changeEmail`, data),
    resendLink: data => requests.postAmplify("hotgiggity-be", `/user/resend`, data),
    setUserFreeOrNotFree: data => requests.postAmplify("hotgiggity-be", `/user/set/freeornot`, data),
    setProPlan: data => requests.postAmplify("hotgiggity-be", `/user/set/isProPlan`, data)
    
};

const Tags = {
    getAll: () => requests.get("/tags")
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = article => Object.assign({}, article, { slug: undefined });

const Articles = {
    all: (page, lim = 10) => requests.get(`/articles?${limit(lim, page)}`),
    byAuthor: (author, page, query) => requests.get(`/articles?author=${encode(author)}&${limit(5, page)}`),
    byTag: (tag, page, lim = 10) => requests.get(`/articles?tag=${encode(tag)}&${limit(lim, page)}`),
    del: slug => requests.del(`/articles/${slug}`),
    favorite: slug => requests.post(`/articles/${slug}/favorite`),
    favoritedBy: (author, page) => requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
    feed: () => requests.get("/articles/feed?limit=10&offset=0"),
    get: slug => requests.get(`/articles/${slug}`),
    unfavorite: slug => requests.del(`/articles/${slug}/favorite`),
    update: article => requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
    create: article => requests.post("/articles", { article })
};

const Comments = {
    create: (slug, comment) => requests.post(`/articles/${slug}/comments`, { comment }),
    delete: (slug, commentId) => requests.del(`/articles/${slug}/comments/${commentId}`),
    forArticle: slug => requests.get(`/articles/${slug}/comments`)
};


const MYOB = {
    connectMyob : (profileId, body) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/connect`,body),
    getListOfAccounts : (profileId) => requests.getAmplify("hotgiggity-be", `/myob/${profileId}/listaccounts`),
    mapAccounts: (profileId,body) => requests.putAmplify("hotgiggity-be", `/myob/${profileId}/mapaccounts`,body),
    getListMYOBMappedCustomers : (profileId) => requests.getAmplify("hotgiggity-be", `/myob/${profileId}/mappedcustomers`),
    getListMYOBMappedSuppliers : (profileId) => requests.getAmplify("hotgiggity-be", `/myob/${profileId}/mappedsuppliers`),
    getMYOBSuppliers : (profileId) => requests.getAmplify("hotgiggity-be", `/myob/${profileId}/contact/suppliers`),
    getMYOBCustomers : (profileId) => requests.getAmplify("hotgiggity-be", `/myob/${profileId}/contact/customers`),
    createMYOBSuppliers :(profileId, supplierID) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/contact/${supplierID}/suppliers`),
    createMYOBCustomers :(profileId, customerId) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/contact/${customerId}/customers`),
    mapExistingMYOBSupplier :(profileId, body) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/map/supplier`,body),
    mapExistingMYOBCustomer :(profileId, body) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/map/customer`,body),
    deleteContactMYOB :(id) => requests.postAmplify("hotgiggity-be", `/myob/contact/delete/${id}`),
    updateContactMYOB: (id,data) => requests.putAmplify("hotgiggity-be", `/myob/${id}/map/update`,data),
    integrateIncomingInvoice: (profileId,data) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/incomingInvoice`,data),
    integrateOutgoingInvoice: (profileId,data) => requests.postAmplify("hotgiggity-be", `/myob/${profileId}/outgoingInvoice`,data),
    disconnect: (profileId) => requests.putAmplify("hotgiggity-be", `/myob/${profileId}/disconnect`),
    getInvoiceMYOBCustomerById : (profileId,invoiceId) => requests.getAmplify("hotgiggity-be", `/myobcustomers/${profileId}/invoice/${invoiceId}`),
    getMappedInvoiceMYOBCustomer : (profileId,invoiceId) => requests.getAmplify("hotgiggity-be", `/myobcustomers/${profileId}/mapped/invoice/${invoiceId}`),
    createInvoiceMYOBCustomer: (profileId,data) => requests.postAmplify("hotgiggity-be", `/myobcustomers/${profileId}`,data),
    createMappedInvoiceMYOBCustomer: (data) => requests.postAmplify("hotgiggity-be", `/myobcustomers/mapped/invoice`,data),
    updateInvoiceMYOBCustomer: (profileId,invoiceId,data) => requests.putAmplify("hotgiggity-be", `/myobcustomers/${profileId}/invoice/${invoiceId}`, data),
    updateMapInvoiceMYOBCustomer: (data) => requests.putAmplify("hotgiggity-be", `/myobcustomers/mapped/invoice`, data),
    deleteMapInvoiceMYOBCustomer: (data) => requests.putAmplify("hotgiggity-be", `/myobcustomers/mapped/delete`, data),
    getCompanyUriList: (profileId) => requests.getAmplify("hotgiggity-be", `/myob/listCompanies/${profileId}`),
    mapCompanyUri: (data) => requests.postAmplify("hotgiggity-be", `/myob/initialMap`,data),
}

const XeroLogs = {
    getXeroLogById: profileId => requests.getAmplify("hotgiggity-be", `/xero/error/logs/${profileId}`),
    markAsResolved: id => requests.putAmplify("hotgiggity-be", `/xero/error/logs/markresolve/${id}`),

  
}
const Profile = {
    checkEmailValid: email => requests.getAmplify("hotgiggity-be", `/check/email/${email}`),
    toggleXeroMarkAsSent: id => requests.putAmplify("hotgiggity-be", `/profile/toggle/xeromarkassent/${id}`),
    getBackgroundImageById: id => requests.getAmplify("hotgiggity-be", `/profile/backgroundimage/${id}`),
    getAllImageById: id => requests.getAmplify("hotgiggity-be", `/profile/allimage/${id}`),
    follow: username => requests.post(`/profiles/${username}/follow`),
    getByUserId: userId => requests.get(`/user/${userId}/profile`),
    loadActiveUsers: (limit, token) => requests.getAmplify("hotgiggity-be", "/user/active?limit=" + limit + "&token=" + token),
    searchActiveUsers: (value) => requests.getAmplify("hotgiggity-be", `/user/active/search/${value}`),
    loadLegacyUsers: (limit, page) => requests.getAmplify("hotgiggity-be", "/user/legacy?limit=" + limit + "&page=" + page),
    searchLegacyUsers: (value, limit, page) => requests.getAmplify("hotgiggity-be", "/user/legacy/search/" + value + "?limit=" + limit + "&page=" + page),
    loadProfiles: () => requests.getAmplify("hotgiggity-be", "/user/me/profile"),
    loadActs: userId => requests.getAmplify("hotgiggity-be", `/user/${userId}/acts`),
    // getByUserIdAndType: (userId, type) => requests.get(`/user/${hotgiggity-be}/profile/${type}`),
    getByUserIdAndType: (userId, type) => requests.getAmplify("hotgiggity-be", `/user/${userId}/profile/${type}`),
    unfollow: username => requests.del(`/profiles/${username}/follow`),
    getUserActListByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/profile/act/list/${profileId}`),
    getUserActListSocialByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/profile/act/list/social/${profileId}`),
    getUserTotalActListByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/profile/act/list/${profileId}/total`),
    getAgentMyActs: profileId => requests.getAmplify("hotgiggity-be", `/profile/${profileId}/myActs`),
    getById: profileId => requests.getAmplify("hotgiggity-be", `/profile/detail/${profileId}`),
    getByIdSimple: profileId => requests.getAmplify("hotgiggity-be", `/profile/simple/${profileId}`),
    
    getProfileAct: () => requests.getAmplify("hotgiggity-be", `/getprofileact`),
    get: profileId => requests.getAmplify("hotgiggity-be", `/profile/${profileId}/data`),
    getPublicProfileByVanityUrl: vanityUrl => requests.getAmplify("hotgiggity-be", `/profile/public/vanity/${vanityUrl}`),
    getPublicProfileById: id => requests.getAmplify("hotgiggity-be", `/profile/public/id/${id}`),
    toggleXeroSyncPDF: id => requests.putAmplify("hotgiggity-be", `/profile/toggle/xerosyncpdf/${id}`),
    setCheckboxInvoice: id => requests.putAmplify("hotgiggity-be", `/profile/toggle/toggleCheckboxInvoice/${id}`),
    toggleXeroSettingApproved: id => requests.putAmplify("hotgiggity-be", `/profile/toggle/xeroapprovedsetting/${id}`),
    delete: profileId => requests.putAmplify("hotgiggity-be", `/profile/${profileId}`),

    createSoloAct: () => requests.postAmplify("hotgiggity-be", `/profile/act/solo`),

    searchMusician: name => requests.getAmplify("hotgiggity-be", "/profile/search/musician/" + name),
    searchConsultant: name => requests.getAmplify("hotgiggity-be", "/profile/search/consultant/" + name),
    searchProduction: name => requests.get("/profile/search/production/" + name),
    searchVenueProfile: name => requests.getAmplify("hotgiggity-be", `/profile/search/venue?query=${name}`),
    search: searchQuery => requests.getAmplify("hotgiggity-be", "/profile/search?" + searchQuery),
    searchAct: name => requests.getAmplify("hotgiggity-be", "/profile/search/act/" + name),
    searchProfile: name => requests.get("/profile/search/all/" + name),
    searchOtherAct: name => requests.get(`/profile/search/otheract/${name}`),
    searchAgent: name => requests.get(`/profile/search/agent/${name}`),
    searchAgency: name => requests.getAmplify("hotgiggity-be", `/profile/search/agency/${name}`),
    searchMemberVenueProfile: name => requests.getAmplify("hotgiggity-be", `/profile/search/member/${name}`),
    checkVanityUrl: (vanityUrl, profileId) => requests.getAmplify("hotgiggity-be", `/profile/vanity/${vanityUrl}/${profileId}`),

    getMusicianDashboard: (id, start, end) => requests.getAmplify("hotgiggity-be", "/profile/musician/dashboard/" + id + "?start=" + start + "&end=" + end),
    getMusicianDashboardMain: (id, start, end) => requests.getAmplify("hotgiggity-be", "/profile/musician/dashboard/main/" + id + "?start=" + start + "&end=" + end),
    getMusicianDashboardRequests: (id, start, end) => requests.getAmplify("hotgiggity-be", "/profile/musician/dashboard/request/" + id + "?start=" + start + "&end=" + end),
    getStaffDashboard: (id, start, end, organisationId, stateId, regionId, venueId) =>
        requests.getAmplify("hotgiggity-be",
            "/profile/staff/dashboard/" +
            id +
            "?start=" +
            start +
            "&end=" +
            end +
            "&organisationId=" +
            organisationId +
            "&stateId=" +
            stateId +
            "&regionId=" +
            regionId +
            "&venueId=" +
            venueId
        ),
    getAgentDashboard: (id, start, end, venueId, consultantId) =>
        requests.getAmplify("hotgiggity-be", "/profile/agent/dashboard/" + id + "?start=" + start + "&end=" + end + "&venueId=" + venueId + "&consultantId=" + consultantId),
        getAgentDashboardMain: (id, start, end, venueId, consultantId) =>
        requests.getAmplify("hotgiggity-be", "/profile/agent/dashboard/main/" + id + "?start=" + start + "&end=" + end + "&venueId=" + venueId + "&consultantId=" + consultantId),
        getAgentBookingRequestDashboard: (id, start, end, venueId, consultantId) =>
        requests.getAmplify("hotgiggity-be", "/profile/agent/dashboard/request/" + id + "?start=" + start + "&end=" + end + "&venueId=" + venueId + "&consultantId=" + consultantId),
        getAgentDashboardVenue: (id, start, end, venueId, consultantId) =>
        requests.getAmplify("hotgiggity-be", "/profile/agent/dashboard/venue/" + id + "?start=" + start + "&end=" + end + "&venueId=" + venueId + "&consultantId=" + consultantId),
    createTermCondition: data => requests.postAmplify("hotgiggity-be", `/profile/termcondition`, data),
    loadTermCondition: id => requests.getAmplify("hotgiggity-be", `/profile/${id}/termcondition`),
    getMyVenues: (page) => requests.getAmplify("hotgiggity-be", `/venue/v2?limit=10&page=${page}`),
    getMyVenuesNoImage: () => requests.getAmplify("hotgiggity-be", `/venue/noimage`),
    getGoogleCalendars: () => requests.get("/user/me/google-calendar"),
    getQuickBooks: () => requests.get("/user/me/quickbooks"),
    getQuickBooksCustomers: (profileId, invoiceId) => requests.get(`/profile/${profileId}/quickbooks/customers?invoiceId=${invoiceId}`),
    getQuickBooksVendors: (profileId, invoiceId) => requests.get(`/profile/${profileId}/quickbooks/vendors?invoiceId=${invoiceId}`),
    getQuickBooksItems: profileId => requests.get(`/profile/${profileId}/quickbooks/items`),
    getQuickBooksTaxCodes: profileId => requests.get(`/profile/${profileId}/quickbooks/taxcodes`),
    getQuickBooksExpenses: profileId => requests.get(`/profile/${profileId}/quickbooks/expenses`),
    getQuickBooksInvoice: (profileId, invoiceId) => requests.get(`/profile/${profileId}/quickbooks/invoice/${invoiceId}`),
    unlinkGoogleCalendars: () => requests.get("/user/me/unlink-google-calendar"),
    canAgentInviteAct: (agentId, actId) => requests.get(`/profile/check/agent/invite/act?agentId=${agentId}&actId=${actId}`),
    canAgentInviteVenue: (agentId, venueId) => requests.get(`/profile/check/agent/invite/venue?agentId=${agentId}&venueId=${venueId}`),
    getSearchAllProfiles:  name => requests.getAmplify("hotgiggity-be", "/profile/searchProfile/all/" + name)
};

const MusicianGeneralProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/musician/general", profile)
};

const Xero = {
    connectXero : (body) => requests.postAmplify("hotgiggity-be", `/xero/exchangeToken`,body),
    getListOfAccounts : (profileId) => requests.getAmplify("hotgiggity-be", `/xero/accounts/${profileId}`),
    mapAccounts: (profileId,body) => requests.postAmplify("hotgiggity-be", `/xero/mapAccount/${profileId}`,body),
    getListXeroMappedContacts : (profileId) => requests.getAmplify("hotgiggity-be", `/xero/${profileId}/mappedcontacts`),
    getXeroContacts : (profileId) => requests.getAmplify("hotgiggity-be", `/xero/contacts/${profileId}`),
    createXeroContacts :(profileId, contactId) => requests.postAmplify("hotgiggity-be", `/xero/${profileId}/contacts/${contactId}`),
    mapExistingXeroContact :(body) => requests.postAmplify("hotgiggity-be", `/xero/createContact`,body),
    deleteContactXero :(id) => requests.putAmplify("hotgiggity-be", `/xero/deleteContact/${id}`),
    updateContactXero: (id,data) => requests.putAmplify("hotgiggity-be", `/xero/contact/${id}/mapped`,data),
    integrateIncomingInvoice: (profileId,data) => requests.postAmplify("hotgiggity-be", `/xero/createBill/${profileId}`,data),
    integrateOutgoingInvoice: (profileId,data) => requests.postAmplify("hotgiggity-be", `/xero/createInvoice/${profileId}`,data),
    disconnect: (profileId) => requests.putAmplify("hotgiggity-be", `/xero/${profileId}/disconnect`),
    getInvoiceXeroContactById : (profileId,invoiceId) => requests.getAmplify("hotgiggity-be", `/Xerocustomers/${profileId}/invoice/${invoiceId}`),
    getMappedInvoiceXeroContact : (profileId,invoiceId) => requests.getAmplify("hotgiggity-be", `/Xerocustomers/${profileId}/mapped/invoice/${invoiceId}`),
    createInvoiceXeroContact: (profileId,data) => requests.postAmplify("hotgiggity-be", `/Xerocustomers/${profileId}`,data),
    createMappedInvoiceXeroContact: (data) => requests.postAmplify("hotgiggity-be", `/Xerocustomers/mapped/invoice`,data),
    updateInvoiceXeroContact: (profileId,invoiceId,data) => requests.putAmplify("hotgiggity-be", `/Xerocustomers/${profileId}/invoice/${invoiceId}`, data),
    updateMapInvoiceXeroContact: (data) => requests.putAmplify("hotgiggity-be", `/Xerocustomers/mapped/invoice`, data),
    deleteMapInvoiceXeroContact: (data) => requests.putAmplify("hotgiggity-be", `/Xerocustomers/mapped/delete`, data),
    getXeroTenantId: (profileId) => requests.getAmplify("hotgiggity-be", `/xero/connection/${profileId}`),
    mapXeroTenant: (data) => requests.postAmplify("hotgiggity-be", `/xero/initialMap`,data),
    deleteXeroInvoice: (profileId,invoiceId) => requests.putAmplify("hotgiggity-be", `/xero/${profileId}/unsyncSendtoAccountXero/${invoiceId}`),

    
}
const MusicianDesignProfile = {
    load: id => requests.getAmplify("hotgiggity-be", `/profile/musician/design/${id}`),
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/design", profile)
};

const MusicianSocialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/social", profile)
};

const MusicianFinancialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/financial", profile)
};

const MusicianInsuranceProfile = {
    load: id => requests.getAmplify("hotgiggity-be", `/profile/musician/insurance/${id}`),
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/insurance/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/insurance", profile),
    uploadDocument: (profileId, acceptedFiles) => requests.postAmplify(`hotgiggity-be`, `/document/insurance/${profileId}`, acceptedFiles),
    downloadDocument: guid => requests.getFile(`/document/${guid}`),
    deleteDocument: guid => requests.delAmplify(`hotgiggity-be`, `/document/${guid}`)
};

const MusicianPrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/musician/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/privacy", profile)
};

const MusicianEmbedProfile = {
    update: profile => requests.put(`/profile/musician/embed/${profile.id}`, profile),
    create: profile => requests.post("/profile/musician/embed", profile)
};

const AgentGeneralProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/agent/general", profile)
};

const AgentDesignProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/design/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/agent/design", profile)
};

const AgentSocialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/social/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/agent/social", profile)
};

const AgentFinancialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/financial/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/agent/financial", profile)
};

const AgentInsuranceProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/insurance/${profile.id}`, profile)
};

const AgentPrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/agent/privacy/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/agent/privacy", profile)
};

const StaffGeneralProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/staff/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/staff/general", profile)
};

const GooglePlace = {
    getPlaceId: (key,placeId) => requests.googlePlace(key,placeId),
    
}


const PrivateHireGeneralProfile = { 
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/privateHire/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/privateHire/general", profile)
};

const StaffDesignProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/staff/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/staff/design", profile)
};

const StaffSocialProfile = {
    update: profile => requests.put(`/profile/staff/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/staff/social", profile)
};

const StaffFinancialProfile = {
    update: profile => requests.put(`/profile/staff/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/staff/financial", profile)
};

const StaffPrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/staff/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/staff/privacy", profile)
};

const VenueGeneralProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/venue/general", profile)
};

const ProductionGeneralProfile = {
    update: profile => requests.put(`/profile/production/general/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/general", profile)
};

const ProductionDesignProfile = {
    update: profile => requests.put(`/profile/production/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/design", profile)
};

const ProductionSocialProfile = {
    update: profile => requests.put(`/profile/production/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/social", profile)
};

const ProductionFinancialProfile = {
    update: profile => requests.put(`/profile/production/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/financial", profile)
};

const ProductionPrivacyProfile = {
    update: profile => requests.put(`/profile/production/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/privacy", profile)
};

const ProductionEmbedProfile = {
    update: profile => requests.put(`/profile/production/embed/${profile.id}`, profile),
    create: profile => requests.post("/profile/production/embed", profile)
};

const DjGeneralProfile = {
    update: profile => requests.put(`/profile/dj/general/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/general", profile)
};

const DjDesignProfile = {
    update: profile => requests.put(`/profile/dj/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/design", profile)
};

const PrivateHireDesignProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/privatehire/design/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/privatehire/design", profile)
};

const DjSocialProfile = {
    update: profile => requests.put(`/profile/dj/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/social", profile)
};

const DjFinancialProfile = {
    update: profile => requests.put(`/profile/dj/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/financial", profile)
};

const DjPrivacyProfile = {
    update: profile => requests.put(`/profile/dj/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/privacy", profile)
};

const DjEmbedProfile = {
    update: profile => requests.put(`/profile/dj/embed/${profile.id}`, profile),
    create: profile => requests.post("/profile/dj/embed", profile)
};

const VenueDesignProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/design", profile)
};

const VenueSocialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/social", profile)
};

const VenueFinancialProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/financial", profile)
};

const VenueMemberProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/member/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/member", profile)
};

const VenuePrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/privacy", profile)
};

const PrivateHirePrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/privatehire/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/venue/privacy", profile)
};



const VenueMenuProfile = {
    get: profileId => requests.getAmplify('hotgiggity-be', `/profile/venue/menu/${profileId}`),
    loadProfile: profile => requests.get(`/profile/venue/menu/${profile.id}`),
    create: profile => requests.putAmplify("hotgiggity-be", `/profile/venue/menu/${profile.id}`, profile)
};

const ActDuplicateProfile = {
    duplicate: actId => requests.postAmplify('hotgiggity-be', `/profile/act/duplicate/${actId}`)
};

const UserFavourite = {
    addUserFavourite: data => requests.postAmplify("hotgiggity-be", "/userFavourite/create", data),
    removeUserFavourite: profileId => requests.delAmplify(`hotgiggity-be`, `/userFavourite/${profileId}/delete`),
    getUserFavouriteActs: () => requests.getAmplify("hotgiggity-be", `/userFavourite/acts`)

}
const ActGeneralProfile = {
    update: profile => requests.putAmplify('hotgiggity-be', `/profile/act/general/${profile.id}`, profile),
    create: profile => requests.postAmplify("hotgiggity-be", "/profile/act/general", profile)
};

const ActDesignProfile = {
    update: profile => requests.putAmplify('hotgiggity-be', `/profile/act/design/${profile.id}`, profile),
    create: profile => requests.post("/profile/act/design", profile)
};

const ActSocialProfile = {
    update: profile => requests.putAmplify('hotgiggity-be', `/profile/act/social/${profile.id}`, profile),
    create: profile => requests.post("/profile/act/social", profile)
};

const ActFinancialProfile = {
    update: profile => requests.put(`/profile/act/financial/${profile.id}`, profile),
    create: profile => requests.post("/profile/act/financial", profile)
};

const ActPrivacyProfile = {
    update: profile => requests.putAmplify("hotgiggity-be", `/profile/act/privacy/${profile.id}`, profile),
    create: profile => requests.post("/profile/act/privacy", profile)
};

const ActRoster = {
    getActRoster: actId => requests.getAmplify("hotgiggity-be", `/actRoster/getList/${actId}`),
    getActProductionRoster: actId => requests.get(`/actRoster/production/${actId}`),
    update: actRoster => requests.postAmplify("hotgiggity-be", "/actRoster/update", actRoster),
    productionUpdate: actRoster => requests.post("/actRoster/production", actRoster),
    getRosterByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/roster/profile/${profileId}`)
};

const Roster = {
    inviteNew: (actId, invite) => requests.postAmplify("hotgiggity-be", `/roster/invite/${actId}`, invite),
    inviteProduction: (actId, invite) => requests.post(`/roster/invite/${actId}/production`, invite),
    acceptDecline: (actId, profileId, accept) => requests.postAmplify("hotgiggity-be", `/roster/acceptDecline/${actId}/${profileId}/${accept}`),
    quit: (actId, profileId) => requests.delAmplify("hotgiggity-be", `/roster/${actId}/quit/${profileId}`)
};

const ProfileRelationship = {
    //AgentActRoster
    getAgentActRequest: () => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/agentAct/agent`),
    getActAgentRequest: () => requests.get(`/profileRelationshipRequest/agentAct/act`),
    getMusicianActAgentRequest: () => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/agentAct/musician`),

    getAgentMusicianRequest: () => requests.get(`/profileRelationshipRequest/agentMusicianRequest`),
    getMusicianAgentRequest: () => requests.get(`/profileRelationshipRequest/musicianAgentRequest`),

    getAgentVenueRequets: () => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/agentVenueRequest`),
    //getVenueAgentRequest: venueId => requests.get(`/profileRelationshipRequest/venueAgentRequest/${venueId}`),
    getVenueAgentRequest: venueId => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/venueAgentRequest/${venueId}`),
    getStaffVenuesAgentRequests: staffId => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/staffAgentRequest/${staffId}`),
    //venue act roster

    getVenueActRoster: venueId => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/venueAct/${venueId}/venue`),
    getStaffActRoster: staffId => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/venueAct/${staffId}/staff`),
    getMusicianActVenueRoster: () => requests.getAmplify("hotgiggity-be", `/profileRelationshipRequest/venueAct/musician`),
    getActVenueRoster: actId => requests.get(`/profileRelationshipRequest/venueAct/${actId}/act`),

    invite2: requestDetail => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/invite/v2`, requestDetail),
    invite: requestDetail => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/invite`, requestDetail),
    inviteMany: requestDetails => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/inviteMany`, requestDetails),
    accept: requestDetail => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/accept`, requestDetail),
    acceptMany: requestDetails => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/acceptMany`, requestDetails),
    decline: requestId => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/decline/${requestId}`),
    declineMany: requestIds => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/declineMany`, requestIds),
    remove: requestId => requests.delAmplify("hotgiggity-be", `/profileRelationshipRequest/remove/${requestId}`),
    removeMany: requestIds => requests.postAmplify("hotgiggity-be", `/profileRelationshipRequest/removeMany`, requestIds)
};

const AgentAct = {
    getAgentActRosterSimple: agentId => requests.getAmplify("hotgiggity-be", `/agentAct/${agentId}/agentActRoster/simple`),
    getAgentActRoster: agentId => requests.getAmplify("hotgiggity-be", `/agentAct/${agentId}/agentActRoster`),
    getAgentActRosterV2: (agentId,limit,page) => requests.getAmplify("hotgiggity-be", `/agentAct/${agentId}/agentActRoster/v2?limit=${limit}&page=${page}`),

    
    getAgentActMyActs: agentId => requests.getAmplify("hotgiggity-be", `/agentAct/${agentId}/myActs`),
    getActAgents: actId => requests.get(`/agentAct/${actId}/actAgents`),
    getMusicianAgents: musicianId => requests.getAmplify("hotgiggity-be", `/agentAct/${musicianId}/musicianAgents`),

    getConsultantActRoster: consultantId => requests.getAmplify("hotgiggity-be", `/agentAct/${consultantId}/consultantActRoster`),

    delete: id => requests.delAmplify("hotgiggity-be", `/agentAct/${id}/delete`),
    deleteMany: ids => requests.postAmplify("hotgiggity-be", `/agentAct/deleteMany`, ids),
    getAgentAvailableActs: (agentId, booking) => requests.postAmplify("hotgiggity-be", `/agentAct/${agentId}/agentAvailableAct`, booking)
};

const AgentMusician = {
    getAgentMusicianRoster: agentId => requests.get(`/agentMusician/getAgentMusicianRoster/${agentId}`),
    getAgentMusicianActRoster: agentId => requests.get(`/agentMusician/getAgentMusicianActRoster/${agentId}`),
    getMusicianAgents: musicianId => requests.get(`/agentMusician/getMusicianAgents/${musicianId}`)
};

const AgentVenues = {
    getById: id => requests.get(`/agentVenue/${id}/getById`),
    //getMyVenuesAgent: venueId => requests.get(`/agentVenue/getMyVenueAgents/${venueId}`),
    getMyVenuesAgent: venueId => requests.getAmplify("hotgiggity-be", `/agentVenue/getMyVenueAgents/${venueId}`),
    getMyVenueRosterSocial: agentId => requests.getAmplify("hotgiggity-be", `/agentVenue/getMyVenueRoster/social/${agentId}/simple`),
    
    getMyVenueRoster: agentId => requests.getAmplify("hotgiggity-be", `/agentVenue/getMyVenueRoster/${agentId}`),
    getMyVenueRosterV2: (agentId,limit,page) => requests.getAmplify("hotgiggity-be", `/agentVenue/getMyVenueRoster/${agentId}/v2?limit=${limit}&page=${page}`),
    getMyVenueRosterSimple: agentId => requests.getAmplify("hotgiggity-be", `/agentVenue/getMyVenueRoster/${agentId}/simple`),
    getStaffVenuesAgent: staffId => requests.getAmplify("hotgiggity-be", `/agentVenue/${staffId}/staff`),
    getConsultantVenueRoster: consultantId => requests.getAmplify("hotgiggity-be", `/agentVenue/${consultantId}/consultant`),
    getConsultantVenueRosterV2: (consultantId, limit, page) => requests.getAmplify("hotgiggity-be", `/agentVenue/${consultantId}/consultant/v2?limit=${limit}&page=${page}`),
    getConsultantVenueRosterSimple: consultantId => requests.getAmplify("hotgiggity-be", `/agentVenue/${consultantId}/consultant/simple`),
    deleteAgentVenue: (id, profileId) => requests.delAmplify("hotgiggity-be", `/agentVenue/${id}/delete/${profileId}`),
    deleteAgentVenues: (ids, profileId) => requests.postAmplify("hotgiggity-be", `/agentVenue/deleteMany/${profileId}`, ids),
    edit: details => requests.putAmplify("hotgiggity-be", `/agentVenue/edit`, details),
};

const VenueAct = {
    getVenueActRoster: venueId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${venueId}/venue`),
    getVenueActRosterSimpleData: venueId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${venueId}/venue/simple`),
    getVenueActRosterWithPagination: (venueId,limit,page) => requests.getAmplify("hotgiggity-be", `/venueActRoster/${venueId}/venue/v2?limit=${limit}&page=${page}`),
    getMusicianActVenue: musicianId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${musicianId}/musician`),
    getStaffActRoster: staffId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${staffId}/rosters`),
    getStaffActRosterSimple: staffId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${staffId}/rosters/simple`),
    getStaffActRosterUnique: staffId => requests.getAmplify("hotgiggity-be", `/venueActRoster/${staffId}/staff`),

    createMany: details => requests.postAmplify("hotgiggity-be", `/venueActRoster/createMany`, details),

    delete: (id, cancelBookings) => requests.delAmplify("hotgiggity-be", `/venueActRoster/${id}/delete/${cancelBookings}`),
    deleteMany: ids => requests.postAmplify("hotgiggity-be", `/venueActRoster/deleteMany`, ids),
    deleteMusician: (id) => requests.postAmplify("hotgiggity-be", `/venueActRoster/${id}/musician/delete`),
    getStaffAvailableActs: (staffId, bookingDetail) => requests.postAmplify("hotgiggity-be", `/venueActRoster/${staffId}/staffAvailableAct`, bookingDetail)
};

const AgentConsultant = {
    getAgentConsultants: () => requests.getAmplify("hotgiggity-be", `/agentConsultant/as/agent`),
    getAgentAgency: () => requests.getAmplify("hotgiggity-be", `/agentConsultant/as/consultant`),
    create: details => requests.postAmplify("hotgiggity-be", `/agentConsultant/create`, details),
    update: details => requests.postAmplify("hotgiggity-be", `/agentConsultant/update`, details),
    updateStatus: (id, status) => requests.putAmplify("hotgiggity-be", `/agentConsultant/update/${id}/status/${status}`),
    updateChangeAgent: (id, agentId, venue) => requests.postAmplify("hotgiggity-be", `/agentConsultant/update/${id}/agent/${agentId}`, venue),
    delete: (id, profileId) => requests.delAmplify("hotgiggity-be", `/agentConsultant/${id}/delete/${profileId}`)
};

const Image = {
    uploadProfilePicture: (profileId, acceptedFiles) => requests.postAmplify("hotgiggity-be", `/image/profile/${profileId}`, acceptedFiles),
    uploadBackgroundPicture: (profileId, acceptedFiles) => requests.postAmplify("hotgiggity-be", `/image/background/${profileId}`, acceptedFiles),
    deleteProfilePicture: imageId => requests.delAmplify("hotgiggity-be", `/image/${imageId}`),
    deleteBackgroundPicture: imageId => requests.delAmplify("hotgiggity-be", `/image/background/${imageId}`)
};

const Instrument = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/instrument/getAll")
};

const Production = {
    getAll: () => requests.get("/production/all")
};

const Genre = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/genre/all")
};

const VenueType = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/venueType/all")
};

const ActType = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/actType/all")
};

const EmbedGig = {
    getByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/embedgig/${profileId}/profile`),
    createEmbedCode: data => requests.postAmplify("hotgiggity-be", `/embedgig/create/${data.profileId}`, data)
};

const GigType = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/gigType/all")
};

const Vibe = {
    getAll: () => requests.getAmplify("hotgiggity-be", "/vibe/all")
};

const SoundCloud = {
    getEmbed: data => requests.simplePostForSoundcloud("https://soundcloud.com/oembed", data)
};

const Song = {
    create: song => requests.post(`/song`, song),
    getSongs: profileId => requests.getAmplify("hotgiggity-be", `/song/profile/${profileId}`),
    addSong: (profileId, songs) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}`, songs),
    undoAddSong: (profileId, songs) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}/undo`, songs),
    deleteSong: (profileId, songs) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}/delete`, songs),
    undoDeleteSong: (profileId, songs) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}/undodelete`, songs),
    executeDeleteSong: (profileId, songs) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}/executedelete`, songs),
    selectTopSong: (profileId, song) => requests.postAmplify("hotgiggity-be", `/song/profile/${profileId}/select`, song),
    getTopSongs: profileId => requests.get(`/song/profile/${profileId}/select`),
    update: song => requests.putAmplify("hotgiggity-be", `/song/media/${song.id}`, song)
};

const PlayedAt = {
    addPlayedAt: (profileId, playedAts) => requests.postAmplify("hotgiggity-be", `/profile/${profileId}/playedat`, playedAts),
    editPlayedAt: (profileId, playedAt) => requests.putAmplify("hotgiggity-be", `/profile/${profileId}/playedat/edit`, playedAt),
    deletePlayedAt: (profileId, playedAts) => requests.delWithBodyAmplify("hotgiggity-be", `/profile/${profileId}/playedat/delete`, playedAts)
};

const PlayedWith = {
    addPlayedWith: (profileId, playedWiths) => requests.postAmplify("hotgiggity-be", `/profile/${profileId}/playedwith`, playedWiths),
    editPlayedWith: (profileId, playedWith) => requests.putAmplify("hotgiggity-be", `/profile/${profileId}/playedwith/edit`, playedWith),
    deletePlayedWith: (profileId, playedWiths) => requests.delWithBodyAmplify("hotgiggity-be", `/profile/${profileId}/playedwith/delete`, playedWiths)
};

const Notification = {
    load: data => requests.getAmplify("hotgiggity-be", "/user/me/notifications"),
    read: notificationId => requests.postAmplify("hotgiggity-be", `/notification/read/${notificationId}`),
    update: notification => requests.postAmplify("hotgiggity-be", `/notification/${notification.id}`, notification),
    delete: notificationIds => requests.delWithBodyAmplify("hotgiggity-be", `/notification/delete`, notificationIds),
    deleteAll: userId => requests.putAmplify("hotgiggity-be", `/notification/delete/all`),
    readAll: userId => requests.putAmplify("hotgiggity-be", `/notification/read/all`)
};

const NotificationSetting = {
    load: () => requests.get("/user/me/notificationsetting"),
    update: notificationSettings => requests.post(`/notificationsetting/${notificationSettings.id}`, notificationSettings)
};

const Organisation = {
    loadOrganisations: () => requests.getAmplify("hotgiggity-be", "/user/me/organisation"),
    loadRegions: () => requests.getAmplify("hotgiggity-be", "/region/user/venue"),
    load: organisationId => requests.getAmplify("hotgiggity-be", `/organisation/${organisationId}`),
    update: organisation => requests.putAmplify("hotgiggity-be", `/organisation/${organisation.id}`, organisation),
    create: organisation => requests.postAmplify("hotgiggity-be", `/organisation`, organisation),
};

const OrganisationMember = {
    loadOrg: organisationId => requests.get(`/organisation/${organisationId}/member`),
    update: organisation => requests.post(`/organisation/${organisation.id}`, organisation),
    create: organisation => requests.post("/organisation", organisation),
    checkEmailMember: email => requests.getAmplify("hotgiggity-be", `/organisation/member/${email}`),
};

const SetList = {
    load: id => requests.getAmplify("hotgiggity-be", `/setlist/${id}`),
    create: setlist => requests.postAmplify("hotgiggity-be", `/setlist`, setlist),
    update: setlist => requests.postAmplify("hotgiggity-be", `/setlist/${setlist.id}`, setlist),
    delete: id => requests.delAmplify("hotgiggity-be", `/setlist/${id}`),
    getByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/setlist/profile/${profileId}`),
    getByBookingId: bookingId => requests.getAmplify("hotgiggity-be", `/booking/${bookingId}/setlists`),
    addSongs: (id, songs) => requests.postAmplify("hotgiggity-be", `/setlist/single/${id}/add`, songs),
    copyFromAct: (bookingId, actId) => requests.postAmplify("hotgiggity-be", `/booking/${bookingId}/copyAct/${actId}`)
};

const State = {
    load: stateId => requests.getAmplify("hotgiggity-be", `/state/${stateId}`),
    update: state => requests.putAmplify("hotgiggity-be", `/state/${state.id}`, state),
    create: state => requests.post("/state", state)
};

const Region = {
    load: id => requests.getAmplify("hotgiggity-be", `/region/${id}`),
    update: region => requests.putAmplify("hotgiggity-be", `/region/${region.id}`, region),
    create: region => requests.post("/region", region)
};

const Booking = {
    sendBookingDetailsToEmail: (data) => requests.postAmplify("hotgiggity-be", `/booking/details/sendEmail`, data),
    getPDFBookingDetails: (bookingId, sendFeeDetails) => requests.getPlainAmplify("hotgiggity-be",`/booking/details/pdf/${bookingId}/${sendFeeDetails}`), 
    loadSocial: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId, page, creatorId) =>
    requests.getAmplify("hotgiggity-be",
        `/booking/${profileId}/socialPost?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=${50}&page=${page}&creatorId=${creatorId}`
    ),
    getAllowBooking: (organisationId, isOrganisation) => requests.getAmplify('hotgiggity-be', `/organisation/profile/allowBooking/${organisationId}/${isOrganisation}`),
    deleteBooking: (bookingId,profileId) => requests.putAmplify("hotgiggity-be", `/booking/delete/${bookingId}/${profileId}`),
    duplicateBooking: (bookingId,profileId) => requests.postAmplify("hotgiggity-be", `/booking/${bookingId}/duplicate/${profileId}`),
    sendReminder: data => requests.postAmplify('hotgiggity-be', `/booking/reminder`, data),
    sendReminderRequest: data => requests.postAmplify('hotgiggity-be', `/booking/reminder/request`, data),
    save: book => requests.postAmplify('hotgiggity-be', `/booking/booking`, book),
    initializeBook: (actId, venueId) => requests.getAmplify('hotgiggity-be', `/booking/booking/?act=${actId}&venue=${venueId}`),
    getBooking: id => requests.getAmplify("hotgiggity-be", `/booking/profile/` + id),
    getBookingRelatedAct: (id, startDate, endDate) => requests.getAmplify("hotgiggity-be", `/booking/${id}/upcoming/relatedact?startDate=${startDate}&endDate=${endDate}`),
    getBookingFilterDate: (id, startDate, endDate,utcOffset) => requests.getAmplify("hotgiggity-be", `/booking/profile/${id}/filter?startDate=${startDate}&endDate=${endDate}&utcOffset=${utcOffset}`),
    getBookingByProfileIds: profileIds =>  requests.getAmplify('hotgiggity-be', `/booking/profiles?profileIds=${profileIds}`),
    getDetail: (id, bookingRequestId, profileId) =>
        requests.getAmplify('hotgiggity-be', `/booking/detail/${id}/${bookingRequestId}/${new Date().getTimezoneOffset()}/?profileId=${profileId}`),
    getDetailCalendar: (id, bookingRequestId, profileId) =>
        requests.getAmplify('hotgiggity-be', `/booking/detail-calendar/${id}/${bookingRequestId}/?profileId=${profileId}`),
    getSummary: id => requests.getAmplify("hotgiggity-be", `/booking/${id}/summary`),
    updateStatus: (profileId, booking) => requests.postAmplify("hotgiggity-be", `/booking/${profileId}/status`, booking),

    loadRequestsGigs: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId, page, creatorId, bookingType) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/requests/V2/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=50&page=${page}&creatorId=${creatorId}&bookingType=${bookingType}`
        ),
    loadUpcomingGigsPublic: (profileId) => requests.getAmplify("hotgiggity-be", `/booking/${profileId}/upcomingsPublic`),
    loadUpcomingsGigs: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId, page, creatorId, bookingType) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/upcomings/V2/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=${50}&page=${page}&creatorId=${creatorId}&bookingType=${bookingType}`
        ),
    loadUpcomingsGigsWithoutAdhoc: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/upcomings/v2/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&excludeAdhoc=true&limit=${100}&page=${1}`
        ),
    loadEntertainmentUpcomingsGigsWithoutAdhoc: (venueId, startDate, endDate) =>
        requests.getAmplify("hotgiggity-be", `/booking/${venueId}/upcomings/entertainment?startDate=${startDate}&endDate=${endDate}&excludeAdhoc=true`),
    loadEntertainmentUpcomingsGigsWithoutAdhocIncludeGenre: (venueId, startDate, endDate) =>
        requests.getAmplify("hotgiggity-be", `/booking/${venueId}/upcomings/entertainment?startDate=${startDate}&endDate=${endDate}&excludeAdhoc=true&includeGenre=true`),
    loadUpcomingGigsNextEvent: (profileId) =>
    requests.getAmplify("hotgiggity-be", `/booking/${profileId}/upcomings/nextEvent`),
    loadUpcomingGigs4NextEvent: (profileId) =>
    requests.getAmplify("hotgiggity-be", `/booking/${profileId}/upcomings/next4Event`),
    loadEntertainmentUpcomingsGigsWithoutAdhocGigGuideEmbed: (profileId, startDate, endDate) =>
        requests.getAmplify("hotgiggity-be", `/booking/${profileId}/upcomings/entertainmentGigGuideEmbed?startDate=${startDate}&endDate=${endDate}&utcOffset=${new Date().getTimezoneOffset()}`),
    loadCompletedGigs: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId, page, creatorId, bookingType) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/completed/V2/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=50&page=${page}&creatorId=${creatorId}&bookingType=${bookingType}`
        ),
    loadAllGigs: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId,page, creatorId, bookingType) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/all/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=50&page=${page}&creatorId=${creatorId}&bookingType=${bookingType}`
        ),
    loadCancelledGigs: (profileId, startDate, endDate, organisationId, stateId, regionId, venueId, actId, page, creatorId, bookingType) =>
        requests.getAmplify("hotgiggity-be",
            `/booking/${profileId}/cancelled/V2/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&actId=${actId}&limit=50&page=${page}&creatorId=${creatorId}&bookingType=${bookingType}`
        ),

    loadLineup: id => requests.getAmplify('hotgiggity-be', `/booking/${id}/lineup`),
    saveLineups: booking => requests.postAmplify('hotgiggity-be', `/booking/${booking.id}/lineup`, booking),
    checkAvailability: booking => requests.postAmplify('hotgiggity-be', `/booking/checkAvailability`, booking),
    cancelBooking: booking => requests.postAmplify("hotgiggity-be", `/booking/cancel`, booking),
    acknowledgeGigs: (profileId, bookingId) => requests.put(`/booking/${profileId}/acknowledge/${bookingId}`),
    unacknowledgeGigs: (profileId, bookingId) => requests.put(`/booking/${profileId}/unacknowledge/${bookingId}`),
    // getCalendarBooking: (profileId, currentProfileId) => requests.getAmplify("hotgiggity-be", `/booking/${profileId}/calendar/${currentProfileId}`),
    getCalendarBooking: (profileId, currentProfileId, startDate, endDate, utcOffset) => requests.getAmplify("hotgiggity-be", `/booking/${profileId}/calendar/${currentProfileId}/filter?startDate=${startDate}&endDate=${endDate}&utcOffset=${utcOffset}`),
    setReminder: id => requests.post(`/booking/${id}/reminder`),
    requestExtraGigFee: (id, extraGigFee) => requests.get(`/booking/${id}/requestExtraGigFee/${extraGigFee}`),
    approveExtraGigFee: (id, profileId) => requests.get(`/booking/${id}/approveExtraGigFee/${profileId}`),
    declineExtraGigFee: (id, profileId) => requests.get(`/booking/${id}/declineExtraGigFee/${profileId}`),

    getBookableActs: (profileId, bookingId, bookingDate, startTime, endTime, actType, actLineupCount,utcOffset) =>
        requests.getAmplify('hotgiggity-be', `/booking/${profileId}/bookable/act/${bookingId}/${bookingDate}/${startTime}/${endTime}/undefined/undefined/${utcOffset}`),
    getHistory: (id, profileId) => requests.getAmplify("hotgiggity-be", `/booking/${id}/history/${profileId}`),
    updateBookingsPurchaseOrder: data => requests.put(`/booking/purchaseOrder`, data),

    getClientEmailPreview: id => requests.getAmplify('hotgiggity-be', `/booking/${id}/clientEmail/preview`),
    sendClientEmail: (id, data) => requests.postAmplify('hotgiggity-be', `/booking/${id}/clientEmail/send`, data),
    saveLineupFee: (lineup) => requests.postAmplify('hotgiggity-be', `/booking/lineup/savefee`, lineup),
    getBookingCount: (profileId) => requests.getAmplify('hotgiggity-be', `/booking/count/${profileId}`), 
    
};

const BookingRequest = {
    create: bookings => requests.postAmplify("hotgiggity-be", `/bookingRequest/create`, bookings),
    accept: bookingReqId => requests.postAmplify("hotgiggity-be", `/bookingRequest/${bookingReqId}/accept`),
    acceptBookingRequest: (bookingReqId, profileId) => requests.postAmplify("hotgiggity-be", `/bookingRequest/${bookingReqId}/${profileId}/accept`),
    decline: bookingReqId => requests.postAmplify("hotgiggity-be", `/bookingRequest/${bookingReqId}/decline`),
    remove: bookingReqId => requests.post(`/bookingRequest/${bookingReqId}/remove`)
};

const Venue = {
    load: data => requests.getAmplify("hotgiggity-be", "/user/me/venues"),
    // loadByRegionId: id => requests.get(`/region/${id}/venues`),
    delete: venueProfileId => requests.del(`/profile/${venueProfileId}`),
    loadBookableVenues: () => requests.getAmplify('hotgiggity-be', `/profile/bookableVenues`),
    getSummaries: ids => requests.getAmplify("hotgiggity-be", `/profile/venue/summary?ids=${ids}`),
    searchVenueAndIndependentVenue: name => requests.getAmplify('hotgiggity-be', `/venue/${name}/search`),
    getUserVenuesBasedOnPermission: (edit, manage, financial, booking, promote) =>
        requests.getAmplify('hotgiggity-be', `/profile/venue/permission?edit=${edit}&manage=${manage}&financial=${financial}&booking=${booking}&promote=${promote}`),
    getUserVenuesBasedOnPermissionSimple: (edit, manage, financial, booking, promote) =>
        requests.getAmplify('hotgiggity-be', `/profile/venue/permission/simple?edit=${edit}&manage=${manage}&financial=${financial}&booking=${booking}&promote=${promote}`)
};

const Promotion = {
    savePromotion: promotion => requests.postAmplify('hotgiggity-be', `/promotion/save`, promotion),
    uploadPromotionImage: (promotionId, images) => requests.postAmplify('hotgiggity-be', `/promotion/${promotionId}/image`, images),
    getPending: () => requests.getAmplify('hotgiggity-be', `/promotion/organisation/pending`),
    getPublished: () => requests.getAmplify('hotgiggity-be', `/promotion/organisation/published`),
    getCompleted: () => requests.getAmplify('hotgiggity-be', `/promotion/organisation/completed`),
    getById: promotionId => requests.getAmplify('hotgiggity-be', `/promotion/id/${promotionId}`),
    removePromotion: promotionId => requests.delAmplify('hotgiggity-be', `/promotion/${promotionId}/delete`)
};

const Event = {
    getByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/event/profile/${profileId}`),
    getByProfileIds: profileIds => requests.getAmplify("hotgiggity-be", `/event/profiles/?profileIds=${profileIds}`),
    get: eventId => requests.getAmplify("hotgiggity-be", `/event/${eventId}`),
    create: event => requests.postAmplify("hotgiggity-be", "/event/create", event),
    update: event => requests.postAmplify("hotgiggity-be", `/event/update/${event.id}`, event),
    delete: eventId => requests.delAmplify("hotgiggity-be", `/event/delete/${eventId}`)
};

const NegotiateMessage = {
    deleteNegotiateMessage : id => requests.put(`/booking/messages/delete/${id}`),
    loadByBookingId: id => requests.getAmplify("hotgiggity-be", `/booking/${id}/messages`),
    update: negotiateMessage => requests.post(`/negotiateMessage/${negotiateMessage.id}`, negotiateMessage),
    create: negotiateMessage => requests.postAmplify("hotgiggity-be", "/negotiateMessage", negotiateMessage)
};

const Lineup = {
    get: id => requests.get(`/lineup/${id}`),
    updateStatus: lineup => requests.postAmplify('hotgiggity-be', `/lineup/${lineup.id}/status`, lineup),
    setReminder: lineup => requests.post(`/lineup/${lineup.id}/reminder`, lineup)
};

const SocialPost = {
    get: (bookingId, profileId) => requests.getAmplify('hotgiggity-be', `/social/${bookingId}/post/${profileId}`),
    create: data => requests.postAmplify('hotgiggity-be', `/social/post`, data),
    postToFacebook: (id) => requests.postAmplify('hotgiggity-be', `/social/postToFacebook/${id}`),
    cancel: (id) => requests.postAmplify('hotgiggity-be', `/social/post/cancel/${id}`),
    postToInstagram: (id) => requests.postAmplify('hotgiggity-be', `/social/postToInstagram/${id}`),
    postImage: data => requests.postAmplify('hotgiggity-be', `/social/postImage`, data)
}

const GeneralSocialPost = {
    getByProfileId: (profileId,startDate, endDate,venueId,page) => requests.getAmplify('hotgiggity-be', `/social/originalPost/creator/${profileId}?startDate=${startDate}&endDate=${endDate}&venueId=${venueId}&limit=${10}&page=${page}`),
    get: (id) => requests.getAmplify('hotgiggity-be', `/social/originalPost/${id}`),
    create: data => requests.postAmplify('hotgiggity-be', `/social/originalPost`, data),
    postToFacebook: (id) => requests.postAmplify('hotgiggity-be', `/general/social/postToFacebook/${id}`),
    cancel: (id) => requests.postAmplify('hotgiggity-be', `/social/originalPost/cancel/${id}`),
    postToInstagram: (id) => requests.postAmplify('hotgiggity-be', `/general/social/postToInstagram/${id}`),
    postImage: data => requests.postAmplify('hotgiggity-be', `/social/original/postImage`, data)
}

// const Facebook = {
//     login: token => requests.get(`/auth/facebook/callback/${token}`),
//     setPage: data => requests.post(`/profile/facebook`, data),
//     disconnectPage: data => requests.put(`/profile/facebook`, data),
//     getPages: (id, token) => requests.get(`/profile/facebook/${id}/pages/${token}`),
//     postToFacebook: (id, booking) => requests.post(`/booking/${id}/facebook`, booking),
//     playerPostToFacebook: (id, booking) => requests.post(`/booking/player/${id}/facebook`, booking)
// };



const Facebook = {
    login: token => requests.get(`/auth/facebook/callback/${token}`),
    setPage: data => requests.postAmplify("hotgiggity-be", `/profile-facebook`, data),
    refreshPage: data => requests.postAmplify("hotgiggity-be", `/profile-instagram-refresh`, data),
    setInstagramPage: data => requests.postAmplify("hotgiggity-be", `/profile-facebook-instagram`, data),
    disconnectPage: data => requests.putAmplify("hotgiggity-be", `/profile-facebook`, data),
    getPages: (id, token) => requests.getAmplify("hotgiggity-be", `/profile-facebook/${id}/pages/${token}`),
    postToFacebook: (id, booking) => requests.postAmplify("hotgiggity-be", `/booking/${id}/facebook`, booking),
    playerPostToFacebook: (id, booking) => requests.postAmplify("hotgiggity-be", `/booking/player/${id}/facebook`, booking)
}

const LineupMessage = {
    getBookingLineupMessage: bookingId => requests.getAmplify('hotgiggity-be', `/lineupMessage/${bookingId}/messages`),
    postBookingLineupMessage: messages => requests.postAmplify('hotgiggity-be', `/lineupMessage/create`, messages),
    deleteLineupMessage: id => requests.putAmplify('hotgiggity-be', `/lineupMessage/${id}/delete`)
};

const Conversation = {
    create: message => requests.postAmplify("hotgiggity-be", `/conversation/new/message`, message),
    createContactActConversation: message => requests.postAmplify("hotgiggity-be", `/conversation/publicnew`, message),
    createMessage: message => requests.postAmplify("hotgiggity-be", `/conversation/message`, message),
    loadByProfileId: profileId => requests.getAmplify("hotgiggity-be", `/profile/${profileId}/conversation`, profileId),
    loadById: id => requests.getAmplify("hotgiggity-be", `/conversation/${id}`, id),
    addParticipant: (participantId, participantData) => requests.postAmplify("hotgiggity-be", `/conversationParticipant/addParticipant/by/${participantId}`, participantData),
    deleteMessage: (messageId) => requests.putAmplify("hotgiggity-be", `/message/delete/${messageId}`),
    removeParticipant: (participantRemove, conversationId, participantCreate) => requests.putAmplify("hotgiggity-be", `/conversationParticipant/remove/${participantRemove}/from/${conversationId}/by/${participantCreate}`),
    leaveConversation: (conversationId, participantId) => requests.putAmplify("hotgiggity-be", `/conversation/${conversationId}/leaveConversation/${participantId}`),
    updateConversationRead: (profileId, conversationId) => requests.putAmplify("hotgiggity-be", `/message/${profileId}/conversation/${conversationId}`),
    updateSubjectConversation: (conversationId, newSubject, participantId) => requests.putAmplify("hotgiggity-be", `/conversation/${conversationId}/changeSubject/${newSubject}/by/${participantId}`)
};

const Invoice = {
    getBookingCreatorByInvoiceId: id => requests.getAmplify("hotgiggity-be", `/invoice/${id}/getBookingOwner`),
    get: id => requests.getAmplify("hotgiggity-be", `/invoice/${id}/detail`),
    getProfileId: (id, profileId) => requests.getAmplify("hotgiggity-be", `/invoice/${id}/detail/${profileId}`),
    getPdf: id => requests.getAmplify("hotgiggity-be", `/invoice/${id}/pdf`),
    getAll: () => requests.getAmplify("hotgiggity-be", `/invoice`),
    getByPayeeId: payeeId => requests.getAmplify("hotgiggity-be", `/invoice/payee/${payeeId}`),
    getByPayeeIds: payeeIds => requests.getAmplify("hotgiggity-be", `/invoice/payees?payeeIds=${payeeIds}`),
    getByPayerId: payerId => requests.getAmplify("hotgiggity-be", `/invoice/payer/${payerId}`),
    getByPayerIds: payerIds => requests.getAmplify("hotgiggity-be", `/invoice/payers?payerIds=${payerIds}`),
    getInvoiceByBookingAndProfileId: (bookingId,profileId) => requests.getAmplify("hotgiggity-be", `/invoice/booking/${bookingId}/profile/${profileId}`), 
    getByPayeeIdAndFilter: (payeeId, startDate, endDate, organisationId, stateId, regionId, venueId, statusFilter, actId) =>
        requests.getAmplify("hotgiggity-be", `/invoice/payee/${payeeId}/filter/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&status=${statusFilter}&actId=${actId}`),

    getByPayerIdAndFilter: (payerId, startDate, endDate, organisationId, stateId, regionId, venueId, statusFilter, actId) =>
        requests.getAmplify("hotgiggity-be", `/invoice/payer/${payerId}/filter/?startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&status=${statusFilter}&actId=${actId}`),

    generate: payeeId => requests.getAmplify("hotgiggity-be", `/invoice/generate/${payeeId}`),
    syncQuickBooks: (invoiceId, data) => requests.post(`/invoice/${invoiceId}/quickbooks`, data),
    createQuickBooksCustomer: (profileId, invoiceId, data) =>
        requests.post(`/profile/${profileId}/quickbooks/customers?invoiceId=${invoiceId}`, data),
    downloadPdf: id => requests.getPlainAmplify("hotgiggity-be", `/invoice/${id}/download/pdf`),
    payInvoice: (invoiceId, data) => requests.postAmplify('hotgiggity-be', `/invoice/pay/${invoiceId}`, data),
    updatePaidDate: (invoiceId, data) => requests.postAmplify('hotgiggity-be', `/invoice/updatePaidDate/${invoiceId}`, data),
    unPayInvoice: (invoiceId, data) => requests.postAmplify('hotgiggity-be', `/invoice/unpay/${invoiceId}`, data),
    declineInvoice: invoiceId => requests.get(`/invoice/decline/${invoiceId}`),
    generateReport: (profileType, startDate, endDate, organisationId, stateId, regionId, venueId, statusFilter) =>
        requests.get(
            `/invoice/reports?profileType=${profileType}&startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}&stateId=${stateId}&regionId=${regionId}&venueId=${venueId}&status=${statusFilter}`
        ),
    downloadBatchPdf: ids => requests.getPlainAmplify("hotgiggity-be", `/invoice/download/batch/pdf?invoiceIds=${ids}`),
    updateInvoicesPurchaseOrder: data => requests.putAmplify("hotgiggity-be", `/invoice/purchaseOrder`,data),
    getBookingByInvoiceId: invoiceId => requests.getAmplify("hotgiggity-be", `/invoice/${invoiceId}/booking`),
    unsyncSendToAccount: invoiceId => requests.postAmplify("hotgiggity-be", `/invoice/${invoiceId}/unsyncSendtoaccount`),
    getByIdSimple: invoiceId => requests.getAmplify("hotgiggity-be", `/invoice/${invoiceId}/detail/simple`), 
};

const Signature = {
    load: id => requests.get(`/signature/${id}`),
    loadByProfileId: id => requests.getAmplify("hotgiggity-be", `/profile/staff/signature/${id}`),
    loadByBookingId: (bookingId, profileId) => requests.getAmplify("hotgiggity-be", `/booking/${bookingId}/signature/${profileId}}`),
    create: (profileId, signature) => requests.postAmplify("hotgiggity-be", `/signature`, signature),
    update: signature => requests.postAmplify("hotgiggity-be", `/signature/${signature.id}`, signature),
    delete: id => requests.del(`/signature/${id}`),
    updateSignatureProfile: (profileId, signature) => requests.postAmplify("hotgiggity-be", `/signatureProfile/${profileId}`, signature),
};

const UserInvitation = {
    create: invitation => requests.postAmplify("hotgiggity-be", `/userInvitation/create`, invitation),
    getByInvitationToken: token => requests.get(`/userInvitation/${token}/token`),
    getMyInvitation: (profileId, type) => requests.getAmplify("hotgiggity-be", `/userInvitation/${profileId}/invitation/${type}`),
    delete: invitation => requests.postAmplify("hotgiggity-be", `/userInvitation/delete`, invitation),
    deleteMany: invitations => requests.postAmplify("hotgiggity-be", `/userInvitation/deleteMany`, invitations),
    getUserInvitationByUserEmailAndType: (userEmail, type) => requests.getAmplify("hotgiggity-be", `/userInvitation/${userEmail}/byEmail/${type}`),
    getByAgentIdPlaceId: (agentId, placeId ) => requests.getAmplify("hotgiggity-be", `/venue/${agentId}/external/${placeId}`),
    accept: invitationId => requests.putAmplify("hotgiggity-be", `/userInvitation/${invitationId}/accept`),
    decline: invitationId => requests.putAmplify("hotgiggity-be", `/userInvitation/${invitationId}/decline`),
    resendLink: (invitationId,profileId) => requests.postAmplify("hotgiggity-be", `/resend/${profileId}/invitation/${invitationId}`),
    
}

const hgBilling = {
    getProfileAccess: (profileId) =>  requests.getAmplify("hotgiggity-be", `/hgBilling/getProfileStatus/${profileId}`),
    cancelSubscription: (type) => requests.postAmplify("hotgiggity-be", `/hgBilling/subscription/cancel/${type}`),
    getCustomerAccess: (type) => requests.getAmplify("hotgiggity-be", `/hgBilling/getCustomer/${type}`),
    postCheckout: (data, profileId) => requests.postAmplify("hotgiggity-be", `/hgBilling/checkout/${profileId}`, data),
    updateSubscriptionPostCheckout : (data) => requests.postAmplify("hotgiggity-be", `/hgBilling/updateSubscription`, data),
    addPaymentMethod: () => requests.postAmplify("hotgiggity-be", `/hgBilling/creditCard`),
    getPaymentMethod: () => requests.getAmplify("hotgiggity-be", `/hgBilling/creditCard`),
    deletePaymentMethod: (data) => requests.postAmplify("hotgiggity-be", `/hgBilling/creditCard/delete`,data),
    setAsDefaultPaymentMethod: (data) => requests.postAmplify("hotgiggity-be", `/hgBilling/paymentMethod/setdefault`,data),
}
const Billing = {
    upgradeProPlan: (profileType, data) => requests.postAmplify("hotgiggity-be", `/billing/customers/${profileType}/upgradeSubscription`, data),
    downgradeProPlan: (profileType, data) => requests.postAmplify("hotgiggity-be", `/billing/customers/${profileType}/downgradeSubscription`, data),
    cancelDowngradeProPlan: (profileType) => requests.postAmplify("hotgiggity-be", `/billing/customers/${profileType}/cancelDowngrade`),
    getProducts: () => requests.get(`/billing/products/`),
    getBillingPlans: () => requests.get(`/billing/plans/`),
    getCustomer: customerId => requests.get(`/billing/customers/${customerId}`),
    getCustomerByUserId: userId => requests.get(`/billing/customers/users/${userId}`),
    createCustomer: customer => requests.postAmplify("hotgiggity-be", `/billing/customers`, customer),
    addCard: (customerId, card) => requests.post(`/billing/customers/${customerId}/cards/`, {customerId, card}),
    removeCard: (customerId,profileType) =>  requests.postAmplify("hotgiggity-be", `/billing/customers/${customerId}/cards/remove`, {customerId,profileType}),
    getCardByCustomerId: customerId => requests.get(`/billing/customers/${customerId}/cards/`),
    createSubscription: (customerId, planId) => requests.get(`/billing/customers/${customerId}/subscription/${planId}`),
    getSubscriptionByCustomerId: customerId => requests.get(`/billing/customers/${customerId}/subscription/`),
    getChargesByCustomerId: customerId => requests.get(`/billing/customers/${customerId}/charges/`),
    getInvoicesByCustomerId: customerId => requests.get(`/billing/customers/${customerId}/invoices/`),
    downloadPdf: (customerId, invoiceId) => requests.getFile(`/billing/customers/${customerId}/invoices/${invoiceId}/pdf`),
    getBillingInformation: customerId => requests.get(`/billing/customers/${customerId}/billinginfo/`),
    updateSubscriptionInEndPeriodByCustomerId: (customerId, data) => requests.patch(`/billing/customers/${customerId}/subscriptions/`, data),
    cancelMembershipBySubscriptionId: (subscriptionId) => requests.post(`/billing/subscriptions/${subscriptionId}/cancel`, null),
    getPaymentAccesses: userId => requests.getAmplify("hotgiggity-be", `/billing/customers/users/${userId}/paymentAccesses/`),
    updateCustomerEmail: (data) =>  requests.postAmplify("hotgiggity-be", `/billing/update/email`, data),
    billingAccess: (sub, type) => requests.get(`/billing/customers/users/${sub}/access/${type}`),
    subscribeSingleVenueProPlan: (profileId) => requests.postAmplify("hotgiggity-be", `/billing/customers/upgrade/singleVenuePro`, {profileId: profileId})
};

const ProfileViews = {
    insertProfileViews: profileId => requests.postAmplify("hotgiggity-be", `/profileviews/insert/${profileId}`),
}

const GoogleCalendar = {
    // exchangeTokenAndGetGoogleCalendarList : 
    exchangeTokenAndGetGoogleCalendarList: data => requests.postAmplify("hotgiggity-be", `/googlecalendar/token`, data),
    selectCalendar: data => requests.postAmplify("hotgiggity-be", '/googlecalendar/selectcalendar', data),
    disconnectGoogle: data => requests.postAmplify("hotgiggity-be", '/googlecalendar/disconnect', data),
    getProfileGoogleCalendar: (profileId, startDate, endDate,utcOffset) => requests.getAmplify("hotgiggity-be", `/googlecalendar/getListEvent?profileId=${profileId}&startDate=${startDate}&endDate=${endDate}&utcOfset=${utcOffset}`)
} 

const InstagramIntegration = {
    // exchangeTokenAndGetGoogleCalendarList : 
    exchangeToken: data => requests.postAmplify("hotgiggity-be", `/instagram/integration/token`, data),
    disconnectInstagram: data => requests.postAmplify("hotgiggity-be", '/instagram/integration/disconnect', data),

}   
const GigPromotion = {
    saveGigPromotion: gigPromotion => requests.postAmplify("hotgiggity-be", `/promotion/gig/save`, gigPromotion),
    getGigPromotionImage: id => requests.get(`/promotion/gig/image/${id}`),
    uploadGigPromotionImage: (gigPromotionId, images) => requests.postAmplify("hotgiggity-be", `/promotion/gig/${gigPromotionId}/image`, images),
    uploadGigPromotionActImage: (gigPromotionId, images) => requests.postAmplify("hotgiggity-be", `/promotion/gig/${gigPromotionId}/act/image`, images),
    deleteGigPromotionImage: id => requests.delAmplify("hotgiggity-be", `/promotion/gig/image/${id}`),
    getPending: () => requests.getAmplify("hotgiggity-be", `/promotion/gig/pending`),
    getPublished: () => requests.getAmplify("hotgiggity-be", `/promotion/gig/published`),
    getCompleted: () => requests.getAmplify("hotgiggity-be", `/promotion/gig/completed`),
    getById: gigPromotionId => requests.getAmplify('hotgiggity-be', `/promotion/gig/id/${gigPromotionId}`),
    removeGigPromotion: gigPromotionId => requests.delAmplify("hotgiggity-be", `/promotion/${gigPromotionId}/gig/delete`)
};

const UserFestivals = {
    getUserFestivalForAct: actId => requests.getAmplify("hotgiggity-be", `/festival/act/${actId}`),
    registerActToFestivals: (actId, festivalId, venueId) => requests.post(`/festival/${festivalId}/register/${actId}/at/${venueId}`),
    registerToAgentFestival: (actId, agentId, venueId) => requests.post(`/festival/agent/${agentId}/register/${actId}/at/${venueId}`)
};

const VenueExternalInvitation = {
    create: (data) => requests.postAmplify("hotgiggity-be", `/venue/external/invite`, data),
    
}

const ReferralProfile = {
    create: (referralCode, userId) => requests.postAmplify("hotgiggity-be", `/profileReferralMapping/${referralCode}/${userId}`),
    
}
export default {
    Articles,
    Auth,
    Comments,
    Profile,
    Tags,
    MusicianGeneralProfile,
    MusicianDesignProfile,
    MusicianSocialProfile,
    MusicianFinancialProfile,
    MusicianInsuranceProfile,
    MusicianPrivacyProfile,
    MusicianEmbedProfile,
    AgentGeneralProfile,
    AgentDesignProfile,
    AgentSocialProfile,
    AgentInsuranceProfile,
    AgentFinancialProfile,
    AgentPrivacyProfile,
    StaffGeneralProfile,
    PrivateHireGeneralProfile,
    StaffDesignProfile,
    StaffSocialProfile,
    StaffFinancialProfile,
    StaffPrivacyProfile,
    VenueGeneralProfile,
    VenueDesignProfile,
    VenueSocialProfile,
    VenueFinancialProfile,
    VenueMemberProfile,
    VenuePrivacyProfile,
    VenueMenuProfile,
    ProductionGeneralProfile,
    ProductionDesignProfile,
    ProductionSocialProfile,
    ProductionFinancialProfile,
    ProductionPrivacyProfile,
    ProductionEmbedProfile,
    DjGeneralProfile,
    DjDesignProfile,
    DjSocialProfile,
    DjFinancialProfile,
    DjPrivacyProfile,
    DjEmbedProfile,
    ActDuplicateProfile,
    ActGeneralProfile,
    ActDesignProfile,
    ActSocialProfile,
    ActFinancialProfile,
    ActPrivacyProfile,
    Roster,
    Image,
    Instrument,
    Production,
    Genre,
    ActType,
    GigType,
    ActRoster,
    SoundCloud,
    Song,
    PlayedAt,
    PlayedWith,
    Notification,
    NotificationSetting,
    ProfileRelationship,
    Organisation,
    SetList,
    OrganisationMember,
    State,
    Region,
    Venue,
    Booking,
    AgentVenues,
    Event,
    AgentMusician,
    AgentAct,
    NegotiateMessage,
    VenueAct,
    Lineup,
    BookingRequest,
    AgentConsultant,
    Facebook,
    LineupMessage,
    Invoice,
    EmbedGig,
    UserInvitation,
    Billing,
    VenueType,
    Signature,
    Promotion,
    GigPromotion,
    UserFestivals,
    Conversation,
    Vibe,
    PrivateHireDesignProfile,
    PrivateHirePrivacyProfile,
    UserFavourite,
    MYOB,
    GoogleCalendar,
    ProfileViews,
    Xero,
    XeroLogs,
    SocialPost,
    InstagramIntegration,
    GooglePlace,
    VenueExternalInvitation,
    GeneralSocialPost,
    hgBilling,
    ReferralProfile
};
