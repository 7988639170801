import React, { Component, Fragment } from "react";
import { inject } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Heading from "../../components/Heading";

import { Grid, Card, Table, TableBody, TableCell, TableHead, TableRow, Button } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import Loading from "../../components/loadingSpinner/Loading";
import moment from "moment";
import axios from 'axios';
import { ProfileType } from "../../types/enum";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    head: {
        backgroundColor: theme.palette.background.default,
        width: 1000
    }
});

   

@inject("billingStore", "userStore","profileStore")
class PaymentHistory extends Component {
    state = {
        invoices: null,
        pdfFile: undefined,
        rows: []
    };

    componentDidMount() {
        this.props.handleLoading(true)
        this.handleLoadData();
    }

    async handleLoadData() {
        let customer = await this.props.billingStore.getCustomerByUserId(this.props.userStore.currentUser.sub);
        const { classes } = this.props;
        let rows = [];
        let invoices = null;
        if(customer == null){
            rows.push(
                <TableRow key={0}>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell> -</TableCell>
                    <TableCell> -</TableCell>
                </TableRow>
            );
            this.props.handleLoading(false)
            this.setState({ isLoading: false,  rows: rows });
        }else{
            let currentProfile = this.props.profileStore.getCurrentProfile();
            // if(currentProfile.type === ProfileType.PrivateHire.ordinal){
            //     let charges = await this.props.billingStore.getChargesByCustomerId(customer.id);
            //     if (charges === null) {
            //         this.props.handleLoading(false)
            //         return null;
            //     }
            //     for (let i = 0; i < charges.length; i++) {
            //         let charge = charges[i];
            //         let day = moment.unix(charge.created);
            //         let chargePdfUrl = charge.receipt_url;
            //         let nickname = charge.description;
    
            //         rows.push(
            //             <TableRow key={i + 1}>
            //                 <TableCell>{i + 1}</TableCell>
            //                 <TableCell>{nickname}</TableCell>
            //                 <TableCell>{day.local().format("DD/MM/YYYY")}</TableCell>
            //                 <TableCell>{day.local().format("HH:mm:ss")}</TableCell>
            //                 <TableCell> A$ {charge.amount / 100}.00</TableCell>
            //                 <TableCell>
            //                     <Button
            //                         variant="contained"
            //                         color="primary"
            //                         className={classes.button}
            //                         target="_blank"
            //                         href={chargePdfUrl}
            //                         download={`Receipt.pdf`}
            //                     >
            //                         Download
            //                     </Button>
            //                 </TableCell>
            //             </TableRow>
            //         );
                    
            //     }
            //     this.setState({
            //         rows: rows
            //     });
            //     this.props.handleLoading(false)
            // }else{
            //     let invoices = await this.props.billingStore.getInvoicesByCustomerId(customer.id);
            //     if (invoices === null) {
                 
            //         this.props.handleLoading(false)
            //         return null;
            //     }
    
            //     for (let i = 0; i < invoices.length; i++) {
            //         let invoice = invoices[i];
            //         let day = moment.unix(invoices[i].created);
            //         let lines =invoice.lines.data;
            //         let line = lines[0];
            //         let invoicePdfUrl = invoice.invoice_pdf;
            //         let nickname = line.description;
    
            //         rows.push(
            //             <TableRow key={i + 1}>
            //                 <TableCell>{i + 1}</TableCell>
            //                 <TableCell>{nickname}</TableCell>
            //                 <TableCell>{day.local().format("DD/MM/YYYY")}</TableCell>
            //                 <TableCell>{day.local().format("HH:mm:ss")}</TableCell>
            //                 <TableCell> A$ {invoice.amount_paid / 100}.00</TableCell>
            //                 <TableCell>
            //                     <Button
            //                         variant="contained"
            //                         color="primary"
            //                         className={classes.button}
            //                         target="_blank"
            //                         href={invoicePdfUrl}
            //                         download={`Receipt.pdf`}
            //                     >
            //                         Download
            //                     </Button>
            //                 </TableCell>
            //             </TableRow>
            //         );
                    
            //     }
            //     this.setState({
            //         invoices: invoices,
            //         rows: rows
            //     });
            //     this.props.handleLoading(false)
            // }
            let invoices = await this.props.billingStore.getInvoicesByCustomerId(customer.id);
            if (invoices === null) {
             
                this.props.handleLoading(false)
                return null;
            }

            for (let i = 0; i < invoices.length; i++) {
                let invoice = invoices[i];
                let day = moment.unix(invoices[i].created);
                let lines =invoice.lines.data;
                let line = lines[0];
                let invoicePdfUrl = invoice.invoice_pdf;
                let nickname = line.description;

                rows.push(
                    <TableRow key={i + 1}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{nickname}</TableCell>
                        <TableCell>{day.local().format("DD/MM/YYYY")}</TableCell>
                        {/* <TableCell>{day.local().format("HH:mm:ss")}</TableCell> */}
                        <TableCell> A$ {invoice.amount_paid / 100}</TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                target="_blank"
                                href={invoicePdfUrl}
                                download={`Receipt.pdf`}
                            >
                                Download
                            </Button>
                        </TableCell>
                    </TableRow>
                );
                
            }
            this.setState({
                invoices: invoices,
                rows: rows
            });
            this.props.handleLoading(false)
        }

        
    }

    handleGenerateRow = () => {
        if (this.state.rows.length > 0) return this.state.rows;
        return null;
    };

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid   item xs={12} justify="center" style={{ marginLeft: 8, marginRight: 8, marginTop:20}}>
                            <Card>
                                <Table>
                                    <TableHead className={classes.head}>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Date</TableCell>
                                            {/* <TableCell>Time</TableCell> */}
                                            <TableCell>Amount Paid</TableCell>
                                            <TableCell>Invoice</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{this.handleGenerateRow()}</TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        </Grid>
                    </Grid>
                   
            </Fragment>
        );
    }
}

export default withStyles(styles)(PaymentHistory);
