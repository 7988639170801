import { NotificationType, ProfileType, BookingType } from "../../../types/enum";
import profileStore from "../../../stores/profileStore";
import generalProfileStore from "../../../stores/generalProfileStore"
import agentConsultantStore from "../../../stores/agentConsultantStore"
import billingStore from "../../../stores/billingStore"
import conversationStore from "../../../stores/conversationStore"

export function GetNotificationLink(notification) {
    let type = notification.type;
    let receiverProfileType = notification.toProfile.type;
    const { profile } = profileStore;
    let notificationMessage = notification.message ? notification.message : "";

    if (notificationMessage && typeof(notificationMessage) == 'string') {
        notificationMessage = JSON.parse(notificationMessage)
        notificationMessage = _.mapKeys(notificationMessage, (value, key) => _.camelCase(key))
    }
    switch (type) {
        case NotificationType.VenueAcceptPermissionManageSocialPost.ordinal:
            case NotificationType.VenueRemovePermissionManageSocialPost.ordinal:   {
                return `/socialposts/generalsocialpost`
        }
        case NotificationType.VenueRemovePermissionManageAct.ordinal:
            case NotificationType.VenueAcceptPermissionManageAct.ordinal:   {
                return `/agent/actfavorite/venues/${notification.toProfileId}/${notification.fromProfile?.profileName}`
        }
        case NotificationType.ActRosterInvited.ordinal:
        case NotificationType.ActRosterAccepted.ordinal:
            if(receiverProfileType === ProfileType.Agent.ordinal) {
                return "/agent/actroster/myact"
            } else {
                return "/myact";
            }
        case NotificationType.ActRosterRejected.ordinal:
        case NotificationType.ActRosterRemoved.ordinal:
        case NotificationType.MusicianQuitAct.ordinal:
            if(receiverProfileType === ProfileType.Agent.ordinal) {
                return "/agent/actroster"
            } else {
                return "/myact";
            }

        case NotificationType.PasswordChanged.ordinal:
            return "/account/setting";

        case NotificationType.AgentActRosterInvitation.ordinal:
        case NotificationType.AgentActRosterRemoved.ordinal:
            return "/musician/agents";
        case NotificationType.AgentActRosterAccepted.ordinal:
        case NotificationType.AgentActRosterRejected.ordinal:
            if (receiverProfileType === ProfileType.Venue.ordinal) {
                return `/venues/${notification.toProfileId}/edit/favourite`;
            } else if (receiverProfileType === ProfileType.Staff.ordinal) {
                return `/staff/acts`
            }
        case NotificationType.ActRemoveAgent.ordinal:
            if(receiverProfileType === ProfileType.Musician.ordinal) {
                return "/musician/venues"
            } else {
                return "/agent/actroster";
            }

        case NotificationType.AgentVenueRosterInvited.ordinal:
        case NotificationType.AgentVenueRosterAccepted.ordinal:
        case NotificationType.AgentVenueRosterRejected.ordinal:
        case NotificationType.AgentVenueRosterRemoved.ordinal:
            if (receiverProfileType === ProfileType.Agent.ordinal) {
                return "/agent/venues";
            } else if (receiverProfileType === ProfileType.Staff.ordinal || receiverProfileType === ProfileType.Venue.ordinal) {
                return `/venues/${notification.toProfileId}/edit/member`;
            }
            return "";
        case NotificationType.AgentVenueRosterUpdated.ordinal:
            return "/agent/venues";

        case NotificationType.VenueActRosterInvited.ordinal:
        case NotificationType.VenueActRosterRemoved.ordinal:
            return "/musician/venues";
        case NotificationType.VenueActRosterAccepted.ordinal:
        case NotificationType.VenueActRosterRejected.ordinal:
            return `/venues/${notification.toProfileId}/edit/favourite`;

        case NotificationType.LineupAdded.ordinal:
        case NotificationType.LineupFeeUpdated.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/musician/booking/${notification.bookingId}/details`;
            }

        case NotificationType.LineupAccepted.ordinal:
        case NotificationType.LineupRejected.ordinal:
        // case NotificationType.LineupConfirmed.ordinal:
        case NotificationType.LineupCancelled.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/lineup`;
            } 
            else {
                return `/musician/booking/${notification.bookingId}/lineup`;
            }
            
        case NotificationType.LineupReminder.ordinal:
        case NotificationType.LineupConfirmed.ordinal:
        case NotificationType.LineupReminder2.ordinal:
            return `/musician/gig/upcoming/${notification.bookingId}`;
        case NotificationType.LineupRemoved.ordinal:
            return `/musician/gig`;

        case NotificationType.BookingCreated.ordinal:
        case NotificationType.BookingUpdated.ordinal: {
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            }
            else if (_.isInteger(notification.bookingRequestId)) {
                return `/musician/booking/${notification.bookingId}/details/${notification.bookingRequestId}`
            }
            else {
                return `/musician/booking/${notification.bookingId}/details`;
            }
        }
        case NotificationType.BookingAccepted.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/promote`;
            } else {
                return `/booking/${notification.bookingId}/promote`;
            }

        case NotificationType.BookingDeclined.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/booking/${notification.bookingId}/edit`;
            }
        case NotificationType.BookingHolded.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/negotiate`;
            } else {
                return `/booking/${notification.bookingId}/edit`;
            }

        case NotificationType.BookingConfirmed.ordinal: 
        if(profile.type === ProfileType.Musician.ordinal){
            return `/musician/gig/upcoming/${notification.bookingId}`; 
        }else if(profile.type === ProfileType.Agent.ordinal){
            return `/agent/gig/upcoming/${notification.bookingId}`; 
        }else if(profile.type === ProfileType.Staff.ordinal){
            return `/staff/gig/upcoming/${notification.bookingId}`; 
        }

        case NotificationType.BookingReminder.ordinal:
            return `/musician/gig/upcoming/${notification.bookingId}`;
        case NotificationType.BookingReminder2.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/musician/booking/${notification.bookingId}/details`;
            }

        case NotificationType.BookingCancelled.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                if (profile.type === ProfileType.Agent.ordinal || profile.type === ProfileType.Staff.ordinal) {
                    return `/booking/${notification.bookingId}/edit`;
                } else {
                    return `/musician/booking/${notification.bookingId}/details`;
                }
            }

        case NotificationType.BookingRequestCreated.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit/${notification.bookingRequestId}`;
            } else {
                return `/musician/booking/${notification.bookingId}/details/${notification.bookingRequestId}`;
            }

        case NotificationType.BookingRequestAccepted.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/booking/${notification.bookingId}/edit`;
            }

        case NotificationType.BookingRequestDeclined.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/booking/${notification.bookingId}/edit`;
            }
        case NotificationType.BookingRequestRemoved.ordinal:
        case NotificationType.BookingRequestHasBeenClaimed.ordinal:
            return `/musician/gig`;

        case NotificationType.AgentConsultantInvitation.ordinal:

            var isConsultantExist = false;

            if (profileStore.profiles && profileStore.profiles.length > 0) {
                for (let data of profileStore.profiles) {

                    if (data.type==ProfileType.Agent.ordinal) {
                        isConsultantExist = true;
                        break;
                    }
                }

            }
            if (isConsultantExist) {
                billingStore.setTemporaryAccess(true)
                return `/agent/agency`;
            } else {
                generalProfileStore.setCreateNewConsultant(true);
                return `/agent/edit/new/consultant/create`;
            }

        case NotificationType.AgentConsultantUpdated.ordinal:
            return `/agent/agency`;

        case NotificationType.AgentConsultantAccepted.ordinal:
        case NotificationType.AgentConsultantDeclined.ordinal:
        case NotificationType.ConsultantQuitAgency.ordinal:
            return `/agent/consultant`;
        case NotificationType.AgentRemoveConsultant.ordinal:
            return `/agent`;
        case NotificationType.ConsultantChangeAgent.ordinal:
            agentConsultantStore.changeCurrentAgent(notificationMessage.agentConsultantId, notificationMessage.agentId, notificationMessage.consultantVenue ? notificationMessage.consultantVenue : "").then(response => {
                return "/agent/agency";
            });
        case NotificationType.ConsultantRemoveAgency.ordinal:
            return window.location.reload()

        case NotificationType.InvoiceIncoming.ordinal:
        case NotificationType.InvoicePaid.ordinal:
            return `/invoice/${notificationMessage.invoiceId}/detail`;

        case NotificationType.NewBookingLineupMessage.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/lineup`;
            } else {
                return `/musician/booking/${notification.bookingId}/lineup`;
            }

        case NotificationType.ExtraGigFeeRequested.ordinal:
            return `/booking/${notification.bookingId}/edit`;

        case NotificationType.ExtraGigFeeApproved.ordinal:
        case NotificationType.ExtraGigFeeDeclined.ordinal:
            return `/musician/booking/${notification.bookingId}/details`;

        case NotificationType.NewBookingNegotiationMessage.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/negotiate`;
            } else {
                if (receiverProfileType === ProfileType.Agent.ordinal || receiverProfileType === ProfileType.Staff.ordinal) {
                    return `/booking/${notification.bookingId}/negotiate`;
                } else {
                    return `/musician/booking/${notification.bookingId}/negotiate`;
                }
            }

        case NotificationType.NewVenueBookingCreated.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/edit`;
            } else {
                return `/booking/${notification.bookingId}/edit`;
            }

        case NotificationType.FestivalRegistrationCreated.ordinal:
            return "/agent/actroster";
        case NotificationType.FestivalRegistrationAccepted.ordinal:
        case NotificationType.FestivalRegistrationDeclined.ordinal:
            return "/musician/agents";

        case NotificationType.NewMessageReceived.ordinal:
            console.log("weeh", notificationMessage)
            return `/messages/${notificationMessage.conversationId}`;


        case NotificationType.VenueAgentRosterInvited.ordinal:
            return "/agent/venues";

        case NotificationType.VenueAcceptedAgentInvitation.ordinal:
            if (receiverProfileType === ProfileType.Agent.ordinal) {
                return "/agent/venues";
            } else if (receiverProfileType === ProfileType.Staff.ordinal || receiverProfileType === ProfileType.Venue.ordinal) {
                return `/venues/${notification.toProfileId}/edit/member`;
            }
            return "";

        case NotificationType.MusicianInviteAgent.ordinal: 
            return "/agent/actroster";
        
        case NotificationType.VenueAgentRosterAccepted.ordinal:
            return "/musician/agents";
        
        case NotificationType.MusicianApplyVenue.ordinal:
            return "/staff/acts";

        case NotificationType.VenueActRosterActInvited.ordinal:
            if (notification?.toProfile?.actAdminId == profile?.id){
                if (profile.type == ProfileType.Musician.ordinal){
                    return "/musician/venues";
                } else {
                    return "/agent/actroster/myact"
                }
            } else {
                if (profile.type == ProfileType.Agent.ordinal){
                    if (!notification?.toProfile?.actAdminId) return "/agent/actroster/myact"
                    else return "/musician/venues";
                } else {
                    if (!notification?.toProfile?.actAdminId) return "/musician/venues"
                    else return "/agent/actroster/myact"
                }
            }

        case NotificationType.VenueDeclineMusicianInvitation.ordinal:
            return "/musician/venues";
        
        case NotificationType.AddActRoster.ordinal:
            return "/musician/agents"

        case NotificationType.VenueMemberSwitch.ordinal:
            return "/organisation";
            "AutoSendNotificationSuccess", 
            "AutoSendNotificationFailed"
        case NotificationType.UpdateOrganisationMember.ordinal:
            return "/organisation";
            case NotificationType.PrivateHireQuote.ordinal:
                return `/messages/${notificationMessage.conversationId}`;
        case NotificationType.AutoSendNotificationSuccess.ordinal:
            case NotificationType.AutoSendNotificationFailed.ordinal:
                case NotificationType.AutoXeroNotificationSuccessCount.ordinal:
                    case NotificationType.AutoXeroNotificationFailedCount.ordinal:
                    
                return "/invoice/lastweek";
        case NotificationType.BookingReminderNotification.ordinal:
        
            if (_.isInteger(notification.bookingRequestId)) {
                return `/musician/booking/${notification.bookingId}/details/${notification.bookingRequestId}`
            }
            else {
                return `/musician/booking/${notification.bookingId}/details`;
            }
        case NotificationType.AgentOwnedLineUpAdded.ordinal:
            if (notification.booking && notification.booking.type === BookingType.Adhoc.ordinal) {
                return `/privateBooking/${notification.bookingId}/details`;
            } else {
                return `/musician/booking/${notification.bookingId}/details`;
            }
        case NotificationType.SocialPostBookingChanged.ordinal:
            case NotificationType.SocialPostBookingCancelAfterPublished.ordinal:
                case NotificationType.SocialPostBookingChangedAfterPublished.ordinal:
            return `/post/detail/${notification.bookingId}`;
        default:
            return "";
    }
}
