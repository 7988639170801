import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SetListForm from "../../components/setList/SetListForm";
import { Button, Grid, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import blankProfile from "../../img/hotgig-loading.png";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import RichTextEditor from "../../components/richTextEditor/RichTextEditor"
import "./Booking.css";
import moment from "moment";
import { Paper, Hidden } from "@material-ui/core";
import PdfViewer from "../../components/pdf/PdfViewer";
import MultiInputTextbox from './MultiInputTextbox'; // Adjust path if needed
import blankBackground from "../../img/hero2.jpg";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BookingDetailsModal from "../../components/booking/DocumentModal/BookingDetailsModal.js";
import Heading from "../../components/Heading";

import Loading from "../../components/loadingSpinner/Loading";
const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("templateStore", "bookingRequestStore", "profileStore")
@observer
class SendBookingDetails extends Component {
    state = {
        isLoading: false,
        clientMessage: "",
        pdfFile: "",
        to:[],
        image: null,
        openDetails: false,
        sendFeeDetails: true
    };
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Send Booking Details" }];

    componentDidMount() {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        this.setState({
            ...this.state,
            isLoading:true
        })
        Promise.all(
            [this.props.profileStore.getAllImageById(currentProfile?.id),
                this.props.bookingRequestStore.downloadBookinDetailsPdf(this.props.match.params.id, this.state.sendFeeDetails),
                this.props.bookingRequestStore.initializeEditBooking(this.props.match.params.id)
            ]).then(response => {
                var responseFile = response[1]
                let pdfFile = `data:application/pdf;base64,${responseFile}`;
                this.setState({
                    ...this.state,
                    pdfFile: pdfFile,
                    image: response[0],
                    isLoading: false
                });
            })
    }

    handleChangeBody = (e) => {
        this.setState({
            ...this.state,
            clientMessage: e
        });
    };

    handleSubmitForm = () => {
        var data = {
            emailAddress: this.state.to.toString(),
            bookingId: this.props.match.params.id,
            emailBody: this.state.clientMessage,
            sendFeeDetails: this.state.sendFeeDetails
        }
        this.props.bookingRequestStore.sendBookingDetailsToEmail(data).then(responseFile => {
        })
        
    }

    checkSendFeedDetails = (checked) => {
        this.setState({  isLoading: true})
            this.props.bookingRequestStore.downloadBookinDetailsPdf(this.props.match.params.id, checked)
            .then(responseFile => {
            let pdfFile = `data:application/pdf;base64,${responseFile}`;
            this.setState({
                ...this.state,
                pdfFile: pdfFile,
                isLoading: false,
                sendFeeDetails: checked
            });
        })
        
    }

    setData = (newInputs) => {
        console.log(newInputs)
        this.setState({
            ...this.state,
            to: newInputs
        });
    }
    render() {
        const { booking, createdBy,bookingHeading } = this.props.bookingRequestStore;
        const {clientMessage, image, isLoading, sendFeeDetails} = this.state
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Loading showed={isLoading} />
                    {/* <Heading title={bookingHeading}>
                        <div style={{ display: "inline-block" }}>
                            <BookingStatusBadge cancelledBy={booking.cancelledBy ? booking.cancelledBy.type : 0} status={this.getBookingStatus()} />
                        </div>
                    </Heading> */}
                    <Heading title={`${booking.title} ${moment(booking.dateStart).format("ddd, DD-MM-YYYY")} ${moment(booking.timeStart, "HH:mm").format("h.mma")}`}>
                    </Heading>
                    <Paper style={{padding:30}}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={5}>
                                <div>
                                    <Typography variant="headline" component="h3" gutterBottom>
                                        Send Booking Details
                                    </Typography>
                                    <Grid container spacing={8}>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <MultiInputTextbox onInputsChange={(newInputs) => this.setData(newInputs)} />
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Typography gutterBottom>
                                                <b>Subject:</b> &nbsp; {`Booking Details of ${booking?.title} - ${moment(booking.dateStart).format("ddd, DD-MM-YYYY")}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Typography gutterBottom>
                                                <b>Message to client:</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={10} className="gridPrivateBookingForm">
                                            {/* <TextField
                                                id="input-client-message"
                                                value={clientMessage}
                                                onChange={this.handleSetMessage}
                                                fullWidth
                                                multiline
                                                rows={10}
                                            /> */}
                                            <RichTextEditor value={this.state.clientMessage} onChange={this.handleChangeBody}/>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={sendFeeDetails}
                                                        onChange={e => this.checkSendFeedDetails(e.target.checked)}
                                                    />
                                                }
                                                labelPlacement="end"
                                                label="Include Fee Details"
                                            />
                                    </Grid>
                                        <Grid item xs={12}>
                                            <div className="action">
                                                <Button variant="raised" color="primary" onClick={this.handleSubmitForm}>
                                                    Send
                                                </Button>
                                            </div>
                                           
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={7} style={{ border: 2, borderColor: "gray", borderStyle: "solid", paddingLeft: 15, paddingBottom: 15 }}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Email Preview
                            </Typography>
                            <Grid container spacing={8}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>From:</b> &nbsp;  {createdBy &&
                                            createdBy.firstName + " " + createdBy.lastName}
                                        no-reply@hotgiggity.com
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>To:</b> {this.state.to.toString()}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Typography gutterBottom>
                                        <b>Subject:</b> &nbsp; {`Booking Details of ${booking?.title} - ${moment(booking.dateStart).format("ddd, DD-MM-YYYY")}`}&nbsp;
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <center>
                                        <table align="center" border={0} cellPadding={0} cellSpacing={0} height="100%" width="100%" id="bodyTable" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%', backgroundColor: '#262626' }}>
                                            <tbody><tr>
                                                <td align="center" valign="top" id="bodyCell" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', height: '100%', margin: 0, padding: 0, width: '100%', borderTop: 0 }}>
                                                    <table border={0} cellPadding={0} cellSpacing={0} width="100%" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                        <tbody><tr>
                                                            <td align="center" valign="top" id="templateHeader" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#6b6c6d', backgroundImage: `url(${image?.backgroundImage?.length ? image?.backgroundImage[0].url : blankBackground})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '140px', paddingBottom: '80px' }}>
                                                                <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{ width: '600px' }}>
                                                                    <tbody><tr>
                                                                        <td align="center" valign="top" width={600} style={{ width: '600px' }}>
                                                                            <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important' }}>
                                                                                <tbody><tr>
                                                                                    <td valign="top" className="headerContainer" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                        <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{ minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                            <tbody className="mcnTextBlockOuter">
                                                                                                <tr>
                                                                                                    <td valign="top" className="mcnTextBlockInner" style={{ paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                        <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100%' }}>
                                                                                                            <tbody><tr>
                                                                                                                <td valign="top" width={600} style={{ width: '600px' }}>
                                                                                                                    <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{ maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }} width="100%" className="mcnTextContentContainer">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td valign="top" className="mcnTextContent" style={{ padding: '0px 18px 9px', color: '#FFFFFF', fontFamily: '"Open Sans", sans-serif', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', fontSize: '18px', lineHeight: '150%', textAlign: 'left' }}>
                                                                                                                                    <img style={{ display: 'block', margin: '0 auto 0 auto', border: '0px solid #fff', boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)', width: '100px', height: '100px', borderRadius: '100px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundColor: '#efefef', objectFit: 'cover' }} src={image?.profileImage ? image?.profileImage?.url400 : blankProfile} />
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            </tbody></table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody></table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                            <tr>
                                                                <td align="center" valign="top" id="templateBody" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', backgroundColor: '#ffffff', backgroundImage: 'none', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', borderTop: 0, borderBottom: 0, paddingTop: '40px', paddingBottom: '40px' }}>
                                                                    <table align="center" border={0} cellSpacing={0} cellPadding={0} width={600} style={{ width: '600px' }}>
                                                                        <tbody><tr>
                                                                            <td align="center" valign="top" width={600} style={{ width: '600px' }}>
                                                                                <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" className="templateContainer" style={{ borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', maxWidth: '600px !important' }}>
                                                                                    <tbody><tr>
                                                                                        <td valign="top" className="bodyContainer" style={{ msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                            <table border={0} cellPadding={0} cellSpacing={0} width="100%" className="mcnTextBlock" style={{ minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                <tbody className="mcnTextBlockOuter">
                                                                                                    <tr>
                                                                                                        <td valign="top" className="mcnTextBlockInner" style={{ paddingTop: '9px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }}>
                                                                                                            <table align="left" border={0} cellSpacing={0} cellPadding={0} width="100%" style={{ width: '100%' }}>
                                                                                                                <tbody><tr>
                                                                                                                    <td valign="top" width={600} style={{ width: '600px' }}>
                                                                                                                        <table align="left" border={0} cellPadding={0} cellSpacing={0} style={{ maxWidth: '100%', minWidth: '100%', borderCollapse: 'collapse', msoTableLspace: '0pt', msoTableRspace: '0pt', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%' }} width="100%" className="mcnTextContentContainer">
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <td valign="top" className="mcnTextContent" style={{ paddingTop: 0, paddingRight: '18px', paddingBottom: '9px', paddingLeft: '18px', msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', wordBreak: 'break-word', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%', textAlign: 'center' }}>

                                                                                                                                        <p style={{ textAlign: 'left', margin: '10px 0', padding: 0, msoLineHeightRule: 'exactly', msTextSizeAdjust: '100%', WebkitTextSizeAdjust: '100%', color: '#666666', fontFamily: '"Open Sans", sans-serif', fontSize: '16px', lineHeight: '150%' }}>
                                                                                                                                            <span style={{ color: '#000000', fontFamily: '"Open Sans", sans-serif' }} dangerouslySetInnerHTML={{ __html: clientMessage != "" ? clientMessage : '{Messagetoclient}' }}>
                                                                                                                                            </span>
                                                                                                                                        </p>
                                                                                                                                        
                                                                                                                                        <div style={{ height: '6px' }}>&nbsp;</div>
                                                                                                                                   
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                </tbody></table>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    </tbody></table>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody></table>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>
                                            </tbody></table>
                                    </center>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <FormControl style={{ marginRight: 10, marginTop: 10 }}>
                                        <Input
                                            id="insuranceFileName"
                                            value={`${booking.title}-${moment(booking.dateStart).format("ddd, DD-MM-YYYY")}.pdf`}
                                            startAdornment={
                                                <InputAdornment
                                                    onClick={e => this.setState({ openDetails: true})}
                                                    position="start"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <a target="_blank">
                                                        <PictureAsPdfIcon />
                                                    </a>
                                                </InputAdornment>
                                            }
                                            readOnly={true}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>

                            </Grid>
                        </Grid>
                        {/* <Grid style={{marginTop:20}} container spacing={8}>
                            <Grid item xs={12} lg={12} style={{ border: 2, borderColor: "gray", borderStyle: "solid", paddingLeft: 15, paddingBottom: 15 }}>
                                <PdfViewer file={this.state.pdfFile}/>
                            </Grid>
                        </Grid> */}
                    </Paper>
                    <BookingDetailsModal
                            open={this.state.openDetails}
                            title="Booking Details"
                            maxWidth="lg"
                            clientInvoice={false}
                            pdfFile={this.state.pdfFile}
                            closeHandler={e => this.setState({ openDetails: false })}
                            bookingId={this.props.match.params.id} 
                            sendFeeDetails={this.state.sendFeeDetails}
                        />
                </MainContainer>
                
                
            </main>
        );
    }
}

export default SendBookingDetails;
