import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, Grid, Typography, ListItem} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import "../HgBilling.css";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withRouter, Link } from "react-router-dom";
import { hgStripe } from "../StripeConfig.js";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";


const styles = theme => ({
    card: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    actions: {
      display: 'flex',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        marginRight: -8,
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    button: {
        margin: theme.spacing.unit,
      },
  });

@inject("userStore", "profileStore",  "hgBillingStore")
@withRouter
@observer
class SingleVenueBilling extends Component{
    constructor(props){
        super(props);
    };


    state = {

    };

    selectPlan = (value,type, isReturn) => {
        if(isReturn) return;
        this.props.selectPlan(value,type);
    }
    selecFreePlan = () => {
        this.props.selecFreePlan();
    }

    handleOpenModal = (isReturn) => {
        if(isReturn) return;
        this.setState({ toggleModal: true });
    }
    handleCloseModal = () => {
        this.setState({
            ...this.state,
            toggleModal: false
        });
        
    };


    render(){
        const {billingAccess} = this.props.hgBillingStore
        var isSingleVenueFree  = !billingAccess ? true:false || billingAccess.isFreePlan || billingAccess?.priceId === hgStripe.pricing.singleVenueFreeMembershipMonth
        var isSingleVenueBasicMonthly = billingAccess?.priceId === hgStripe.pricing.singleVenueMembershipMonth || billingAccess?.priceId === hgStripe.pricing.singleVenueMonthlyLegacy 
        var isSingleVenueBasicPerGigs = billingAccess?.priceId === hgStripe.pricing.singleVenueMembershipPerGigBilledMonthly
        var isSingleVenueProMonthly = billingAccess?.priceId === hgStripe.pricing.singleVenueProMembershipMonth
        var isSingleVenueProPerGigs = billingAccess?.priceId === hgStripe.pricing.singleVenueProMembershipPerGigBilledMonthly
        var priceIdExisted = billingAccess?.priceId ? true : false
        var isEssential = billingAccess?.isBasicPlan && !priceIdExisted
        var isProPlan = billingAccess?.isProPlan && !priceIdExisted

        return (
           <Fragment>
                    <Grid container className="billingHg">
                        <Grid item xs={12} md={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isSingleVenueFree? "pink-background" : ""}>
                                <th>Free</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                    <ul className="billingBullet">
                                        <li>Create & manage your venue profile</li>
                                        <li>Up to 8 bookings per month</li>
                                        <li>Unlimited staff with role-based permissions</li>
                                        <li>Act roster management incl. venue favourites</li>
                                        <li>Act availability visibility</li>
                                        <li>Integrated worksheets & contracts</li>
                                        <li>Cancellation workflows</li>
                                        <li>Automated artist invoicing</li>
                                        <li>Spend vs budget reporting</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <td style={{textAlign:"center"}}>
                                    <Button className={isSingleVenueFree ? "buttonHgBilling" : ""}  style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.handleOpenModal(isSingleVenueFree)}>
                                        {isSingleVenueFree ? "Current Plan" : "Select Plan"}
                                    </Button>
                                </td>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={12} md={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isEssential || isSingleVenueBasicMonthly || isSingleVenueBasicPerGigs? "pink-background" : ""}>
                                <th>Essentials</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Free plus:
                                    <ul className="billingBullet">
                                        <li>Unlimited bookings</li>
                                        <li>Invite agents to manage your entertainment</li>
                                        <li>Automated gig promotion - website & screens</li>
                                        <li>Social post management for both live events and general social posts </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $15 per gig
                                        </td>
                                        <td>
                                            {!isSingleVenueBasicMonthly && isEssential ? "Free" :"$99 per month"}  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button className={isSingleVenueBasicPerGigs ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.singleVenueMembershipPerGigBilledMonthly, 0, isSingleVenueBasicPerGigs)}>
                                            {isSingleVenueBasicPerGigs  ? "Current Plan" : "Select Plan"}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className={isSingleVenueBasicMonthly ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.singleVenueMembershipMonth, 1, isSingleVenueBasicMonthly)}>
                                            {isSingleVenueBasicMonthly  ? "Current Plan" : isEssential ? "Current Plan Free" :"Select Plan" }
                                            </Button>
                                           
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={12} md={3} className="billingHgCell">
                        <table className="hgBillingTable">
                            <tr className={ isProPlan || isSingleVenueProMonthly || isSingleVenueProPerGigs? "pink-background" : ""}>
                                <th>Pro</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Essentials plus:
                                    <ul className="billingBullet">
                                        <li>Accounting Package Integrations including XERO & MYOB (Quickbooks coming soon)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $19 per gig
                                        </td>
                                        <td>
                                            {!isSingleVenueProMonthly && isProPlan ? "Free" :"$149 per month"}  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button className={isSingleVenueProPerGigs ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.singleVenueProMembershipPerGigBilledMonthly, 0,isSingleVenueProPerGigs)}>
                                                {isSingleVenueProPerGigs  ? "Current Plan" : "Select Plan"}
                                            </Button>
                                        </td>
                                        <td>
                                            <Button className={isSingleVenueProMonthly ? "buttonHgBilling" : ""} style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(hgStripe.pricing.singleVenueProMembershipMonth, 1,isSingleVenueProMonthly)}>
                                            {isSingleVenueProMonthly ? "Current Plan" : isProPlan ? "Current Plan Free" : "Select Plan"}
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                               
                            </tr>
                        </table>
                        </Grid>
                        <Grid item xs={12} md={3} className="billingHgCell">
                        {/* <table className="hgBillingTable">
                            <tr>
                                <th>Pro Plus</th>
                            </tr>
                            <tr className="hgTrTable">
                                <td className="hgBillingAlignTop">
                                Everything in Pro plus:
                                    <ul className="billingBullet">
                                        <li>Auto Schedule to book any time period of entertainment with one click. (coming soon)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr className="hgBillingFooter">
                                <table className="hgBillingChild">
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            Pay As You Go
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                        <td>
                                            Pay Monthly
                                            <Divider style={{background: 'black', marginTop:5}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            $25 per gig
                                        </td>
                                        <td>
                                            $189 per gig
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="hgBillingBorderRight">
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(5)}>
                                                Select Plan
                                            </Button>
                                        </td>
                                        <td>
                                            <Button style={{height:30,width:90, fontSize:8, borderRadius:20}} variant="contained" color="primary" onClick={() => this.selectPlan(6)}>
                                                Select Plan
                                            </Button>
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table> */}
                        </Grid>
                    </Grid>
                    <ConfirmationModal
                        open={this.state.toggleModal}
                        title={"Select Free Plan"}
                        message={`Are you sure you want to select the free plan?`}
                        closeHandler={this.handleCloseModal}
                        confirmationHandler={this.selecFreePlan}
                        declineHandler={this.handleCloseModal}
                        confirmationLabel="Confirm"
                    />
           </Fragment>
        );
    }
}

export default withStyles(styles) (SingleVenueBilling);
