import React, { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";

import { Grid, Typography, Button, Hidden, InputLabel } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import BookingEventDetails from "./BookingForm/BookingEventDetails";
import BookingClientDetails from "./BookingForm/BookingClientDetails";
import BookingSocialDetail from "./BookingForm/BookingSocialDetail";
import BookingFeeDetails from "./BookingForm/BookingFeeDetails";
import BookingDateDetails from "./BookingForm/BookingDateDetails";
import BookingRequirementDetails from "./BookingForm/BookingRequirementDetails";
import BookingDressInput from "./BookingForm/BookingDressInput";
import BookingNumberSet from "./BookingForm/BookingNumberSet";
import BookingNotes from "./BookingForm/BookingNotes";
import BookingContract from "./BookingForm/BookingContract";

import { BookingStatusType, ProfileType } from "../../types/enum";
import BookingSummaryModal from "./BookingForm/BookingSummaryModal";
import CancelBookingModal from "./CancelBookingModal";
import ConfirmationModal from "../modal/ConfirmationModal";
import BookingChangeActModal from "./BookingModal/BookingChangeActModal";
import BookingActDetails from "./BookingForm/BookingActDetails";
import BookingAgentContract from "./BookingForm/BookingAgentContract";
import SignatureCanvas from "../sigpad/SignatureCanvas";

import Genre from "../controls/BookingGenres";
import Vibe from "../controls/BookingVibes";
@inject("profileStore", "bookingRequestStore", "agentActStore", "agentVenueStore", "templateStore", "signatureStore","invoiceStore","conversationStore")
@withRouter
@observer
class BookingForm extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Booking Request" }];
    venueSuggestions = [];
    actSuggestions = [];
    sigCanvas = {};

    constructor(props) {
        super(props);
        this.props.bookingRequestStore.resetBooking();
        this.state = {
            cancelBookingConfirmationModalState: false,
            confirmationBookingModalState: false,
            changeActModalState: false,
            confirmDoubleBookingModalState: false,
            errorMessage: "",
            isVenueChange: false,
            isNoteChange: false,
            isActChange: false,
            isProductionChange: false,
            isLoading: false,
            isViewOnly : false,
            unsyncInvoiceModal : false,
            unsyncInvoiceXeroModal : false,
            invoiceid : null,
            unsyncInvoiceModalPayee: false,
            invoice: null
        };
    }

    componentDidMount() {
        this.props.bookingRequestStore.resetBooking()
        const { currentProfile } = this.props.profileStore;
        if (!currentProfile) {
            const profile = this.props.profileStore.getCurrentProfile();
            if (profile) {
                this.props.profileStore.setCurrentProfile(profile)
            }
        }
        this.setState({ isLoading: true });
        this.props.templateStore.showLoadingScreen();
        
        let params = queryString.parse(this.props.location.search);
        if (this.props.bookingId) {
            this.props.bookingRequestStore.initializeEditBooking(this.props.bookingId).finally(() => {
                
                this.setState({ isLoading: false });
            });;
        } else {
            this.props.bookingRequestStore.initializeBooking(this.props.actId, this.props.venueId).then(() => {
                this.props.bookingRequestStore.setBooking("forceAct", params.forceAct);
                this.props.bookingRequestStore.setBooking("isAdmin", true);
            }).finally(() => {
                this.props.templateStore.hideLoadingScreen();
                this.setState({ isLoading: false });
            });
        }

        if (currentProfile && currentProfile.type === ProfileType.Staff.ordinal) {
        }

        
        this.props.signatureStore
            .loadBookingSignature(this.props.bookingId)
            .then(signature => {
                if (signature) {
                    this.setState({ fileContent: signature.fileContent });
                }

                // this.sigCanvas.fromDataURL(fileContent);
            })


        this.props.bookingRequestStore.setBooking("dateStart", moment(this.props.dateStart, "DD-MM-YYYY"));
        this.props.bookingRequestStore.setBooking("dateEnd", moment(this.props.dateStart, "DD-MM-YYYY"));
    }

    venueIsChangging = (value) => {
        this.setState({ isVenueChange: value });
    }

    viewOnlyIsChangging = (value) =>{
        this.setState({ isViewOnly : value})
    }
    noteIsChanging = (value) => {
        this.setState({ isNoteChange: value });
    }

    actIsChanging = (value) => {
        this.setState({ isActChange: value });
    }

    productionIsChangging = (value) => {
        this.setState({ isProductionChange: value });
    }

    handleOpenUnsyncInvoiceXeroModal = invoice => this.setState({ unsyncInvoiceXeroModal: true, invoiceid: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceXeroModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceXeroModal: false,
            invoiceid: null
        });
    };

    handleOpenUnsyncInvoiceModal = invoice => this.setState({ unsyncInvoiceModal: true, invoiceid: invoice, actionAnchor: [] });
    handleCloseUnsyncInvoiceModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceModal: false,
            invoiceid: null
        });
    };

    handleConfirmMarkAsUnsync = () => {
        this.props.invoiceStore.unsyncSendToAccount(this.state.invoiceid).then(responseFile => {
            this.handleCloseUnsyncInvoiceModal()
            this.componentDidMount();
        })
    }
    handleConfirmMarkAsUnsyncXero = () => {
        let currentProfile = this.props.profileStore.getCurrentProfile();
        this.props.invoiceStore.unsyncXeroInvoice(currentProfile.id, this.state.invoiceid).then(response => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.componentDidMount();
        }).catch((err) => {
            this.handleCloseUnsyncInvoiceXeroModal()
            this.componentDidMount();
            this.props.templateStore.openSnackbar("This invoice has already been paid and cannot be unsynced. You will need to manually edit the invoice in XERO.");
        });
    }

    handleNext = () => {
        const { editBookingStep } = this.props.templateStore;
        this.props.history.push(`/booking/${this.props.match.params.id}/negotiate`);
        this.props.templateStore.setEditBookingStep(editBookingStep + 1);
    };

    saveBooking() {
        let { dateStart, dateEnd, act, saveAsDraft } = this.props.bookingRequestStore.booking;
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        this.props.bookingRequestStore.setBooking("dateStart", moment(dateStart));
        this.props.bookingRequestStore.setBooking("dateEnd", moment(dateEnd));
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveBooking().then(
            () => {
                this.props.templateStore.hideLoadingScreen();
                if (!this.props.bookingId) {
                    this.props.templateStore.openSnackbar("Booking has been created.");
                    this.props.signatureStore.setBookingId(this.props.bookingRequestStore.booking.id)
                    if (currentProfile.type === ProfileType.Staff.ordinal && booking.signed) {
                        this.props.signatureStore.submitBookingSignature()
                    }

                    if (currentProfile.type === ProfileType.Musician.ordinal) {
                        this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                        this.props.history.push(`/booking/${this.props.bookingRequestStore.booking.id}/notifyclient`);
                    }
                    else if (act && act.actAdminId && act.actAdminId === currentProfile.id && currentProfile.type === ProfileType.Agent.ordinal) {
                        if (saveAsDraft) {
                            this.props.history.push(`/agent/gig`);
                        } else {
                            this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                            this.props.history.push(`/booking/${this.props.bookingRequestStore.booking.id}/lineup`);
                        }
                    }
                    else {
                        this.props.history.push("/booking?actId=" + this.props.bookingRequestStore.booking.actId + "&venueId=" + this.props.bookingRequestStore.booking.venueId);
                    }
                } else {
                    this.props.templateStore.openSnackbar("Booking details has been updated.");
                    if (currentProfile.type === ProfileType.Musician.ordinal) {
                        this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                        this.props.history.push(`/booking/${this.props.bookingId}/notifyclient`);
                    }  else if (act && act.actAdminId && parseInt(act.actAdminId) === currentProfile.id && currentProfile.type === ProfileType.Agent.ordinal) {
                        this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                        this.props.history.push(`/booking/${this.props.bookingId}/lineup`);
                    } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                        this.props.history.push(`/agent/gig`);
                    } else if (currentProfile.type === ProfileType.Staff.ordinal) {
                        this.props.history.push(`/staff/gig`);
                    }
                }
                this.props.bookingRequestStore.resetBooking()
            },
            reason => {
                this.props.templateStore.hideLoadingScreen();
                if (reason === "Invalid") {
                    const { error } = this.props.bookingRequestStore;
                    if (error) {
                        let node = undefined;
                        if (error["title"] || error["venue"] || error["act"] || error["agent"] || error["ticketUrl"]) {
                            node = findDOMNode(this.refs.eventDetail);
                        } else if (error["gigFee"] || error["agentCommissionFee"] || error["actFee"]) {
                            node = findDOMNode(this.refs.eventFee);
                        }
                        if (node) {
                            window.scrollTo(0, node.offsetTop - 100);
                        }
                    }
                    this.props.templateStore.openSnackbar("Incomplete Booking Field(s)");
                } else {
                    // let node = findDOMNode(this.refs.eventDate);
                    // if(node){
                    //     window.scrollTo(0, node.offsetTop - 100);
                    // }
                    // this.props.templateStore.openSnackbar("" + reason);
                    this.handleOpenConfirmDoubleBookingModal(reason);
                }
            }
        );
    }

    handleOpenConfirmationModal = () => {
        const {booking} = this.props.bookingRequestStore
        if(!booking.actId){
            this.props.bookingRequestStore.setBooking("saveAsDraft", true);
            this.props.bookingRequestStore.setBooking("notifyAct", false);
        }else{
            this.props.bookingRequestStore.setBooking("notifyAct", true);
            this.props.bookingRequestStore.setBooking("saveAsDraft", false);
        }
        this.setState({
            ...this.state,
            confirmationBookingModalState: true
        });
    };

    handleSaveButton = () => {
        const { currentProfile } = this.props.profileStore;
        const { booking } = this.props.bookingRequestStore;
        if (currentProfile.type === ProfileType.Musician.ordinal) {
            this.saveBooking();
        }
        else {
            if (!booking.id && !booking.notifyAct) {                
                // Checked saveAsDraft by default
                // if it's newly created booking by agent, const, or staff
                this.props.bookingRequestStore.setBooking("saveAsDraft", true);
            }
            this.handleOpenConfirmationModal();
        }
    }

    handleCloseConfirmationModal = () => {
        this.setState({
            ...this.state,
            confirmationBookingModalState: false
        });
    };

    handleOpenCancelConfirmationModal = () => {
        this.setState({
            ...this.state,
            cancelBookingConfirmationModalState: true
        });
    };

    handleCloseCancelConfirmationModal = () => {
        this.setState({
            ...this.state,
            cancelBookingConfirmationModalState: false
        });
    };

    handleOpenChangeActModal = () => {
        this.setState({
            ...this.state,
            changeActModalState: true
        });
    };

    handleRedirectToInvoice = (invoiceId) => {
        const { booking } = this.props.bookingRequestStore;
        this.props.history.push(`/invoice/${invoiceId}/detail`);
    }

    handleRedirectUnsyncInvoice = (isPayer) => {
        const { booking } = this.props.bookingRequestStore;

        if(isPayer){
            if(booking.profilePayer.synchedStatus === 1){
                this.handleOpenUnsyncInvoiceModal(booking.profilePayer.invoiceId)
            }else if(booking.profilePayer.synchedPayer){
                this.handleOpenUnsyncInvoiceXeroModal(booking.profilePayer.invoiceId)
            }else if(booking.profilePayer.synchedPayee){
                this.handleOpenUnsyncInvoicePayeeModal(booking.profilePayer.invoiceId)
            }
        }else{
            if(booking.profilePayee.synchedStatus === 1){
                this.handleOpenUnsyncInvoiceModal(booking.profilePayee.invoiceId)
            }else if(booking.profilePayee.synchedPayee){
                this.handleOpenUnsyncInvoiceXeroModal(booking.profilePayee.invoiceId)
            }else if(booking.profilePayee.synchedPayer){
                this.handleOpenUnsyncInvoicePayeeModal(booking.profilePayee.invoiceId)
            }
        }
        
    }

    handleOpenUnsyncInvoicePayeeModal = invoiceId => {
        this.props.invoiceStore.getByIdSimple(invoiceId).then(result => {
            this.setState({ unsyncInvoiceModalPayee: true, invoice: result, actionAnchor: [] });
        })
    }
    handleCloseUnsyncInvoicePayeeModal = () => {
        this.setState({
            ...this.state,
            unsyncInvoiceModalPayee: false,
            invoiceid: null
        });
        
    };
    handleSendMessageNotification = (invoiceId) => {
        var currentProfile = this.props.profileStore.getCurrentProfile()
        var result = this.state.invoice
        if(result){
            var payeeName = result.payeeName
            var invoiceNumber = "HG-"+result.no
            var title = `Please unsync ${invoiceNumber} to enable changes`;
            var body = `<p>Hi ${payeeName},</br></p><p>Please unsync invoice ${invoiceNumber} from your accounting system so that the booking can be updated.</p><p>Thanks,</p></br><p>${currentProfile.firstName} ${currentProfile.lastName}</p>`
            this.props.conversationStore.setTitle(title);
            this.props.conversationStore.setMessage(body);
            this.props.conversationStore.setParticipant(result.payee);
            this.props.history.push("/messages/invoice");
        }

    
        // this.props.conversationStore.handleSendMessageNotification(invoice)
       
    }
  
    handleCloseChangeActModal = () => {
        this.setState(
            {
                ...this.state,
                changeActModalState: false
            },
            () => {
                if (this.props.bookingId) {
                    this.props.bookingRequestStore.initializeEditBooking(this.props.bookingId);
                }
            }
        );
    };

    handleOpenConfirmDoubleBookingModal = errorMessage =>
        this.setState({ ...this.state, confirmDoubleBookingModalState: true, errorMessage: errorMessage });
    handleCloseConfirmDoubleBookingModal = () => this.setState({ ...this.state, confirmDoubleBookingModalState: false });

    handleConfirmDoubleBookingBooking = e => {
        this.handleCloseConfirmDoubleBookingModal();
        const { match } = this.props;
        const { currentProfile } = this.props.profileStore;
        this.props.templateStore.showLoadingScreen();
        this.props.bookingRequestStore.saveBookingWithoutCheckingAvailability().then(response => {
            this.props.templateStore.hideLoadingScreen();
            if (!match.params.id) {
                this.props.templateStore.openSnackbar("Booking has been created.");
                this.props.history.push("/booking");
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    this.props.history.push(`/privateBooking/${response.id}/lineup`);
                } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                    this.props.history.push(`/agent/calendar`);
                }
            } else {
                this.props.templateStore.openSnackbar("Booking details has been updated.");
                this.props.templateStore.setEditBookingStep(this.props.templateStore.editBookingStep + 1);
                if (currentProfile.type === ProfileType.Musician.ordinal) {
                    this.props.history.push(`/privateBooking/${response.id}/lineup`);
                } else if (currentProfile.type === ProfileType.Agent.ordinal) {
                    this.props.history.push(`/agent/gig`);
                }
            }
        });
    };

    handleCancelDoubleBookingBooking = e => {
        let node = findDOMNode(this.refs.eventDate);
        if (node) {
            window.scrollTo(0, node.offsetTop - 100);
        }
        // this.props.templateStore.openSnackbar(reason);
        this.handleCloseConfirmDoubleBookingModal();
    };

    handleCancelDoubleBookingBooking = e => {
        let node = findDOMNode(this.refs.eventDate);
        if (node) {
            window.scrollTo(0, node.offsetTop - 100);
        }
        // this.props.templateStore.openSnackbar(reason);
        this.handleCloseConfirmDoubleBookingModal();
    };

    isReadonly = () => {
        const { booking } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const readOnly = this.props.readOnly || (booking.createdById && booking.createdById !== currentProfile.id);
        return readOnly;
    };

    render() {
        const { booking, error } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const { bookingSignatureId } = this.props.signatureStore;
        const { isViewOnly } = this.state
        if (booking.signed && bookingSignatureId && this.sigCanvas && this.sigCanvas.fromDataURL) {
            const { fileContent } = this.state;
            this.sigCanvas.fromDataURL(fileContent);
            this.sigCanvas.off();
        }

        const showCancelBookingForAgent =
            this.props.isEdit &&
            booking &&
            currentProfile &&
            booking.createdById === currentProfile.id &&
            moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
            booking.status !== BookingStatusType.Cancelled.ordinal;

        const showCancelBookingForStaff =
            currentProfile &&
            currentProfile.type === ProfileType.Staff.ordinal &&
            booking.id &&
            booking.createdById !== currentProfile.id &&
            moment(booking.dateStart).startOf("day") >= moment().startOf("day") &&
            booking.status !== BookingStatusType.Cancelled.ordinal;

        const isCancelled =
            (booking.status === BookingStatusType.Cancelled.ordinal ||
                booking.status === BookingStatusType.CancelledByAct.ordinal) &&
            booking.cancelledBy;

        const isDeclined = booking.status === BookingStatusType.Declined.ordinal

        const isCancelledByAct =
            (booking.status === BookingStatusType.Cancelled.ordinal ||
                booking.status === BookingStatusType.CancelledByAct.ordinal) &&
            booking.cancelledBy &&
            booking.cancelledBy.type === ProfileType.Act.ordinal;

        var canUpdateBooking = ProfileType.Staff.ordinal
        var isMusician = true
        var isStaff = false

        if (currentProfile) {
            canUpdateBooking = currentProfile.type === ProfileType.Staff.ordinal || currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Musician.ordinal;
            isMusician = !(currentProfile.type === ProfileType.Agent.ordinal || currentProfile.type === ProfileType.Staff.ordinal)
            isStaff = currentProfile.type === ProfileType.Staff.ordinal
        }


        const showNextButton = currentProfile &&
            booking.createdById &&
            booking.createdById !== currentProfile && currentProfile.id &&
            currentProfile.type !== ProfileType.Staff.ordinal &&
            (booking.status !== BookingStatusType.Draft.ordinal && booking.status !== BookingStatusType.SentToAct.ordinal &&  booking.status !== BookingStatusType.Declined.ordinal && booking.status !== BookingStatusType.Hold.ordinal)&&
            booking.dateStart < moment();
        // const readOnly = !canUpdateBooking;
        var synchedStatus = booking.profilePayer?.synchedStatus === 1 
        var synchedPayee = booking.profilePayer?.synchedPayee
        var synchedPayer = booking.profilePayer?.synchedPayer
        var invoiceIdPayer = booking.profilePayer?.invoiceId

        var synchedStatusPayee = booking.profilePayee?.synchedStatus === 1 
        var synchedPayeePayee = booking.profilePayee?.synchedPayee
        var synchedPayerPayee = booking.profilePayee?.synchedPayer
        var invoiceIdPayee = booking.profilePayee?.invoiceId
        var invoicePayerIsPaid =  booking.profilePayer?.status === "paid"
        var invoicePayeeIsPaid =  booking.profilePayee?.status === "paid"

        var showPayerSection = synchedStatus || synchedPayee || synchedPayer
        var showPayeeSection = synchedStatusPayee || synchedPayeePayee || synchedPayerPayee
  

     
        var createdByAgentInStaffPespective = false
        var activateEditVenue = booking?.activateEditVenue
        if(currentProfile?.type === ProfileType.Staff.ordinal && !activateEditVenue){
            if(booking.createdBy?.type != ProfileType.Staff.ordinal){
                createdByAgentInStaffPespective = true
            }
        }

        // const readOnly = showPayerSection || showPayeeSection || createdByAgentInStaffPespective || invoicePayerIsPaid || invoicePayeeIsPaid
        const readOnly = createdByAgentInStaffPespective || invoicePayerIsPaid || invoicePayeeIsPaid
        return (
            <Fragment>
                {(isCancelled || isDeclined) && 
                (
                    <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventDetail">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Cancellation
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <InputLabel shrink>Cancelled By</InputLabel>
                                    <Typography className="scrollable-div" variant="subheading">
                                        {booking.cancelledBy && booking.cancelledBy.type === ProfileType.Act.ordinal
                                            ? booking.cancelledBy.actName
                                            : booking.cancelledBy && (booking.cancelledBy.type === ProfileType.Agent.ordinal)
                                                ? booking.cancelledBy.profileName
                                                : booking.cancelledBy && booking.cancelledBy.type === ProfileType.Staff.ordinal
                                                    ? `${booking.cancelledBy.firstName} ${booking.cancelledBy.lastName}`
                                                    : booking.cancelledBy && booking.cancelledBy.type === ProfileType.PrivateHire.ordinal
                                                    ? `${booking.cancelledBy.firstName} ${booking.cancelledBy.lastName}`
                                                    : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel shrink>Cancellation Reason</InputLabel>
                                    <Typography
                                        className="scrollable-div"
                                        component="div"
                                        dangerouslySetInnerHTML={{ __html: booking.cancellationReason }}
                                    />
                                </Grid>
                                {(isCancelledByAct || isDeclined) && (
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={this.handleOpenChangeActModal}>
                                                Select Another Act
                                            </Button>
                                        </Grid>
                                        <BookingChangeActModal
                                            open={this.state.changeActModalState}
                                            onCloseModel={this.handleCloseChangeActModal}
                                            onClose={this.handleCloseChangeActModal}
                                            booking={booking}
                                        />
                                    </Fragment>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )
                }

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventDate">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Date and Time Detail
                            </Typography>
                            {isMusician ? (
                                <Typography gutterBottom className="side-helper-text">
                                    {/* The date is already populated from when you chose it from the booking station - Manually input the start and
                                    finish time of the gig. The clock is in 24hr time. eg If the gig times are 10pm til 1am simply choose 22.00 til
                                    1.00. You are also able to do a repeat booking. eg book an act in for every second Friday for the month. Simply
                                    choose Fortnightly in the repeat booking field, then choose the number of repeats you require. If it was every
                                    second Friday for the month you would choose 2 repeats. */}
                                    Manually input the date, start and finish times, load in and sound check times.
                                </Typography>
                            ) : (
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Manage this basic information — your name, email, location, etc — to help others find you on Hot Giggity, and make it
                                    easier to get in touch. These informations will be displayed in your public profile page. */}
                                    The date is already populated from when you chose it from the booking station - Manually input the start and finish time of the gig.
                                    The clock is in 24hr time. eg If the gig times are 10pm til 1am simply choose 22.00 til 1.00. You are also able to do a repeat booking.
                                    eg book an act in for every second Friday for the month. Simply choose Fortnightly in the repeat booking field, then choose the number of repeats you require. If it was every second Friday for the month you would choose 2 repeats.
                                </Typography>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <BookingDateDetails readOnly={readOnly} />
                    </Grid>
                </Grid>

                {isMusician &&
                    <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="clientDetail">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Client Details
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {/* Enter the details for your client. Be sure to include the client's email address so that they receive your invoices and contract. */}
                                    Use this section to enter all of the details of the person or company you are doing the gig for. The email address is required in order to automatically email your invoice to the client.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <BookingClientDetails readOnly={readOnly} />
                        </Grid>
                    </Grid>
                }

      
                    <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventDetail">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Event Detail
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {isMusician
                                        ?
                                        "Enter the event title and venue details. The event title will appear in the line item description on your invoice."
                                        : "Manage this basic information — Event Title - Provider - Venue - Act - Venue Contact - Venue address - Venue Phone fields will all auto populate from details that exist in the system to save you time. You can manually change details in these fields at any time during the booking process."}
                                    {/* : "Manage this basic information — your name, email, location, etc — to help others find you on Hot Giggity, and make it easier to get in touch. These informations will be displayed in your public profile page."} */}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <BookingEventDetails viewOnlyIsChangging={this.viewOnlyIsChangging} readOnly={readOnly} changeVenue={this.venueIsChangging} changeNote={this.noteIsChanging} changeProduction={this.productionIsChangging} />
                        </Grid>
                    </Grid>
                


                {!isMusician &&
                    <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventDetail">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Act Details
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    Use this section to select the act for this booking. Only acts that are available on the booking date and time will be
                                    shown.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <BookingActDetails actChange={this.actIsChanging} readOnly={readOnly || (booking.status === BookingStatusType.Declined.ordinal ? false : readOnly)} />
                        </Grid>
                    </Grid>
                }
                {!isMusician &&
                    <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventSocial">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Social
                                </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {/* {isMusician
                                        ?  */}
                                    Share this booking information to social media simply by clicking on the toggle
                                    {/* : "Share this booking information to social media"} */}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <BookingSocialDetail readOnly={readOnly} />
                        </Grid>
                    </Grid>
                }
                {
                     !isViewOnly &&
                     <Grid container style={{ marginBottom: 60 }}>
                        <Grid item xs={12} lg={3}>
                            <div ref="eventFee">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    {/* {isMusician
                                        ? "Fee Detail"
                                        : "Fee and Commission Detail"} */}
                                    Fee Detail
                                </Typography>
                                {currentProfile?.type === ProfileType.Agent.ordinal ? (
                                    <Fragment>
                                        <Typography gutterBottom className="side-helper-text">
                                            Manually input gig fee and your $ fee or hit drop down for % fee. System will automatically calculate your
                                            commission fee and the balance gig fee to the act.
                                        </Typography>
                                        {/* <Typography gutterBottom className="side-helper-text">
                                            Extra set fee field is provided in the case an act is asked on the night of gig to play an extra set. This is
                                            where the act can adjust and add the extra set fee to the work sheet awaiting the approval of the venue
                                            manager and the agent. Both venue and agent are notified of the adjustment via notification. Upon approval,
                                            the work sheet and the invoice are automatically updated splitting the extra fee among all parties according
                                            to the set percentages.
                                        </Typography> */}
                                        {/* <Typography gutterBottom className="side-helper-text">
                                            Manually choose the number of sets required.
                                        </Typography> */}
                                    </Fragment>) :
                                    currentProfile?.type === ProfileType.Staff.ordinal ?
                                        <Typography gutterBottom className="side-helper-text">
                                            Enter the fee that will be paid to the entertainer excluding tax.
                                        </Typography> :
                                        (<Typography gutterBottom className="side-helper-text">
                                            You have the option to create an invoice for this gig by clicking the create invoice button.
                                            If you don’t wish to create an invoice for this gig simply ensure the button is switched off
                                        </Typography>
                                        )
                                    // : (
                                    //     <Typography gutterBottom className="side-helper-text">
                                    //         Manage this basic information — your name, email, location, etc — to help others find you on Hot Giggity, and make it
                                    //         easier to get in touch. These informations will be displayed in your public profile page.
                                    //     </Typography>
                                    // )
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={9}>
                            <BookingFeeDetails  readOnly={readOnly} />
                        </Grid>
                    </Grid>
                }
               

                <Grid container style={{ marginBottom: 60 }}>
                    <Grid item xs={12} lg={3}>
                        <div ref="eventRequirementDetail">
                            <Typography variant="headline" component="h3" gutterBottom>
                                Gig Requirement
                            </Typography>
                                <Typography gutterBottom className="side-helper-text">
                                    {/* List any production requirements that the act will need to provide for the gig. Also use this field to advise the
                                    act if production is being supplied by the venue or the client. */}
                                    Let the artist know the details of the gig, number of sets, vibe, suggested genres and what to wear.
                                </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={8}>
                            <BookingNumberSet readOnly={readOnly} />
                            <BookingDressInput readOnly={readOnly} />
                            <Vibe id="vibes" readOnly={readOnly} />
                            <Genre id="genres" readOnly={readOnly} />
                        </Grid>
                    </Grid>
                </Grid>

                {
                    !this.state.isLoading ? (
                        <>
                            <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                <Grid item xs={12} lg={3}>
                                    <div ref="eventRequirementDetail">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Production Requirements Detail
                                        </Typography>
                                        {isMusician ? (
                                            <Typography gutterBottom className="side-helper-text">
                                                {/* List any production requirements that the act will need to provide for the gig. Also use this field to advise the
                                    act if production is being supplied by the venue or the client. */}
                                                Use this field to record the production requirements for this gig.
                                            </Typography>
                                        ) : (
                                            <Typography gutterBottom className="side-helper-text">
                                                {/* Manage this basic information — your name, email, location, etc — to help others find you on Hot Giggity, and make it
                                    easier to get in touch. These informations will be displayed in your public profile page. */}
                                                List any production requirements that the act will need to provide for the gig. Also use this field to advise the act if
                                                production is being supplied by the venue or the client.
                                            </Typography>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    {!this.state.isLoading &&
                                        <BookingRequirementDetails readOnly={readOnly} isProductionChange={this.state.isProductionChange} productionIsChangging={this.productionIsChangging} />
                                    }

                                </Grid>
                            </Grid>
                            <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                <Grid item xs={12} lg={3}>
                                    <div ref="notes">
                                        <Typography variant="headline" component="h3" gutterBottom>
                                            Notes
                                        </Typography>
                                        <Typography gutterBottom className="side-helper-text">
                                            {!isMusician ?
                                                "Use this field to advise the act on any other details such as load in instructions, db limits, car parking etc." :
                                                "Use this field to record any other details such as load in instructions, db limits, car parking etc."
                                            }
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                    {!this.state.isLoading &&
                                        <BookingNotes readOnly={readOnly} isNoteChange={this.state.isNoteChange} noteIsChanging={this.noteIsChanging} />
                                    }

                                </Grid>
                            </Grid>
                            {!isMusician && (
                                <Grid container spacing={8} style={{ marginBottom: 60 }}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Venue Contract
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                This contract has been supplied by the venue and applies to this booking.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    {!this.state.isLoading &&
                                        <Grid item xs={12} lg={9} className="space-top-10">
                                            <BookingContract readOnly={readOnly} isVenueChange={this.state.isVenueChange} venueIsChangging={this.venueIsChangging} />
                                        </Grid>
                                    }
                                </Grid>)
                            }
                            <Grid container spacing={8} className={booking.signed && booking.signedAt ? "" : "hidden"}>
                                <Grid item xs={12} lg={3}>
                                    {isStaff && (
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Signature
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text" />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={9} className="space-top-10">
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ width: 500, height: 250, className: "sigCanvas" }}
                                        ref={ref => {
                                            this.sigCanvas = ref;
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3} />
                                <Grid item xs={12} lg={9} className="space-top-10">
                                    <div>
                                        Signed By {booking.signedBy ? ` ${booking.signedBy.firstName} ${booking.signedBy.lastName}` : ""}
                                        <br />
                                        On {moment(new Date(booking.signedAt)).format("DD-MM-YYYY")}
                                    </div>
                                </Grid>
                            </Grid>
                            {!isMusician && (
                                <Grid container spacing={8}>
                                    <Grid item xs={12} lg={3}>
                                        <div ref="notes">
                                            <Typography variant="headline" component="h3" gutterBottom>
                                                Agent Contract
                                            </Typography>
                                            <Typography gutterBottom className="side-helper-text">
                                                The act contract is used for the terms and conditions you require the artist to accept.  Your default template can be updated via your Agency Profile Terms and Conditions, or manually overwritten here.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} lg={9} className="space-top-10">
                                        {!this.state.isLoading &&
                                            <BookingAgentContract readOnly={readOnly} isActChange={this.state.isActChange} actIsChanging={this.actIsChanging} />
                                        }
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <p>Hang tight... We're fetching contracts...</p>
                    )
                }
                
                {
                    !isViewOnly && !createdByAgentInStaffPespective &&
                        <Grid container spacing={8}>
                        <Hidden mdDown>
                            <Grid item lg={3} />
                        </Hidden>
                        <Grid item xs={12} lg={9}>
                            <Grid container spacing={8}>
                                {isCancelled && !isCancelledByAct ? null : 
                                    <Grid item xs={12} className="stepperContent">
                                        <Hidden smDown>
                                            <div className="action pull-right">
                                                <Button variant="outlined" onClick={this.props.history.goBack} style={{ marginRight: 10 }}>
                                                    Back
                                                </Button>

                                                {(showCancelBookingForAgent || showCancelBookingForStaff) && (
                                                    <Button variant="outlined" style={{ marginRight: 10 }} onClick={this.handleOpenCancelConfirmationModal} disabled={readOnly}>  
                                                        Cancel Booking
                                                    </Button>
                                                )}

                                                {/* {showNextButton && (
                                                    <Button variant="contained" color="primary" onClick={this.handleNext}>
                                                        Next
                                                    </Button>
                                                )} */}

                                                {canUpdateBooking  && (!error && !readOnly) && (
                                                    <Button
                                                        id="btn-process"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSaveButton}
                                                    >
                                                        {this.props.processLabel ? this.props.processLabel : "Process Booking"}
                                                    </Button>
                                                )}

                                                {canUpdateBooking  && (error || readOnly) && (
                                                    
                                                    // <Tooltip title="The invoice for this booking has already been sent to your accounting system. You need to Unsync the invoice before you can update the booking">
                                                    <Tooltip title="The invoice for this booking has already been paid.">
                                                        <Button
                                                            id="btn-process"
                                                            style={{backgroundColor:"black",color:"white",opacity:"0.6"}}
                                                        >
                                                            {this.props.processLabel ? this.props.processLabel : "Process Booking"}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </Hidden>
                                        <Hidden mdUp>
                                            <Fragment>
                                                {/* {showNextButton && (
                                                    <Button
                                                        variant="contained"
                                                        fullWidth={true}
                                                        style={{ marginBottom: "12px" }}
                                                        color="primary"
                                                        onClick={this.handleNext}
                                                    >
                                                        Next
                                                    </Button>
                                                )} */}

                                                {canUpdateBooking && (
                                                    <Button
                                                        id="btn-process"
                                                        variant="contained"
                                                        fullWidth={true}
                                                        style={{ marginBottom: "12px" }}
                                                        disabled={error || readOnly}
                                                        color="primary"
                                                        onClick={this.handleSaveButton}
                                                    >
                                                        {/* {this.props.processLabel ? this.props.processLabel : "Process Booking"} */}
                                                        {this.props.processLabel ? this.props.processLabel : "Save & Next"}
                                                    </Button>
                                                )}

                                                {(showCancelBookingForAgent || showCancelBookingForStaff) && (
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        style={{ marginBottom: "12px" }}
                                                        disabled={readOnly}
                                                        onClick={this.handleOpenCancelConfirmationModal}
                                                    >
                                                        Cancel Booking
                                                    </Button>
                                                )}

                                                <Button variant="outlined" onClick={this.props.history.goBack} fullWidth={true}>
                                                    Back
                                                </Button>
                                            </Fragment>
                                        </Hidden>
                                    </Grid>
                                
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    
                }
{/* 
var showPayerSection = synchedStatus || synchedPayee || synchedPayer
        var showPayeeSection = synchedStatus || synchedPayee || synchedPayer
   */}
                {/* {canUpdateBooking  && (error || readOnly) && showPayerSection && (
                    <Grid container style={{marginBottom: 60, marginTop:50}}>
                         <Grid item xs={12} lg={3}>
                            <div ref="eventFee">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Invoice Payer Section
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={() => this.handleRedirectToInvoice(invoiceIdPayer)}>
                                View Invoice
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={() => this.handleRedirectUnsyncInvoice(true)}>
                                Unsync Invoice
                            </Button>
                        </Grid>
                    </Grid>)
                }

                {canUpdateBooking  && (error || readOnly) && showPayeeSection && (
                     <Grid container style={{ marginBottom: 60, marginTop:50}}>
                         <Grid item xs={12} lg={3}>
                            <div ref="eventFee">
                                <Typography variant="headline" component="h3" gutterBottom>
                                    Invoice Payee Section
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={() => this.handleRedirectToInvoice(invoiceIdPayee)}>
                                View Invoice
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={3}>
                            <Button className="viewInvoiceForm" style={{width:"120px", height:"40px"}} variant="contained" color="primary" onClick={() => this.handleRedirectUnsyncInvoice(false)}>
                                Unsync Invoice
                            </Button>
                        </Grid>
                    </Grid>)
                } */}
              <BookingSummaryModal
                    open={this.state.confirmationBookingModalState}
                    onClose={this.handleCloseConfirmationModal}
                    processBooking={this.saveBooking.bind(this)}
                    bookingId={this.props.bookingId}
                />
                {/*
                <ConfirmationModal 
                    open = {this.state.cancelBookingConfirmationModalState}
                    title = "Cancel Booking Confirmation"
                    closeHandler = { this.handleCloseCancelConfirmationModal }
                    message = "Are you sure to cancel this booking?"
                    declineLabel = "Close"
                    confirmationLabel = "Cancel Booking"
                    confirmationHandler = { this.handleCancelBooking }
                />
                */}
                <CancelBookingModal open={this.state.cancelBookingConfirmationModalState} onClose={this.handleCloseCancelConfirmationModal} />
                <ConfirmationModal
                    open={this.state.confirmDoubleBookingModalState}
                    title="Booking Warning"
                    message={this.state.errorMessage + " Are you sure to save this gig?"}
                    closeHandler={this.handleCloseConfirmDoubleBookingModal}
                    confirmationHandler={this.handleConfirmDoubleBookingBooking}
                    declineHandler={this.handleCancelDoubleBookingBooking}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="sm"
                />
                 <ConfirmationModal
                    open={this.state.unsyncInvoiceModal}
                    title={"Unsync Confirmation"}
                    message={`This action will not affected the invoice that already sent to accounts. It will only mark this hotgiggity invoice to unsync. Are you sure you want to continue?`}
                    closeHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsync}
                    declineHandler={this.handleCloseUnsyncInvoiceModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.unsyncInvoiceXeroModal}
                    title={"Unsync Confirmation"}
                    message={`This action will void the invoice in XERO. You will need to resync the invoice again. Are you sure you want to continue?`}
                    closeHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationHandler={this.handleConfirmMarkAsUnsyncXero}
                    declineHandler={this.handleCloseUnsyncInvoiceXeroModal}
                    confirmationLabel="Confirm"
                />

                <ConfirmationModal
                    open={this.state.unsyncInvoiceModalPayee}
                    title={"Unsync Confirmation"}
                    message={`${this.state.invoice?.payeeName} has already synced this invoice to their accounting system so this booking cannot be unsynced`}
                    closeHandler={this.handleCloseUnsyncInvoicePayeeModal}
                    confirmationHandler={this.handleSendMessageNotification}
                    declineHandler={this.handleCloseUnsyncInvoicePayeeModal}
                    confirmationLabel={`Message ${this.state.invoice?.payeeName}`}
                />

            </Fragment>
        );
    }
}

export default BookingForm;
