import React, { useState } from 'react';
import "./MusicianBookings.css";

function MultiInputTextbox({ onInputsChange }) {
  const [inputs, setInputs] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (event) => {
    setCurrentInput(event.target.value);
    setError('');
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddInput = () => {
    const trimmedInput = currentInput.trim();
    if (trimmedInput === '') {
      return;
    }

    if (!isValidEmail(trimmedInput)) {
      setError('Invalid email format');
      return;
    }

    const updatedInputs = [...inputs, trimmedInput];
    setInputs(updatedInputs);
    onInputsChange(updatedInputs);
    setCurrentInput('');
  };

  const handleDeleteInput = (index) => {
    const updatedInputs = inputs.filter((_, i) => i !== index);
    setInputs(updatedInputs);
    onInputsChange(updatedInputs);
  };

  const handleInputChangeFromText = (event) => {
    const value = event.target.value;
    setCurrentInput(value);
    setError("");

    if (value.includes(",")) {
      const newInputs = value.split(",").map(item => item.trim()).filter(item => item !== "");

      const validEmails = newInputs.filter(email => isValidEmail(email));
      const invalidEmails = newInputs.filter(email => !isValidEmail(email));

      if (invalidEmails.length > 0) {
        setError('Invalid email format');
        return
      }
      const updatedInputs = [...inputs, newInputs];
      setInputs(updatedInputs);
      onInputsChange(validEmails);
      setCurrentInput("");
    }
  }

  return (
    <div>

      <div>
      To: 
        {inputs.map((input, index) => (
          <button
            key={index}
            onClick={() => handleDeleteInput(index)}
            style={{
              backgroundColor: '#eee',
              color: '#333',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              marginRight: '5px',
              marginBottom: '5px',
              cursor: 'pointer',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
             {input}
            <span style={{ marginLeft: '5px', color: 'red', fontWeight: 'bold' }}>×</span>
          </button>
        ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <input
          type="text"
          value={currentInput}
          onChange={handleInputChangeFromText}
          placeholder="Enter email and press Enter (comma separated for multiple entries)"
          onKeyDown={(event) => { // Listen for Enter key
            if (event.key === 'Enter') {
              handleAddInput();
              event.preventDefault(); // Prevent form submission if inside a form
            }
          }}
          className="multi-input-textbox"
        //   style={{
        //     padding: '8px',
        //     border: '1px solid #ccc',
        //     borderRadius: '4px',
        //     flexGrow: '1',
        //     fontSize: '16px',
        //     borderColor: error ? 'red' : '#ccc',
        //     '::placeholder': `
        //       font-size: 7px;
        //       color: #aaa;
        //     `, // Template literal for placeholder styles
        //   }}
        />
      </div>
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}

    </div>
  );
}

export default MultiInputTextbox;