import React, { Component, Fragment,Viewer } from "react";
import { Document, Page,pdfjs  } from 'react-pdf/dist/esm/entry.webpack';

import { CircularProgress, IconButton, Grid, Typography, withWidth, TextField, InputAdornment, Hidden } from "@material-ui/core";

import Slider from "@material-ui/lab/Slider";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RotateRightIcon from "@material-ui/icons/RotateRight";

import printJS from "print-js";

import "./PdfViewer.css";
const IS_MOBILE = process.env.REACT_APP_IS_MOBILE === "true";
const MAIN_SERVICE_URL = process.env.REACT_APP_MAIN_SERVICE_URL;

class BookingPDFViewer extends Component {
    state = {
        totalPages: undefined,
        page: 1,
        zoom: 1,
        success: false,
        pdfTitle: "",
        rotate: 0,
        tempPage: 1,
        downloadUrl: ""
    };

    componentDidMount() {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
        const { width } = this.props;
        if (width === "xs" || width === "sm") {
            this.setState({
                ...this.state,
                zoom: 0.5
            });
        } else {
            this.setState({
                ...this.state,
                zoom: 1.5
            });
        }
        
        this.setState({
            downloadUrl: MAIN_SERVICE_URL + `/booking/details/pdf/${this.props.bookingId}/${this.props.sendFeeDetails}`
        });
    }

    onDocumentLoad = document => {
        document.getMetadata().then(response => {
            this.setState({
                ...this.state,
                totalPages: document.numPages,
                success: true,
                pdfTitle: "Booking Details"
            });
        });
    };

    handleZoomSlideChange = (event, value) => {
        this.setState({
            ...this.state,
            zoom: value
        });
    };

    handleZoomOut = () => {
        var newZoomValue = this.state.zoom - 0.1;
        if (newZoomValue < 0.2) {
            return false;
        }
        this.setState({
            ...this.state,
            zoom: newZoomValue
        });
    };

    handleZoomIn = () => {
        var newZoomValue = this.state.zoom + 0.1;
        if (newZoomValue > 2) {
            return false;
        }
        this.setState({
            ...this.state,
            zoom: newZoomValue
        });
    };

    renderPages = totalPages => {
        const numbers = Array.from(Array(totalPages).keys()).map(x => ++x);
        const { zoom } = this.state;
        return numbers.map((x, idx) => <Page className="page-container" pageNumber={x} scale={zoom} key={idx} />);
    };

    handlePrevPage = () => {
        var newPage = this.state.page - 1;
        this.setState({
            ...this.state,
            page: newPage,
            tempPage: newPage
        });
    };

    handleNextPage = () => {
        var newPage = this.state.page + 1;
        this.setState({
            ...this.state,
            page: newPage,
            tempPage: newPage
        });
    };

    handleChangePage = e => {
        var page = parseInt(e.target.value, 10);
        this.setState(
            {
                ...this.state,
                tempPage: e.target.value
            },
            () => {
                if (page < 1) {
                    page = 1;
                } else if (page > this.state.totalPages) {
                    page = this.state.totalPages;
                }
                if (page) {
                    this.setState({
                        ...this.state,
                        page,
                        tempPage: page
                    });
                }
            }
        );
    };

    handleRotate = () => {
        var newRotateValue = this.state.rotate + (90 % 360);
        this.setState({
            ...this.state,
            rotate: newRotateValue
        });
    };

    dataURLtoFile(dataurl) {
        var arr = dataurl.split(","),
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return u8arr;
    }
    base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        return new Blob([out], { type: 'application/pdf' });
    };
    handlePrint = () => {
        const { file } = this.props;
        var fileBytes = this.dataURLtoFile(file);
        const pdfBlob = new Blob([fileBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
        printJS({
            printable: url,
            type: "pdf",
            showModal: true,
            modalMessage: "Loading PDF..."
        });
    };

    handleDownloadOnMobile = () => {
        window.cordova.InAppBrowser.open(this.state.downloadUrl, "_system", "location=yes");
    };

    render() {
        const { file, width, pagination } = this.props;
        const { page, zoom, success, totalPages, pdfTitle, rotate, tempPage } = this.state;
        var blob = "";
            var url = "";
        if(file){   
            blob = this.base64toBlob(file);
            url = URL.createObjectURL(blob);
        }
       
        let usedWidth =
            width === "md" || width === "lg" || width === "xl"
                ? width
                : (width === "xs" || width === "sm") && pagination && totalPages > 1
                    ? width
                    : "md";

        return (
            <div className={`pdf-viewer-container-${usedWidth}`}>
                {success ? (
                    <div className={`pdf-viewer-header-${usedWidth}`}>
                        <Grid container>
                            <Grid item xs={6} md={5}>
                                <Typography className="pdf-title-container">{pdfTitle}</Typography>
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={6}>
                                    <div className="pdf-toolbar-action-container">
                                        <IconButton component="a" className="pdf-toolbar-action-btn" onClick={this.handleRotate}>
                                            <RotateRightIcon />
                                        </IconButton>
                                        {IS_MOBILE ? (
                                            <IconButton
                                                className="pdf-toolbar-action-btn"
                                                component="a"
                                                onClick={this.handleDownloadOnMobile}
                                                download={`${pdfTitle}.pdf`}
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton className="pdf-toolbar-action-btn" component="a" href={file} download={`${pdfTitle}.pdf`}>
                                                <GetAppIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={2}>
                                {totalPages !== 1 && pagination ? (
                                    <div className="pdf-toolbar-page-nav">
                                        <IconButton className="pdf-toolbar-action-btn" onClick={this.handlePrevPage} disabled={page === 1}>
                                            <KeyboardArrowLeftIcon />
                                        </IconButton>
                                        <TextField
                                            value={tempPage}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start" classes={{ root: "pdf-page-totalPages" }}>
                                                        of {totalPages}
                                                    </InputAdornment>
                                                )
                                            }}
                                            classes={{
                                                root: "pdf-page-textfield"
                                            }}
                                            onChange={this.handleChangePage}
                                        />
                                        <IconButton className="pdf-toolbar-action-btn" onClick={this.handleNextPage} disabled={page === totalPages}>
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={6} md={5}>
                                    <div className="pdf-toolbar-action-container">
                                        <IconButton className="pdf-toolbar-action-btn" component="a" onClick={this.handleRotate}>
                                            <RotateRightIcon />
                                        </IconButton>
                                        <IconButton className="pdf-toolbar-action-btn" component="a" href={file} download={`${pdfTitle}.pdf`}>
                                            <GetAppIcon />
                                        </IconButton>
                                        <IconButton className="pdf-toolbar-action-btn" component="a" onClick={this.handlePrint}>
                                            <PrintIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                ) : (
                    ""
                )}
                <div className={`pdf-container-${usedWidth}`}>
                    <div className="pdf-document-container">
                             <Document
                                file={file}
                                onLoadSuccess={this.onDocumentLoad}
                                loading={<CircularProgress className="pdf-load-indicator" />}
                                rotate={rotate}
                            >
                                {pagination ? <Page className="page-container" pageNumber={page} scale={zoom} /> : this.renderPages(totalPages)}
                            </Document>
                    
                    </div>
                    {success ? (
                        <Fragment>
                            <div className={width === "xs" || width === "sm" ? "pdf-action-container-small" : "pdf-action-container"}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" color="primary">
                                            Zoom
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton color="primary" onClick={this.handleZoomOut} classes={{ root: "pdf-zoom-button" }}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Slider
                                            value={zoom}
                                            min={0.2}
                                            max={2}
                                            step={0.1}
                                            onChange={this.handleZoomSlideChange}
                                            classes={{ root: "pdf-zoom" }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton color="primary" onClick={this.handleZoomIn} classes={{ root: "pdf-zoom-button" }}>
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </Fragment>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default withWidth()(BookingPDFViewer);
