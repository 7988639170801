import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SetListForm from "../../components/setList/SetListForm";
import { Grid,  FormControlLabel, Checkbox } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import InputAdornment from "@material-ui/core/InputAdornment";
import blankProfile from "../../img/hotgig-loading.png";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import RichTextEditor from "../../components/richTextEditor/RichTextEditor"
import "./Booking.css";
import moment from "moment";
import { Paper, Hidden } from "@material-ui/core";
import BookingPDFViewer from "../../components/pdf/BookingPDFViewer";

import Loading from "../../components/loadingSpinner/Loading";

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("templateStore", "bookingRequestStore", "profileStore")
@observer
class PrintBookingDetails extends Component {
    state = {
        clientMessage: "",
        pdfFile: "",
        to:[],
        image: null,
        openDetails: false,
        isLoading: false,
        sendFeeDetails: true
    };
    breadcrumb = [{ name: "Dashboard", link: "/" }, { name: "Print Booking Details" }];

    componentDidMount() {
        this.setState({
            ...this.state,
            isLoading: true
        });
        Promise.all(
            [ this.props.bookingRequestStore.initializeEditBooking(this.props.match.params.id),
                this.props.bookingRequestStore.downloadBookinDetailsPdf(this.props.match.params.id, this.state.sendFeeDetails)
            ]).then(response => {
                var responseFile = response[1]
                let pdfFile = `data:application/pdf;base64,${responseFile}`;
                this.setState({
                    ...this.state,
                    pdfFile: pdfFile,
                    image: response[0],
                    isLoading: false
                });
                this.props.templateStore.hideLoadingScreen();
            })
    }

    checkSendFeedDetails = (checked) => {
        this.setState({  isLoading: true})
            this.props.bookingRequestStore.downloadBookinDetailsPdf(this.props.match.params.id, checked)
            .then(responseFile => {
            let pdfFile = `data:application/pdf;base64,${responseFile}`;
            this.setState({
                ...this.state,
                pdfFile: pdfFile,
                isLoading: false,
                sendFeeDetails: checked
            });
        })
        
    }
    render() {
        const {isLoading, sendFeeDetails} = this.state
        return (
            <main>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Loading showed={isLoading} />
                    <Paper style={{padding:30}}>
                        <Grid style={{marginTop:20}} container spacing={8}>
                            <Grid item sm={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sendFeeDetails}
                                            onChange={e => this.checkSendFeedDetails(e.target.checked)}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Include Fee Details"
                                />
                            </Grid>
                            <Grid item xs={12} lg={12} style={{ border: 2, borderColor: "gray", borderStyle: "solid", paddingLeft: 15, paddingBottom: 15 }}>
                                <BookingPDFViewer file={this.state.pdfFile} bookingId={this.props.match.params.id} sendFeeDetails={this.state.sendFeeDetails}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </MainContainer>
                
                
            </main>
        );
    }
}

export default PrintBookingDetails;
