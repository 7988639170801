import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import ModalContainer from "../../modal/ModalContainer.js";
import BookingPDFViewer from "../../pdf/BookingPDFViewer";

class BookingDetailsModal extends Component {
    render() {
        return (
            <ModalContainer
                open={this.props.open}
                title={this.props.title ? this.props.title : "Document"}
                onClose={this.props.closeHandler}
                fullScreen={false}
                maxWidth={this.props.maxWidth ? this.props.maxWidth : "xs"}
            >
                <DialogContent
                // style={{ paddingTop: "16px" }}
                >
                    <BookingPDFViewer file={this.props.pdfFile} bookingId={this.props.bookingId} sendFeeDetails={this.props.sendFeeDetails}/>
                        

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.closeHandler}>
                        Close
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default BookingDetailsModal;
